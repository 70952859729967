<template>
    <div>
        <div class="wrapper">
            <div class="login-content">
                <div class="login-title">
                    欢迎使用囊中对比
                </div>
                <div class="login-desc">
                    {{ alertMsg }}
                </div>
                <div class="login-code">
                    <img v-if="status === '0'" :src="codeImg" alt="">
                    <img v-else-if="status == null || status === '-1' || status === '-2'" @click="refreshCode"
                        src="@/assets/images/refresh-code.png" alt="">
                    <img v-else-if="status === '1' || status === '2'" src="@/assets/images/scan-code.png" alt="">
                    <img class="scan" src="@/assets/images/scan.png" alt="">
                </div>
                <div class="login-alert ">
                    切换至<a href="javascript:;" @click="accountLogin">账号登录 ></a>
                </div>
                <div class="login-aggrement">
                    登录/注册表示同意
                    <a href="javascript:;" @click="openPrivacyPolicy">隐私政策</a>
                    和
                    <a href="javascript:;" @click="openServiceAgreement">服务条款</a>
                </div>
            </div>
        </div>
        
        <!-- 添加隐私政策弹窗 -->
        <div v-if="showPrivacyPolicy" class="policy-modal">
          <div class="modal-content">
            <iframe :src="privacyPolicyUrl" frameborder="0"></iframe>
            <button @click="closePrivacyPolicy" class="close-btn">返回</button>
          </div>
        </div>

        <!-- 服务条款弹窗 -->
        <div v-if="showServiceAgreement" class="policy-modal">
          <div class="modal-content">
            <iframe :src="serviceAgreementUrl" frameborder="0"></iframe>
            <button @click="closeServiceAgreement" class="close-btn">返回</button>
          </div>
        </div>
    </div>
</template>

<script>
import { getAccessToken, createQrCode, updateScanCodeStatus, getScanCodeStatus } from "@/api/index";
import { generateRandomString } from "@/utils/nangzhong";
import { setToken } from '@/utils/auth'
const appId = 'wxab6d9433a7ee3945';
export default {
    name: "CodeLoginPage",
    data() {
        return {
            alertMsg: "打开微信扫一扫，快速登录/注册",
            accessToken: null,
            scene: null,
            codeImg: null,
            status: null,
            showPrivacyPolicy: false,
            showServiceAgreement: false,
            privacyPolicyUrl: 'https://help.srnz.net/service/privicy.html',
            serviceAgreementUrl: 'https://help.srnz.net/service/agreement.html'
        }
    },
    mounted() {
        this.getAccessToken();
        this.getScanCodeStatusInterval();
    },
    destroyed() {
        clearInterval(this.interval);
    },
    methods: {
        // 获取accessToken
        // TODO 需要异步处理
        getAccessToken() {
            var appSecret = '3ad71a9c39b0b322908aee795f50c909';
            var params = {
                grant_type: 'client_credential',
                appid: appId,
                secret: appSecret
            }
            getAccessToken(params).then(response => {
                this.accessToken = response.access_token;
                this.createQrCode();
            });

        },
        accountLogin() {
            this.$router.push("/login/account");
        },
        // 创建小程序码
        async createQrCode() {
            this.scene = generateRandomString(32);
            var params = {
                access_token: this.accessToken,
            };
            var data1 = {
                scene: this.scene,
                check_path: false,
                page: 'pages/auth/index',
                env_version: 'release'
            }
            let result = await createQrCode(params, data1);
            let img = new Blob([result]);
            this.codeImg = window.URL.createObjectURL(img);
            var data2 = {
                scene: this.scene,
                status: '0' // 未扫码状态
            }
            updateScanCodeStatus(data2).then(response => {
                console.log(response);
            });
        },
        // 获取小程序码状态
        getScanCodeStatusInterval() {
            var that = this;
            this.interval = setInterval(() => {
                var params = {
                    scene: this.scene
                }
                getScanCodeStatus(params).then(response => {
                    that.status = response.data.status;
                    let userNumber = response.data.userNumber;
                    let isBind = response.data.isBind;
                    let token = response.data.token;
                    if (that.status === '1') {
                        that.alertMsg = "已完成扫描，请确认操作";
                    } else if (that.status === '2') {
                        that.alertMsg = "已确认登录";
                        this.$store.commit("SET_TOKEN", token);
                        setToken(token);
                        if(isBind === '0'){
                            this.$router.push({path:"/login/bind",query: {
                                userNumber: userNumber,
                             }});
                        } else {
                            this.$router.push("/community/home");
                        }
                        clearInterval(this.interval);
                    } else if (that.status === '-1') {
                        that.alertMsg = "二维码已过期，请刷新";
                    } else if (that.status === '-2') {
                        that.alertMsg = "已取消登录";
                    }
                });
            }, 1000)

        },

        refreshCode() {
            this.createQrCode();
        },
        openPrivacyPolicy() {
          this.showPrivacyPolicy = true;
        },
        closePrivacyPolicy() {
          this.showPrivacyPolicy = false;
        },
        openServiceAgreement() {
          this.showServiceAgreement = true;
        },
        closeServiceAgreement() {
          this.showServiceAgreement = false;
        }
    }
}


</script>
<style lang="scss" scoped>
.wrapper {

    background-image: url(~@/assets/images/login-bgc.svg);
    background-size: 100%;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;

    .login-content {
        border-radius: 10px;
        padding: 60px;
        background-color: $background-color;
        display: flex;
        align-items: center;
        flex-direction: column;
        box-shadow: 0px 0px 5px 0px rgba(129, 129, 129, 0.25);
        // align-items : center;
        .login-title {
            font-size: $font-size-Level4;
        }

        .login-desc {
            margin-top: 32px;
            font-size: $font-size-primary;
            color: $font-color-primary;
        }

        .login-code {
            margin-top: 32px;
            position: relative;
            img {
                width: 170px;
                height: 170px;
                cursor: pointer;

            }

            .scan {
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        .login-alert {
            margin-top: 24px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            a {
                color: $font-color-third;
            }
            font-size: $font-size-primary;
        }

        .login-aggrement {
            margin-top: 66px;

            a {
                color: $font-color-third;
            }
        }
    }
}

.policy-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  .modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;

    iframe {
      flex-grow: 1;
      width: 100%;
      height: calc(100% - 50px);
      border: none;
    }

    .close-btn {
      height: 50px;
      width: 100%;
      background-color: $color-primary;
      color: white;
      border: none;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        background-color: darken($color-primary, 5%);
      }
    }
  }
}
</style>
