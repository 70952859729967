<template>
    <div class="body-content">
        <discuss-category></discuss-category>
        <div class="index-container">
            <div class="index-main">
                <div class="list__header">
                    <ul class="list__nav">
                        <li class="list__nav-item" :class="{ 'list__nav-item--active': item.id === navId }"
                            v-for="item in navs" :key="item.title" @click="changeNavType(item)">{{ item.title }}
                        </li>
                      <li class="publish-link" @click="toPublish()">
                          <button class="publish">
                            <span>+</span>发布
                          </button>
                        </li>
                    </ul>
                </div>
                <artic-list :list="articleList"></artic-list>
            </div>
        </div>
        <div class="right-section">
            <user-data class="user-data"></user-data>
            <tool-box class="tool-box"></tool-box>
        </div>
    </div>
</template>
<script>
import discussCategory from '@/components/business/discuss/discussCategory.vue';
import userData from '@/components/business/discuss/userData.vue';
import toolBox from '@/components/business/discuss/toolBox.vue';
import news from '@/components/business/discuss/news.vue';
import {getArticleList} from '@/api';
export default {
    name: "CommunityHomePage",
    data() {
        return {
            navs: [
                {
                    id: 1,
                    title: '最新',
                    type: 1
                },
                {
                    id: 2,
                    title: '最热',
                    type: 2
                },
                {
                    id: 3,
                    title: '关注',
                    type: 3,
                }
            ],
            navId: 1,
            navTypes: [],
            articleList: [{
                articleId: 1,
                userInfo: {
                  userId: 1,
                  userName: "不学就考不上"
                },
                articleInfo: {
                  title: "这是一级标题，加粗，16px",
                  descContent: "1121212",
                  commentCount: 100,
                  likeCount: 11,
                  createTime: "2023-12-20 19:23",
                  coverImage: ''
                },
                tags: [
                  {
                    tagId: 1,
                    tagName: "标签1"
                  },
                  {
                    tagId: 2,
                    tagName: "标签2"
                  },
                  {
                    tagId: 3,
                    tagName: "标签3"
                  }
                ]
              }
            ],
        }
    },
    components: {
        'discuss-category': discussCategory,
        'user-data': userData,
        'tool-box': toolBox,
        'news': news
    },
    methods: {
        changeNavType(item) {
            if (item.id && this.navId !== item.id) {
                this.navId = item.id
            }
        },
        toPublish(hash) {
            this.$router.push({
                name: 'CommunityPublishPage',
                params: {
                  id: 'new'
                },
                query: {
                    hash: hash
                }
            })
        },
        getArticleList() {
            let params = {
                page: 1,
                size: 10,
                params: {

                }
            }
            if(this.navId === 1) { // 最新
              params.params.sort = 'create_time'
              params.params.order = 'desc'
            } else if(this.navId === 2) {
              params.params.sort = 'like_count' // 最热
              params.params.order = 'desc'
            } else if(this.navId === 3) { // 我的关注

            }
            getArticleList(params).then(response => {
              this.articleList = response.rows;
            });
        }
    },
    mounted() {
        this.$router.push("/community/studyRoom/paper");
        this.getArticleList()
    }
}

</script>
<style lang="scss" scoped>
.body-content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .index-container,
    .right-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .index-container {
        display: flex;
        position: relative;
        left: 148px;

        .index-main {
            width: 720px;
            min-height: 620px;
            margin-right: 20px;
            background: #fff;
            border-radius: 10px;
            padding: 20px;
        }

        margin-left: 12px;
    }

    .list__header {
        border-bottom: 1px solid $color-third;
    }

    .list__nav {
        display: flex;
        align-items: center;
        .publish-link {
          margin-left: auto;
          .publish {
            font-size: $font-size-Level6;
            padding: 4px 10px;
            background-color: $color-primary;
            border-radius: 5px;
            cursor: pointer;
            color: $font-color-secondary;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: darken($color-primary, 5%);
            }

            span {
              font-weight: 900;
              font-size: $font-size-Level6;
              margin-right: 7px;
            }
          }
        }

        .list__nav-item {
            height: 50px;
            display: inline-block;
            font-size: $font-size-Level5;
            color: $font-color-primary;
            cursor: pointer;
            padding: 0 23px;
            position: relative;
            line-height: 50px;

            &:hover,
            &.list__nav-item--active {
                color: $color-primary;
            }

            &.list__nav-item--active::after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                width: 34px;
                height: 8px;
                background: $color-primary;
                left: 30%;
                transform: scaleY(.5);
            }

        }
    }

    .right-section {
        width: 300px;
        height: 620px;

        .user-data {
            height: 60%;
        }
    }
}
</style>