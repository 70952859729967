<template>
    <div class="body-content">
        <div class="index-container">
            <div class="index-main">
              <div class="success-message">
                <img src="@/assets/images/finished.svg" alt="success">
                <p>恭喜您，文章发布成功！</p>
              </div>
              <button class="back-home" @click="backHome">返回首页</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "CommunityPublishedPage",
    data() {
        return {

        }
    },

    methods: {
      backHome() {
          this.$router.push('/community/home');
      }
    }
}

</script>
<style lang="scss" scoped>
.body-content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .index-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        .index-main {
          width: 720px;
          min-height: 620px;
          background: #fff;
          border-radius: 10px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .success-message {
            text-align: center;
            img {
              width: 400px;
            }
            p {
              margin-top: 10px;
              font-size: 18px;
              font-weight: bold;
            }
          }
          .back-home {
            margin-top: 20px;
            background-color: #46B4B1;
            color: #FFF;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
          }
        }
    }
}
</style>