<template>
	<div v-show="visable" class="modal-overlay" @click.self="handleClose">
		<div v-if="!showQrCode" class="subscription-container">
			<div class="container-left">
				<div class="title">囊中对比纯净版</div>
				<div class="sub-title">订阅后全平台通用（小程序、APP、电脑网页）</div>

				<div class="features">
					<div class="feature-item">
						<img src="@/assets/images/function-checked.png" alt="">
						<div>
							<strong>免看小程序内所有广告</strong>
							<p>开屏、首页、答案页面</p>
						</div>
					</div>
					<div class="feature-item">
						<img src="@/assets/images/function-checked.png" alt="">
						<div>
							<strong>畅看各端应用内所有答案</strong>
							<p>四大机构、所有网友补充答案</p>
						</div>
					</div>
				</div>
			</div>
			<div class="container-right">
				<div class="close-btn" @click="handleClose">×</div>
				<div class="price-options">
					<div v-for="(item, index) in versionMealList" :key="index" class="price-item"
						:class="{ highlighted: selectedPlan === index }" @click="selectPlan(index)">
						<div class="price-main">
							<span class="currency">￥</span>
							<span class="amount">{{ item.price }}</span>
							<span class="duration">/{{ item.duration }}月</span>
						</div>
						<div class="price-sub">
							<span v-if="item.dailyPrice" class="daily-price">{{ item.dailyPrice }}</span>
							<span v-if="item.originalPrice" class="original-price">￥{{ item.originalPrice }}</span>
						</div>
					</div>
				</div>

				<p class="support-text">您的小小支持能为小囊带来无限动力~</p>

				<button class="wechat-pay-btn" @click="handlePay">
					<img src="@/assets/images/wechat.png" alt="微信支付" />
					微信支付 {{ selectedPlanPrice }}元
				</button>

				<p class="agreement">
					开通即表示同意
					<a href="javascript:;" class="agreement-link" @click="openServiceAgreement">《会员服务协议》</a>
				</p>
			</div>

		</div>

		<!-- 二维码弹窗 -->
		<div v-if="showQrCode" class="qrcode-container">
			<div class="qrcode-header">
				<div class="back-btn" @click="handleBackFromQrCode">返回</div>
			</div>
			<div class="qrcode-content">
				<div class="qrcode-title">微信扫码支付</div>
				<div class="qrcode-amount">￥{{ selectedPlanPrice }}</div>
				<div class="qrcode-image" :class="{ 'loading': payLoading }">
					<!-- 加载状态 -->
					<div v-if="payLoading" class="loading-spinner">
						<i class="el-icon-loading"></i>
						<span>正在生成支付二维码...</span>
					</div>
					<!-- 支付成功状态 -->
					<div v-else-if="payStatus === 1" class="pay-success">
						<i class="el-icon-success"></i>
						<span>{{ payStatusText }}</span>
					</div>
					<!-- 支付失败状态 -->
					<div v-else-if="payStatus === 2" class="pay-error">
						<i class="el-icon-error"></i>
						<span>{{ payStatusText }}</span>
					</div>
					<!-- 二维码 -->
					<img v-else :src="qrCodeUrl" alt="微信支付二维码" />
				</div>
				<div v-if="payStatus === null && !payLoading" class="qrcode-tips">请使用微信扫一扫</div>
				<div v-else-if="payStatus === 0 && !payLoading" class="qrcode-tips status-waiting">{{ payStatusText ||
		'等待支付...' }}</div>
				<div v-else-if="payStatus === 2 || payStatus === 3" class="retry-btn" @click="handlePay">重新支付</div>
			</div>
		</div>
		<!-- 服务条款弹窗 -->
        <div v-if="showServiceAgreement" class="policy-modal">
          <div class="modal-content">
            <iframe :src="serviceAgreementUrl" frameborder="0"></iframe>
            <button @click="closeServiceAgreement" class="close-btn">返回</button>
          </div>
        </div>
	</div>
</template>
<script>
import { getVersionMealList, createOrder, activePay, orderQuery } from '@/api';
import QRCode from 'qrcode';
export default {
	props: {
		visable: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			selectedPlan: 1,
			showQrCode: false,
			qrCodeUrl: '',
			versionMealList: [],
			orderNo: null,
			payStatus: null, // 支付状态：null-初始状态，0-未支付，1-支付成功，2-支付失败 3-已取消
			payStatusText: '', // 支付状态文本
			payStatusTimer: null, // 轮询定时器
			payLoading: false, // 支付加载状态
			payTimeout: 300000, // 支付超时时间，默认5分钟
			payStartTime: 0, // 支付开始时间
			showServiceAgreement: false,
			serviceAgreementUrl: 'https://help.srnz.net/service/purchaseAgreement.html'
		}
	},
	created() {
		this.fetchVersionMealList();
	},

	watch: {
		visable(newVal) {
			// 当弹窗显示时，重置二维码状态，确保每次打开都显示套餐选择页面
			if (newVal) {
				this.showQrCode = false;
				this.payStatus = null;
				this.payStatusText = '';
				this.orderNo = null;
				this.qrCodeUrl = '';
				this.payLoading = false;
				// 停止可能存在的轮询
				this.stopCheckPayStatus();
			}
		}
	},
	computed: {
		selectedPlanPrice() {
			return this.versionMealList.length > 0 ? this.versionMealList[this.selectedPlan].price : 0
		}
	},
	methods: {
		async fetchVersionMealList() {
			try {
				const response = await getVersionMealList();
				if (response && response.rows && response.rows.length > 0) {
					this.versionMealList = response.rows.map(item => ({
						mealId: item.mealId,
						price: item.currentPrice,
						duration: item.period,
						originalPrice: item.originalPrice || null,
						dailyPrice: item.period === 1 ? '0.16元/天' : null
					}));
				} else {
					console.error('获取套餐列表返回数据格式不正确');
					this.$message.error('获取套餐列表失败');
				}
			} catch (error) {
				console.error('获取套餐列表失败:', error);
				this.$message.error('获取套餐列表失败，请稍后再试');
			}
		},
		openServiceAgreement() {
            this.showServiceAgreement = true;
        },
        closeServiceAgreement() {
            this.showServiceAgreement = false;
        },
		handleClose() {
			this.stopCheckPayStatus();
			this.$emit('close');
		},
		selectPlan(index) {
			this.selectedPlan = index;
		},
		async handlePay() {
			if (this.versionMealList.length === 0) {
				this.$message.error('套餐列表为空，请刷新页面重试');
				return;
			}

			// 重置支付状态
			this.payStatus = null;
			this.payStatusText = '';
			this.payLoading = true;

			var data = {
				payAmount: this.selectedPlanPrice,
				productType: 1,
				mealId: this.versionMealList[this.selectedPlan].mealId
			};

			try {
				let createOrderResponse = await createOrder(data);
				if (createOrderResponse.code != 200) {
					this.$message.error('创建订单失败');
					this.payLoading = false;
					return;
				}

				data = createOrderResponse.data;
				// 保存订单ID，用于后续查询支付状态
				this.orderNo = data.orderNo;
				data.platform = 1; // 微信扫码支付
				let activePayResponse = await activePay(data);
				if (activePayResponse.code != 200) {
					this.$message.error('订单激活失败');
					this.payLoading = false;
					return;
				}

				try {
					let codeUrl = JSON.parse(activePayResponse.data)["code_url"];
					// 使用QRCode库生成二维码
					this.qrCodeUrl = await QRCode.toDataURL(codeUrl);

					// 显示二维码弹窗
					this.showQrCode = true;
					this.payLoading = false;

					// 记录支付开始时间
					this.payStartTime = Date.now();

					// 开始轮询检查支付状态
					this.startCheckPayStatus();
				} catch (error) {
					console.error('生成二维码失败:', error);
					this.$message.error('生成支付二维码失败，请重试');
					this.payLoading = false;
				}
			} catch (error) {
				console.error('支付流程出错:', error);
				this.$message.error('支付流程出错，请稍后再试');
				this.payLoading = false;
			}
		},
		handleBackFromQrCode() {
			// 返回到选择套餐页面
			this.showQrCode = false;
			// 停止轮询
			this.stopCheckPayStatus();
		},

		// 开始轮询检查支付状态
		startCheckPayStatus() {
			// 清除可能存在的定时器
			this.stopCheckPayStatus();

			// 设置轮询间隔为3秒
			this.payStatusTimer = setInterval(async () => {
				await this.checkPayStatus();

				// 检查是否超时
				const now = Date.now();
				if (now - this.payStartTime > this.payTimeout) {
					this.payStatus = 2; // 支付超时
					this.payStatusText = '支付超时，请重新支付';
					this.stopCheckPayStatus();
				}

				// 如果支付成功或失败，停止轮询
				if (this.payStatus === 1 || this.payStatus === 2 || this.payStatus === 3) {
					this.stopCheckPayStatus();

					// 如果支付成功，3秒后关闭弹窗
					if (this.payStatus === 1) {
						// 更新 Vuex store 中的用户信息
						try {
							await this.$store.dispatch('user/GetInfo');
							this.$emit('refresh');
							setTimeout(() => {
								this.handleClose();
							}, 3000);
						} catch (error) {
							console.error('更新用户信息失败:', error);
							// 即使更新用户信息失败，也关闭弹窗
							setTimeout(() => {
								this.handleClose();
							}, 3000);
						}
					}
				}
			}, 3000);
		},

		// 停止轮询
		stopCheckPayStatus() {
			if (this.payStatusTimer) {
				clearInterval(this.payStatusTimer);
				this.payStatusTimer = null;
			}
		},

		// 检查支付状态
		async checkPayStatus() {
			if (!this.orderNo) return;
			try {
				// 调用查询订单状态接口
				const response = await orderQuery({ orderNo: this.orderNo });

				// 根据返回结果更新支付状态
				if (response.code === 200 && response.data) {
					try {
						const orderStatusResult = JSON.parse(response.data);
						// 假设订单状态：1-待支付，2-支付成功，3-支付失败，4-已取消
						switch (orderStatusResult["trade_state"]) {
							case "SUCCESS": // 支付成功
								this.payStatus = 1;
								this.payStatusText = '支付成功！';
								this.$message.success('支付成功！');
								break;
							case "PAYERROR": // 支付失败
								this.payStatus = 2;
								this.payStatusText = '支付失败，请重新支付';
								this.$message.error('支付失败，请重新支付');
								break;
							case "REVOKED": // 已取消
								this.payStatus = 3;
								this.payStatusText = '订单已取消';
								break;
							case "NOTPAY": // 未支付
								this.payStatus = 0;
								this.payStatusText = '等待支付...';
								break;
						}
					} catch (error) {
						console.error('解析订单状态数据失败:', error);
					}
				} else {
					console.error('查询订单状态返回异常:', response);
				}
			} catch (error) {
				console.error('查询支付状态出错:', error);
			}
		}
	}
}
</script>


<style scoped lang="scss">
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.subscription-container {
	margin: 0 auto;
	font-family: -apple-system, sans-serif;
	display: flex;
	width: 720px;

	.container-left {
		background-color: #fff;
		background-image: url(~@/assets/images/version-background.png);
		padding: 24px;
		width: 400px;
		border-radius: 10px 0 0 10px;
	}

	.container-right {
		background-color: #fff;
		padding: 56px 28px;
		width: 320px;
		position: relative;
		border-radius: 0 10px 10px 0;

		/* 新增关闭按钮样式 */
		.close-btn {
			position: absolute;
			right: 4px;
			top: 0px;
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 24px;
			color: #666;
			cursor: pointer;
			transition: all 0.2s;

			&:hover {
				color: #46B4B1;
			}
		}
	}


}

.title {
	color: #8C6A03;
	margin-bottom: 8px;
	font-size: 24px;
	font-weight: 700;
}

.sub-title {
	color: #8C6A03;
	font-size: 16px;
	margin-bottom: 24px;
}

.features {
	margin-bottom: 30px;

	.feature-item {
		margin-bottom: 15px;
		display: flex;

		img {
			width: 32px;
			height: 100%;
			padding: 8px;
			border-radius: 8px;
			margin-right: 8px;
		}

		strong {
			color: #3D3D3D;
			display: flex;
			margin-bottom: 4px;
			font-size: 18px;
			align-items: center;
		}

		p {
			color: #808080;
			font-size: 14px;
			margin: 0;
			text-decoration: line-through;
		}
	}
}

.price-options {
	display: grid;
	gap: 15px;
	margin-bottom: 20px;
	width: 100%;

	.price-item {

		border: 2px solid #eee;
		border-radius: 8px;
		padding: 16px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&.highlighted {
			border-color: #46B4B1;
			background: rgba(70, 180, 177, 0.1);
		}

		.price-main {
			font-size: 24px;
			margin-bottom: 6px;

			.amount {
				font-weight: 700;
			}

			.currency {
				font-size: 24px;
				font-weight: 700;
			}

			.duration {
				font-size: 16px;
				color: #666;
				margin-left: 20px;
			}
		}

		.price-sub {
			font-size: 14px;
			color: #999;

			.original-price {
				text-decoration: line-through;
			}
		}
	}
}

.support-text {
	text-align: center;
	color: #333;
	font-size: 16px;
	margin-bottom: 20px;
	font-weight: normal;
}

.wechat-pay-btn {
	width: 100%;
	padding: 12px;
	background: #07c160;
	color: white;
	border: none;
	border-radius: 6px;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	cursor: pointer;
	transition: all 0.2s ease;

	&:active {
		transform: scale(0.98);
		background: #06b054;
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
	}

	&:hover {
		background: #06b054;
	}

	img {
		width: 20px;
		height: 20px;
	}
}

.agreement {
	text-align: center;
	font-size: 12px;
	color: #999;
	margin-top: 20px;

	.agreement-link {
		color: #46B4B1;
		text-decoration: none;
	}
}

/* 二维码弹窗样式 */
.qrcode-container {
	background-color: #fff;
	border-radius: 10px;
	width: 420px;
	padding: 30px;
	position: relative;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.qrcode-header {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 20px;

	.back-btn {
		cursor: pointer;
		color: #666;
		font-size: 16px;
		display: flex;
		align-items: center;

		&:hover {
			color: #46B4B1;
		}

		&:before {
			content: "←";
			margin-right: 4px;
		}
	}
}

.qrcode-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 0;
}

.qrcode-title {
	font-size: 20px;
	font-weight: 500;
	color: #333;
	margin-bottom: 12px;
}

.qrcode-amount {
	font-size: 28px;
	font-weight: 700;
	color: #333;
	margin-bottom: 24px;
}

.qrcode-image {
	width: 240px;
	min-height: 240px;
	background-color: #f5f5f5;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 24px;
	border: 1px solid #eee;
	position: relative;

	&.loading {
		background-color: #f9f9f9;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.loading-spinner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 12px;

		i {
			font-size: 32px;
			color: #46B4B1;
		}

		span {
			color: #666;
			font-size: 14px;
		}
	}

	.pay-success,
	.pay-error {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 12px;

		i {
			font-size: 48px;
		}

		span {
			font-size: 16px;
		}
	}

	.pay-success i {
		color: #67C23A;
	}

	.pay-error i {
		color: #F56C6C;
	}
}

.qrcode-tips {
	font-size: 16px;
	color: #666;

	&.status-waiting {
		color: #E6A23C;
	}
}

.retry-btn {
	margin-top: 16px;
	padding: 8px 24px;
	background: #46B4B1;
	color: white;
	border: none;
	border-radius: 4px;
	font-size: 14px;
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		background: #3a9a97;
	}
}

.policy-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  .modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;

    iframe {
      flex-grow: 1;
      width: 100%;
      height: calc(100% - 50px);
      border: none;
    }

    .close-btn {
      height: 50px;
      width: 100%;
      background-color: $color-primary;
      color: white;
      border: none;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>