<template>
    <header class="topbar__wrapper">
        <div class="topbar topbar--show">
            <div class="topbar__main">
                <div class="logo">
                    <router-link to="/community/home">
                        <img src="@/assets/images/logo.png" alt="">
                        <img src="@/assets/images/logo-font.png" alt="">
                    </router-link>
                </div>
                <!-- 导航功能 -->
                <div class="navs">
                    <ul class="navs">
                        <router-link v-for="item in navItems" :key="item.name" :to="item.link" class="nav-item">{{
                            item.name }}</router-link>
                    </ul>
                </div>
                <SearchInput v-model="searchKey" placeholder="搜索题目" @search="handleSearch"></SearchInput>
                <div class="notice">
                    <!-- <img src="@/assets/images/notice.svg" alt=""> -->
                </div>
                <span :style="versionStyle" class="version-tag">
                    <img v-if="versionInfo.version" src="@/assets/images/vip.svg"
                        alt="">
                    {{ versionTag }}
                    <span v-if="versionInfo.version"></span>
                </span>
                <el-dropdown trigger="hover" @command="handleCommand">
                    <div class="user-info">
                        <div class="user-avatar">
                            <user-avatar :url="userInfo.avatar" :nick-name="userInfo.nickName"
                                :round="true"></user-avatar>
                        </div>
                        <span class="topbar-nickname">{{ formatNickName }}</span>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="profile">
                            <i class="el-icon-user"></i> 个人主页
                        </el-dropdown-item>
                        <el-dropdown-item command="logout">
                            <i class="el-icon-switch-button"></i> 退出登录
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </header>
</template>

<script>
import SearchInput from './searchInput.vue';
export default {
    name: 'TopBar',
    props: {
        // 通过 props 传入不同的导航配置
        type: {
            type: String,
            default: 'community',
            validator: value => ['community', 'studyRoom'].includes(value)
        }
    },
    components: {
        SearchInput
    },

    computed: {
        navItems() {
            return this.type === 'community'
                ? [
                    // { name: '谈谈备考', link: '/community/home' },
                    // { name: '平日闲聊', link: '/community/chat' },
                    // { name: '争议题讨论', link: '/community/argue' },
                ]
                : [
                    // { name: '囊中社区', link: '/community/home' },
                    { name: '申论学习室', link: '/community/studyRoom/paper' },
                ];
        },
        userInfo() {
            return this.$store.getters['user/getState']
        },
        versionInfo() {
            return this.$store.getters['user/getState'].versionInfo || {};
        },
        formatNickName() {
            const name = this.userInfo.nickName || '囊友';
            return name.length > 5 ? name.slice(0, 5) + '...' : name;
        },
        versionTag() {
            if (this.versionInfo.version === 'clean') {
                const daysLeft = this.calculateDaysLeft(this.versionInfo.versionExpireTime);
                if (daysLeft < 0) {
                    this.versionStyle = 'background:#ccc;'
                } else {
                    this.versionStyle = 'background:#B89A70;'
                }
                return '纯净版';
            } else if (this.versionInfo.version === 'pro') {
                const daysLeft = this.calculateDaysLeft(this.versionInfo.versionExpireTime);
                if (daysLeft < 0) {
                    this.versionStyle = 'background:#ccc;'
                } else {
                    this.versionStyle = 'background:#666666;'
                }
                return '尊享版';
            } else {
                this.versionStyle = 'background:#ccc;'
                return '普通版'
            }
        }
    },
    data() {
        return {
            searchKey: '',
            versionStyle: "",
        }
    },
    methods: {
        calculateDaysLeft(expireDate) {
            const today = new Date();
            const expire = new Date(expireDate);
            const timeDiff = expire - today;
            const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
            return daysLeft;
        },
        handleCommand(command) {
            switch (command) {
                case 'profile':
                    this.$router.push(`/community/user/${this.userInfo.userId}`);
                    break;
                case 'logout':
                    this.$store.dispatch('user/LogOut').then(() => {
                        this.$router.push('/login/code');
                    }).catch(error => {
                        this.$message.error('退出失败，请重试');
                    });
                    break;
            }
        },

        handleSearch(keyword) {
            this.$eventBus.$emit('search', { keyword: keyword })
        }
    }
}
</script>
<style scoped lang="scss">
.topbar__wrapper {
    z-index: 1000;
    position: relative;
    height: 60px;
}

.topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 4px #eee;
    transition: all .2s;

    &.topbar--show {
        transform: translateY(0);
    }

    &.topbar--hidden {
        transform: translateY(-100%);
    }
}

.topbar__main {
    display: flex;
    align-items: center;
    max-width: 1280px;
    padding: 0 40px;
    height: 100%;
    margin: 0 auto;

    .logo {
        margin-right: 20px;
        cursor: pointer;

        img:first-child {
            width: 32px;
            margin-right: 16px;
        }

        img:last-child {
            width: 108px;
            margin-right: 56px;
        }
    }

    .version-tag {
        border-radius: 14px;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 10px;
        padding: 3px 6px;
        justify-content: space-between;
        margin-right: 5px;
        cursor: pointer;
        img {
            width: 14px;
            margin-right: 5px;
        }
    }
}

.navs {
    display: flex;
    line-height: 60px;
    height: 60px;
    font-size: $font-size-Level6;
    color: $font-color-fourth;

    .nav-item {
        padding: 0 20px;
        cursor: pointer;

        &:hover,
        &.router-link-active {
            color: $color-primary;
        }
    }

}

.notice {
    margin-left: auto;
    margin-right: 18px;
    cursor: pointer;
}

.user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;

    &:hover {
        background-color: $background-color-secondary;
        border-radius: 4px;
    }

    .user-avatar {
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    .topbar-nickname {
        color: $font-color-primary;
        font-size: $font-size-primary;
        max-width: 120px;
        display: inline-block;
        white-space: nowrap;
    }
}

:deep(.el-dropdown-menu__item) {
    display: flex;
    align-items: center;
    padding: 8px 20px;

    i {
        margin-right: 8px;
        font-size: 16px;
    }

    &:hover {
        background-color: $background-color-fourth;
        color: $color-primary;
    }
}
</style>