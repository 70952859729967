<template>
  <div class="about-author shadow" v-if="info">
    <div class="author__main">
      <router-link v-show="showInfo" :to="'/user/'+info.userInfo.userId" class="author__info-block" target="_blank">
        <div class="author__avatar">
          <user-avatar :url="info.userInfo.avatar_large" :round="true"></user-avatar>
        </div>
        <div class="author__info">
          <div class="author__username">
            {{ info.userInfo.userName }}
          </div>
          <div class="author__username__desc">
            个性签名
          </div>
        </div>
      </router-link>
      <div class="author__meta">
        <div class="honor-item">
          <span class="honor-item__data"> 100</span>
          <span class="honor-item__desc">杂谈 </span>
        </div>
        <div class="honor-item">
          <span class="honor-item__data"> 100</span>
          <span class="honor-item__desc">闲聊</span>
        </div>
        <div class="honor-item">
          <span class="honor-item__data"> 100</span>
          <span class="honor-item__desc">阅读 </span>
        </div>
        <div class="honor-item">
          <span class="honor-item__data"> 100</span>
          <span class="honor-item__desc">粉丝</span>
        </div>
      </div>
      <div class="author__action" v-show="showFollowbtn">
        <follow-btn is-follow.sync="true" type="user" followee-id="1"></follow-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
    showFollowbtn: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang='scss' scoped>
.about-author{
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  .author__meta{
    display: flex;
    justify-content: space-between;
    margin: 8px 0px 12px 0px;
    .honor-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .honor-item__data{
        font-size: 16px;
        color: #000;
      }
      .honor-item__desc{
        color: #8A919F;
      }
    }
  }
  
  .author__info-block{
    display: flex;
    cursor: pointer;

    .author__avatar{
      flex: 0 0 auto;
      width: 40px;
      height: 40px;
      margin-right: 12px;
    }

    .author__info{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      line-height: 1.2;
      overflow: hidden;
      .author__username{
        font-size: 16px;
      }
      .author__username__desc {
        color: #8A919F;
      }
    }
  }
  .author__action {
    display: flex;
    justify-content: center;
  }
}
</style>