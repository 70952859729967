import axios from "axios";
import { getToken } from '@/utils/auth'
import { tansParams } from '@/utils/nangzhong'
import cache from '@/plugins/cache'
import errorCode from '@/utils/errorCode'
import { MessageBox } from 'element-ui';
import {
    AESDecrypt
} from '@/utils/aes/encryption-utils.js';

// 是否显示重新登陆
export let isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const instance = axios.create({
    timeout: 10000
});

instance.interceptors.request.use(
    config => {
        // 是否需要设置 token
        const isToken = (config.headers || {}).isToken == false
        // 是否需要防止数据重复提交
        const isRepeatSubmit = (config.headers || {}).repeatSubmit == false
        if (getToken() && !isToken) {
            config.headers['Authorization'] = 'Bearer ' + getToken();
        }
        if(config.baseURL) {
            axios.baseURL = config.baseURL
        }
        // get请求映射params参数
        if (config.method === 'get' && config.params) {
            let url = config.url + '?' + tansParams(config.params);
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }
        if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
            const requestObj = {
                url: config.url,
                data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
                time: new Date().getTime()
            }
            const requestSize = Object.keys(JSON.stringify(requestObj)).length;
            const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
            if (requestSize >= limitSize) {
                console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。')
                return config;
            }
            const sessionObj = cache.session.getJSON('sessionObj')
            if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
                cache.session.setJSON('sessionObj', requestObj)
            } else {
                const s_url = sessionObj.url;                  // 请求地址
                const s_data = sessionObj.data;                // 请求数据
                const s_time = sessionObj.time;                // 请求时间
                const interval = 1000;
                // 间隔时间(ms)，小于此时间视为重复提交  
                if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
                    const message = '数据正在处理，请勿重复提交';
                    console.warn(`[${s_url}]: ` + message)
                    return Promise.reject(new Error(message));
                } else {
                    cache.session.setJSON('sessionObj', requestObj);
                }
            }
        }
        return config;
    },
    error => {
        console.log(error);
        Promise.reject(error);
    }
)

// 响应拦截器处理函数
function responseInterceptor(res) {
    if(typeof res.data === "string") {
        res.data = AESDecrypt(res.data);
    } 
    return res;
}

instance.interceptors.response.use(
    res => {
        res = responseInterceptor(res);
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode['default']
        // 二进制数据则直接返回
        if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
            return res.data
        }
        if (code === 401) {
            if (!isRelogin.show) {
                isRelogin.show = true;
                // 这里需要添加登录跳转逻辑，例如：
                MessageBox.confirm(
                    '登录状态已过期，您可以继续留在该页面，或者重新登录',
                    '系统提示',
                    {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                ).then(() => {
                    // 清除用户信息
                    store.dispatch('LogOut').then(() => {
                        // 跳转到登录页
                        location.href = '/login';
                    })
                }).catch(() => {
                    isRelogin.show = false;
                });
            }
            return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
        } else if (code === 500) {
            return Promise.reject(msg)
        } else if (code === 601) {
            return Promise.reject('error')
        } else if (code !== 200) {
            return Promise.reject('error')
        } else {
            return res.data;
        }
    },
    error => {
        let { message } = error;
        if (message.includes("Network Error")) {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        return Promise.reject(message)
    }
)

export default instance;