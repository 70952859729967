<!-- components/SearchInput.vue -->
<template>
    <div class="search">
        <input type="search" :value="value" @input="handleInput" @keyup.enter="triggerSearch"
            :placeholder="placeholder">
        <img :src="icon" class="search-icon" @click="triggerSearch">
    </div>
</template>

<script>
export default {
    name: 'SearchInput',
    props: {
        value: {  // Vue2的v-model默认prop
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '搜索内容...'
        },
        icon: {
            type: String,
            default: require('@/assets/images/search.svg')
        }
    },
    methods: {
        // 输入处理
        handleInput(e) {
            this.$emit('input', e.target.value)  // Vue2的v-model默认事件
        },

        // 触发搜索
        triggerSearch() {
            this.$emit('search', this.value)
        }
    }
}
</script>

<style scoped lang="scss">
/* 添加以下样式 */
/* 全浏览器清除按钮隐藏 */
input[type="search"]::-webkit-search-cancel-button { /* Chrome/Edge */
  -webkit-appearance: none;
  appearance: none;
}

input[type="search"]::-moz-search-cancel-button { /* Firefox */
  display: none;
}

input[type="search"]::-ms-clear {  /* IE10+ */
  display: none;
  width: 0;
  height: 0;
}
.search {
    margin-left: auto;
    display: flex;
    align-items: center;
    position: relative;

    input {
        width: 260px;
        height: 28px;
        border-radius: 28px;
        border: 1px solid $color-third;
        padding: 0 20px;

        &:focus {
            border: 1px solid $color-primary;
        }
    }

    img {
        position: absolute;
        right: 20px;
        cursor: pointer;
    }
}
</style>