<template>
  <div class="user-content">
    <div class="main-content">
      <user-info class="user-info"></user-info>
      <div class="index-main shadow">
        <div class="list__header">
          <ul class="list__nav">
            <li class="list__nav-item" :class="{ 'list__nav-item--active': item.id === navId }" v-for="item in navs"
              :key="item.title" @click="changeNavType(item)">
              {{ item.title }}
            </li>
          </ul>
        </div>



        <!-- 谈谈内容 -->
        <div v-if="navId === 1">
          <div v-if="list.length > 0">
            <div v-for="(item, index) in list" :key="index" class="article-item">
              <h3 class="article-title">{{ item.title }}</h3>
              <p class="article-content">{{ item.content }}</p>
              <div class="article-meta">
                <span class="time">{{ item.createTime }}</span>
                <span class="views">阅读 {{ item.viewCount }}</span>
                <span class="likes">点赞 {{ item.likeCount }}</span>
              </div>
            </div>
          </div>
          <div v-else class="empty-tip">暂无内容</div>
        </div>

        <!-- 收藏夹内容 -->
        <div v-if="navId === 4">
          <div v-if="collectionList.length > 0">
            <div v-for="(item, index) in collectionList" :key="index" class="collection-item">
              <div class="collection-content">
                <h3 class="collection-title" @click="goToPaperDetail(item.paperId)">{{ item.title }}</h3>
                <p class="collection-desc">{{ item.description }}</p>
              </div>
              <div class="collection-meta">
                <span class="time">{{ item.createTime }}</span>
                <el-button type="text" class="uncollect-btn" @click="uncollectPaper(item.paperId)"
                  :loading="item.loading">
                  <i class="el-icon-star-off"></i>
                  取消收藏
                </el-button>
              </div>
            </div>
          </div>
          <div v-else class="empty-tip">暂无收藏内容</div>
        </div>

        <!-- 随手记内容 -->
        <div v-if="navId === 5">
          <div v-if="noteList.length > 0">
            <div v-for="(item, index) in noteList" :key="index" class="note-item">
              <div class="note-header">
                <h3 class="note-title" @click="goToPaperDetail(item.paper.paperId)">
                  {{ item.paper.title }}
                  <span class="question-index">第{{ item.question.questionIndex }}题</span>
                </h3>
              </div>
              <div class="note-content">{{ item.content }}</div>
              <div class="note-meta">
                <span class="time">{{ item.createTime }}</span>
                <el-button type="text" class="delete-btn" @click="deleteNote(item.noteId)" :loading="item.loading">
                  <i class="el-icon-delete"></i>
                  删除
                </el-button>
              </div>
            </div>
          </div>
          <div v-else class="empty-tip">暂无随手记</div>
        </div>

        <!-- 答案库内容 -->
        <div v-if="navId === 6">
          <div v-if="answerList.length > 0">
            <div v-for="(item, index) in answerList" :key="index" class="answer-item">
              <div class="answer-header">
                <h3 class="answer-title" @click="goToPaperDetail(item.paper.paperId)">
                  {{ item.paper.title }}
                  <span class="question-index">第{{ item.question.questionIndex }}题</span>
                </h3>
              </div>
              <div class="answer-content" v-html="item.content"></div>
              <div class="answer-meta">
                <span class="time">{{ item.createTime }}</span>
                <el-button type="text" class="delete-btn" @click="deleteUserAnswer(item.answerId)" :loading="item.loading">
                  <i class="el-icon-delete"></i>
                  删除
                </el-button>
              </div>
            </div>
          </div>
          <div v-else class="empty-tip">暂无答案记录</div>
        </div>

      </div>
    </div>
    <!-- <div class="right-section">
      <about-author class="about-author" :info="articDetail"></about-author>
    </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex';
import userInfo from "@/components/business/user/userInfo.vue";
import aboutAuthor from "@/components/business/detail/aboutAuthor.vue";
import { getArticleList, getCollectPaperList, getUserAnswerList, getUserNoteList, doAction, deleteUserNote, deleteUserAnswer } from '@/api';

export default {
  components: {
    userInfo,
    aboutAuthor,
  },
  data() {
    return {
      value: "",
      navs: [
        // {
        //   id: 1,
        //   title: "谈谈",
        //   type: 1,
        // },
        // {
        //   id: 2,
        //   title: "闲聊",
        //   type: 2,
        // },
        // {
        //   id: 3,
        //   title: "关注",
        //   type: 3,
        // },
        {
          id: 4,
          title: "收藏夹",
          type: 4,
        },
        {
          id: 5,
          title: "随手记合集",
          type: 5,
        },
        {
          id: 6,
          title: "我的答案库",
          type: 6,
        },
      ],
      navId: 4,
      list: [],
      publishList: [],
      collectionList: [],
      noteList: [],
      answerList: [],
      loading: false,
      articDetail: {
        userInfo: this.$store.getters['user/getState']
      },
      versionVisable: false,
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      try {
        await this.loadCurrentTabData();
      } catch (error) {
        console.error('加载数据失败:', error);
        this.$message.error('加载数据失败');
      } finally {
        this.loading = false;
      }
    },
    async loadCurrentTabData() {
      const userId = this.$store.getters['user/getState'].userId;
      switch (this.navId) {
        case 1: // 谈谈
          // await this.loadArticles();
          break;
        case 4: // 收藏夹
          await this.loadCollections();
          break;
        case 5: // 随手记合集
          await this.loadNotes();
          break;
        case 6: // 我的答案库
          await this.loadUserAnswers();
          break;
      }
    },
    async loadArticles() {
      const params = {
        userId: this.$store.getters['user/getState'].userId,
        pageSize: 10,
        pageNum: 1
      };
      const res = await getArticleList(params);
      this.list = res.rows || [];
    },
    async loadCollections() {
      const params = {
        pageSize: 10,
        pageNum: 1
      };
      const res = await getCollectPaperList(params);
      this.collectionList = res.rows || [];
    },
    async loadNotes() {
      const params = {
        userId: this.$store.getters['user/getState'].userId,
        pageSize: 10,
        pageNum: 1,
        targetType: 2
      };
      const res = await getUserNoteList(params);
      this.noteList = res.rows || [];
    },
    async loadUserAnswers() {
      const params = {
        userId: this.$store.getters['user/getState'].userId,
        pageSize: 10,
        pageNum: 1
      };
      const res = await getUserAnswerList(params);
      this.answerList = res.rows || [];
    },
    async changeNavType(item) {
      this.navId = item.id;
      await this.loadCurrentTabData();
    },
    async uncollectPaper(paperId) {
      try {
        // 找到对应的收藏项并设置loading状态
        const item = this.collectionList.find(item => item.paperId === paperId);
        if (item) {
          this.$set(item, 'loading', true);
        }

        // 调用取消收藏接口
        await doAction({
          status: '0',
          actionType: 3, // 收藏操作
          targetType: 1, // 试卷类型
          targetId: paperId
        });

        // 刷新收藏列表
        await this.loadCollections();
        this.$message.success('已取消收藏');
      } catch (error) {
        console.error('取消收藏失败:', error);
        this.$message.error('取消收藏失败，请重试');
      } finally {
        // 清除loading状态
        const item = this.collectionList.find(item => item.paperId === paperId);
        if (item) {
          this.$set(item, 'loading', false);
        }
      }
    },
    goToPaperDetail(paperId) {
      this.$router.push(`/community/studyRoom/paper/detail/${paperId}`);
    },

    async deleteNote(noteId) {
      try {
        // 找到对应的笔记项并设置loading状态
        const item = this.noteList.find(item => item.noteId === noteId);
        if (item) {
          this.$set(item, 'loading', true);
        }

        await this.$confirm('确定要删除这条笔记吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        // 调用删除笔记接口
        await deleteUserNote(noteId);

        // 刷新笔记列表
        await this.loadNotes();
        this.$message.success('笔记已删除');
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除笔记失败:', error);
          this.$message.error('删除笔记失败，请重试');
        }
      } finally {
        // 清除loading状态
        const item = this.noteList.find(item => item.noteId === noteId);
        if (item) {
          this.$set(item, 'loading', false);
        }
      }
    },

    async deleteUserAnswer(answerId) {
      try {
        // 找到对应的答案项并设置loading状态
        const item = this.answerList.find(item => item.answerId === answerId);
        if (item) {
          this.$set(item, 'loading', true);
        }

        await this.$confirm('确定要删除这条答案吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        // 调用删除答案接口
        await deleteUserAnswer(answerId);

        // 刷新答案列表
        await this.loadUserAnswers();
        this.$message.success('答案已删除');
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除答案失败:', error);
          this.$message.error('删除答案失败，请重试');
        }
      } finally {
        // 清除loading状态
        const item = this.answerList.find(item => item.answerId === answerId);
        if (item) {
          this.$set(item, 'loading', false);
        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.user-content {
  display: flex;
  gap: 20px;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  .main-content {
    flex: 1;
    min-width: 0;
    width: 100%;
  }

  .right-section {
    width: 300px;
    flex-shrink: 0;
  }
}

.index-main {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
}

.list__header {
  margin-bottom: 20px;
  border-bottom: 1px solid #e4e6eb;
}

.list__nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  .list__nav-item {
    padding: 12px 24px;
    cursor: pointer;
    position: relative;
    color: #86909c;

    &:hover {
      color: #1d2129;
    }

    &--active {
      color: #1d2129;
      font-weight: 500;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 2px;
        background: #46B4B1;
      }
    }
  }
}

.empty-tip {
  text-align: center;
  color: #86909c;
  padding: 40px 0;
}

.article-item,
.collection-item,
.note-item,
.answer-item {
  padding: 20px 0;
  border-bottom: 1px solid #e4e6eb;

  &:last-child {
    border-bottom: none;
  }

  .article-title,
  .collection-title {
    font-size: 16px;
    font-weight: 500;
    color: #1d2129;
    margin: 0 0 12px;
  }

  .article-content,
  .collection-desc,
  .note-content,
  .answer-content {
    font-size: 14px;
    color: #4e5969;
    margin: 0 0 12px;
    line-height: 1.6;
  }

  .article-meta,
  .collection-meta,
  .note-meta,
  .answer-meta {
    font-size: 12px;
    color: #86909c;

    span {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.collection-item {
  padding: 20px 0;
  border-bottom: 1px solid #e4e6eb;
  display: flex;
  flex-direction: column;

  &:last-child {
    border-bottom: none;
  }

  .collection-content {
    flex: 1;
  }

  .collection-title {
    font-size: 16px;
    font-weight: 500;
    color: #1d2129;
    margin: 0 0 12px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #46B4B1;
    }
  }

  .collection-desc {
    font-size: 14px;
    color: #4e5969;
    margin: 0 0 12px;
    line-height: 1.6;
  }

  .collection-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #86909c;

    .uncollect-btn {
      padding: 0;
      font-size: 12px;
      color: #f56c6c;

      &:hover {
        color: #f78989;
      }

      i {
        margin-right: 4px;
      }
    }
  }
}

.answer-item {
    padding: 20px 0;
    border-bottom: 1px solid #e4e6eb;

    &:last-child {
      border-bottom: none;
    }

    .answer-header {
      margin-bottom: 12px;
    }

    .answer-title {
      font-size: 16px;
      font-weight: 500;
      color: #1d2129;
      cursor: pointer;
      transition: color 0.3s;
      display: flex;
      align-items: center;

      &:hover {
        color: #46B4B1;
      }

      .question-index {
        margin-left: 8px;
        font-size: 14px;
        color: #86909c;
        font-weight: normal;
      }
    }
    .answer-content {
      font-size: 14px;
      color: #4e5969;
      margin: 12px 0;
      line-height: 1.6;
      background-color: #f9f9f9;
      padding: 12px;
      border-radius: 4px;
    }

    .answer-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #86909c;

    .delete-btn {
      padding: 0;
      font-size: 12px;
      color: #f56c6c;

      &:hover {
        color: #f78989;
      }

      i {
        margin-right: 4px;
      }
    }
  }
  }

.note-item {
  padding: 20px 0;
  border-bottom: 1px solid #e4e6eb;

  &:last-child {
    border-bottom: none;
  }

  .note-header {
    margin-bottom: 12px;
  }

  .note-title {
    font-size: 16px;
    font-weight: 500;
    color: #1d2129;
    cursor: pointer;
    transition: color 0.3s;
    display: flex;
    align-items: center;

    &:hover {
      color: #46B4B1;
    }

    .question-index {
      margin-left: 8px;
      font-size: 14px;
      color: #86909c;
      font-weight: normal;
    }
  }


  .note-content {
    font-size: 14px;
    color: #4e5969;
    margin: 12px 0;
    line-height: 1.6;
    background-color: #f9f9f9;
    padding: 12px;
    border-radius: 4px;
  }

  .note-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #86909c;

    .delete-btn {
      padding: 0;
      font-size: 12px;
      color: #f56c6c;

      &:hover {
        color: #f78989;
      }

      i {
        margin-right: 4px;
      }
    }
  }
}
</style>
