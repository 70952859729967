import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/views/home/index.vue';
import RegisterPage from '@/views/login/register/index.vue';
import CodeLoginPage from '@/views/login/code/index.vue';
import BindLoginPage from '@/views/login/bind/index.vue';
import AccountLoginPage from '@/views/login/account/index.vue';
import ForgetPasswordPage from '@/views/login/forget/index.vue';
import CommunityHomePage from '@/views/community/home/index.vue';
import CommunityChatPage from '@/views/community/chat/index.vue';
import CommunityArguePage from '@/views/community/argue/index.vue';
import CommunityDetailPage from '@/views/community/detail/index.vue';
import CommunityPublishPage from '@/views/community/publish/index.vue';
import CommunityPublishedPage from '@/views/community/published/index.vue';
import CommunityUserPage from '@/views/community/user/index.vue';
import PaperPage from '@/views/community/studyRoom/paper/index.vue';
import PaperDetailPage from '@/views/community/studyRoom/paper/detail/index.vue';
import AddAnswerPage from '@/views/community/studyRoom/addAnswer/index.vue';
import ClockPage from '@/views/clock/index.vue';
import WxServeLoginPage from '@/wx-serve/login/index.vue';
import WxServeVersionPage from '@/wx-serve/version/index.vue';

Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        redirect: "/home"
    },
    {
        path: '/home',
        name: 'home',
        component: HomePage,
        meta: {
            title: '官网'
        }
    },
    {
        path: '/login/code',
        component: CodeLoginPage,
        meta: {
            title: '二维码登录'
        }
    },
    {
        path: '/login/bind',
        component: BindLoginPage,
        meta: {
            title: '绑定账户'
        }
    },
    {
        path: '/login/register',
        component: RegisterPage,
        meta: {
            title: '注册账号'
        }
    },
    {
        path: '/login/account',
        component: AccountLoginPage,
        meta: {
            title: '账号登录'
        }
    },
    {
        path: '/login/forget',
        component: ForgetPasswordPage,
        meta: {
            title: '忘记密码'
        }
    },
    {
        path: '/community/home',
        component: CommunityHomePage,
        meta: {
            title: '社区首页'
        }
    },
    {
        path: '/wx-serve/login',
        component: WxServeLoginPage,
        meta: {
            title: '微信登录'
        }
    },
    {
        path: '/wx-serve/version',
        component: WxServeVersionPage,
        meta: {
            title: '开通会员'
        }
    },
    {
        path: '/community/chat',
        component: CommunityChatPage,
        meta: {
            title: '平日闲聊'
        }
    },
    {
        path: '/community/argue',
        component: CommunityArguePage,
        meta: {
            title: '争议题讨论'
        }
    },
    {
        path: '/community/detail/:id',
        component: CommunityDetailPage,
        name: "detail-id",
        meta: {
            title: '详情'
        }
    },
    {
        path: '/community/user/:id',
        component: CommunityUserPage,
        name: "user-id",
        meta: {
            title: '个人中心'
        }
    },
    {
        path: '/community/publish/:id',
        component: CommunityPublishPage,
        name: 'CommunityPublishPage',
        meta: {
            title: '内容发布'
        }
    },
    {
        path: '/community/published',
        component: CommunityPublishedPage,
        meta: {
            title: '发布完成'
        }
    },
    {
        path: '/community/studyRoom/paper',
        component: PaperPage,
        meta: {
            title: '历年试卷'
        }
    },
    {
        path: '/community/studyRoom/paper/detail/:paperId',
        name: 'PaperDetail',
        component: PaperDetailPage,
        meta: {
            title: '试卷详情'
        }
    },
    {
        path: '/community/studyRoom/addAnswer',
        component: AddAnswerPage,
        meta: {
            title: '补充答案'
        }
    },
    {
        path: '/clock',
        name: 'clock',
        component: ClockPage,
        meta: {
            title: '专注时钟'
        }
    },
];

const route = new VueRouter({
    routes,
    mode: 'history'
})

export default route;