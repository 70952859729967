<template>
    <div class="tool-box">
        <div class="learn-classroom">申论学习室</div>
        <div class="tool-item-list">
            <div class="tool-item" @click="goToPaper" :class="{ 'active': isPaperPage }">
                <span>
                    <img src="@/assets/images/paper.png" alt="">
                </span>
                <span class="desc">历年试卷</span>
            </div>
            <div class="tool-item" @click="goToAddAnswer" :class="{ 'active': isAddAnswerPage }">
                <span>
                    <img src="@/assets/images/add-answer.png" alt="">
                </span>
                <span class="desc">补充答案</span>
            </div>
            <div class="tool-item" @click="goToClock">
                <span>
                    <img src="@/assets/images/focus-time.png" alt="">
                </span>
                <span class="desc">专注时钟</span>
            </div>
            <!-- <div class="tool-item">
                <span>
                    <img src="@/assets/images/online-question.png" alt="">
                </span>

                <span class="desc">在线做题</span>
            </div>
            <div class="tool-item">
                <span>
                    <img src="@/assets/images/do-list.png" alt="">
                </span>
                <span class="desc">我的清单</span>
            </div>
            <div class="tool-item">
                <span>
                    <img src="@/assets/images/learn-patern.png" alt="">
                </span>

                <span class="desc">学习搭子</span>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
    },
    data() {
        return {
            isPaperPage: false,
            isAddAnswerPage: false
        }
    },
    created() {
        this.isPaperPage = this.$route.path === '/community/studyRoom/paper';
        this.isAddAnswerPage = this.$route.path === '/community/studyRoom/addAnswer';
    },
    watch: {
        '$route'(to) {
            this.isPaperPage = to.path === '/community/studyRoom/paper';
            this.isAddAnswerPage = to.path === '/community/studyRoom/addAnswer';
        }
    },
    methods: {
        goToPaper() {
            this.$router.push('/community/studyRoom/paper');
        },
        goToAddAnswer() {
            this.$router.push('/community/studyRoom/addAnswer');
        },
        goToClock() {
            this.$router.push('/clock');
        }
    }
}
</script>

<style lang='scss' scoped>
.tool-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background-color;
    border-radius: 10px;
    padding: 16px;

    .learn-classroom {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-primary;
        font-size: $font-size-Level6;
        font-weight: 600;
    }

    .tool-item-list {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;

        .tool-item {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 24px;
            cursor: pointer;
            padding: 8px;
            border-radius: 8px;
            border: 1px solid transparent;
            transition: all 0.3s ease;

            span {
                img {
                    width: 28px;
                    transition: filter 0.3s ease;
                }
            }
            
            .desc {
                color: $font-color-fourth;
                margin-top: 12px;
                border-radius: 4px;
            }

            &.active {
                background-color: rgba($color-primary, 0.1);
                border: 1px solid $color-primary;

                .desc {
                    color: $color-primary;
                }
            }

            &:hover {
                background-color: rgba($color-primary, 0.05);
            }
        }
    }
}
</style>