<template>
  <div class="artic-item" @click="toDetail()">
    <div class="artic-item__info">
      <p class="artic-item__title ellipsis" v-html="highlight.title || item.articleInfo.title"></p>
      <p v-if="hasDesc" class="artic-item__desc" v-html="highlight.description || highlight.text || item.articleInfo.descContent"></p>
      <ul class="artic-item__meta__action">
        <li class="meta-item">
          <span class="meta-item__username">
            <router-link :to="'/community/user/'+uid" target="_blank" @click.native="(e) => e.stopPropagation()">{{ item.userInfo.userName }}</router-link>
          </span>
        </li>
        <li class="meta-item">{{ item.articleInfo.createTime  }}</li>
        <li class="action-item" :class="{active: item.articleInfo[likeField]}" @click.stop="articleLike">
          <img v-if="item.articleInfo[likeField]" class="action-item__icon" src="@/assets/images/like.svg">
          <img v-else class="action-item__icon" src="@/assets/images/like.svg">
          <!-- {{ item.articleInfo[likeCount] }} -->100
        </li>
        <li class="action-item" @click.stop="toDetail('#comment')">
          <img class="action-item__icon" src="@/assets/images/comment.svg">
          {{ item.articleInfo.commentCount }}
        </li>
        <li class="meta-item">
          <template v-if="item.tags.length">
            <router-link class="meta-item__tag" :to="'/tag?name='+tag.tagName" v-for="tag in item.tags" :key="tag.id" target="_blank" @click.native="(e) => e.stopPropagation()">{{ tag.tagName }}</router-link>
          </template>
        </li>
      </ul>
    </div>
    <div v-if="item.articleInfo.coverImage" class="artic-item__cover" :style="'background-image: url('+item.articleInfo.coverImage+')'"></div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    hasDesc: {
      type: Boolean,
      default: true,
    },
    highlight: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    // 点赞状态字段不一致  此处筛选出对应字段
    likeField() {
      let fields = ['is_like']
      return fields.filter(key => this.item[key] === undefined ? false : key)[0]
    },
    // 点赞数字段不一致  此处筛选出对应字段
    likeCount() {
      let fields = ['like_count']
      return fields.filter(key => this.item.articleInfo[key] === undefined ? false : key)[0]
    },
    // 统一 uid值
    uid() {
      return this.item.userInfo.userId
    },
    detailId() {
      console.log(this.item);
      return this.item.articleId
    }
  },
  methods: {
    toDetail(hash = '') {
      let routeUrl = this.$router.resolve({
        name: 'detail-id',
        params: {
          id: this.detailId
        }
      })
      window.open(routeUrl.href + hash, '_blank')
    },
    async articleLike() {
      if (!this.$store.state.auth.token) {
        this.$loginModal(this)
        return
      }
      let id = this.item.id
      if (id && this.likeField && this.likeCount) {
        let res = await this.$api.articleLike({
          entryId: id,
          isCollected: !this.item[this.likeField]
        })
        // 更新视图点赞状态
        if (res.s === 1) {
          this.$emit('update:item', {
            ...this.item,
            [this.likeField]: !this.item[this.likeField],
            [this.likeCount]: this.item[this.likeField] ? Number(this.item[this.likeCount]) - 1 : Number(this.item[this.likeCount]) + 1
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.artic-item {
  display: flex;
  align-items: center;
  padding: 20px 25px;
  cursor: pointer;

  &:hover{
    background: rgba(0,0,0,.01);
  }
}

.artic-item__info {
  flex: 1 1 auto;
  overflow: hidden;
}

.artic-item__cover {
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  margin-left: 30px;
  background-color: $background-color-secondary;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2px;
}

.artic-item__meta__action {
  display: flex;
  align-items: center;
  font-size: $font-size-Level7;
  color: $font-color-fourth;

  .meta-item {
    &::after {
      content: "";
      margin: 0 5px;
      color: $font-color-fourth;
    }

    &:last-child::after {
      content: "";
    }
    &:last-child {
      margin-left: auto;
    }
  }

  .meta-item__username {
    cursor: pointer;
    
    &>a:hover{
      color: $color-primary !important;
    }
    &::after {
      margin: 0 12px;
      content: " | ";
      color: #b2bac2;
    }
  }

  .meta-item__tag {
    cursor: pointer;
    background-color: $background-color-secondary;
    color: $color-primary;
    margin-right: 8px;
    padding: 2px 8px;
    border-radius: var(----semi-border-radius-extra-small, 3px);
    &:hover {
      background-color: $background-color-fourth;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .action-item {
    display: flex;
    align-items: center;
    height: 26px;
    padding: 0 10px;
    

    .action-item__icon{
      margin-right: 3px;
      display: block;
      width: 16px;
      height: 16px;
    }

    &.active{
      // color: $success;
    }

    &:last-child {
      border-left: 0;
    }
  }
}

.artic-item__title {
  display: block;
  margin: 10px 0 16px;
  line-height: 1.3;
  font-size: $font-size-Level5;
  font-weight: 700;
  color: inherit;
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  }

   ::v-deep  em{
    color: #e8001c;
  }
}

.artic-item__desc {
  
  margin-bottom: 14px;
  font-size: $font-size-primary;
  color: #5b6169;
  text-overflow: ellipsis;
  overflow: hidden;

   ::v-deep  em{
    color: #e8001c;
  }
}

</style>