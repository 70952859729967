<template>
  <div class="page-container">
    <div class="body-content">
      <div class="index-container">
        <div class="action-buttons">
          <el-tooltip content="收藏试卷" placement="right" v-show="!isImmersive">
            <el-button class="icon-button" v-if="paperActionTarget && paperActionTarget.status == '1'" icon="el-icon-star-on" @click="action(['0',3,1,paperActionTarget.targetId])"></el-button>
            <el-button class="icon-button" v-else icon="el-icon-star-off" @click="action(['1',3,1,paperActionTarget.targetId])"></el-button>
          </el-tooltip>
          <el-tooltip content="评论" placement="right" v-show="!isImmersive">
            <el-button class="icon-button" icon="el-icon-chat-line-round" @click="showCommentDrawer = true"></el-button>
          </el-tooltip>
          <el-tooltip content="随手记" placement="right" v-show="!isImmersive">
            <el-button class="icon-button" icon="el-icon-edit-outline" @click="showNoteDrawer = true"></el-button>
          </el-tooltip>
          <el-tooltip content="沉浸模式" placement="right">
            <el-button class="icon-button" :icon="isImmersive ? 'el-icon-full-screen' : 'el-icon-rank'" @click="toggleImmersive"></el-button>
          </el-tooltip>
        </div>
        <div class="question-section" :class="{ 'full-width': isImmersive }">
          <div class="paper-header">
            <h2 class="paper-text">{{ paperTitle }}</h2>
          </div>
          <div class="question-tabs">
            <button v-for="(question, index) in questions" :key="index" class="tab-button"
              :class="{ active: currentQuestionIndex === question.questionIndex }" @click="setCurrentQuestion(question)">
              第{{ question.questionIndex }}题
            </button>
          </div>
          <div class="ql-editor" style="height: auto;padding: 0;">
            <div v-html="currentQuestion?.content" class="content-area"></div>
          </div>

          <div class="answer-tabs">
            <button v-for="(answer, index) in answerTabs" :key="index" class="tab-button"
              :class="{ active: currentAnswerIndex === index }" @click="setCurrentAnswer(index)">
              <img v-if="answer.icon" :src="answer.icon" alt="icon" class="answer-icon" />
              <span v-else>{{ answer.label }}{{ answer.showCount ? ` (${userAddAnswers.length})` : '' }}</span>
            </button>
          </div>
          <div class="ql-editor" style="height: auto;padding: 0;">
            <div v-if="currentAnswer.source !== '网友补充'" class="content-area" >
              <div class="answer-header">
                <div class="answer-info">
                  <el-tag size="small" type="success" effect="plain" class="answer-source">{{ currentAnswer.source ? "某" + currentAnswer.source.slice(1) : '' }}</el-tag>
                </div>
                <div class="answer-actions">
                  <div class="action-item">
                    <el-button type="text" class="action-btn" 
                      @click="action([currentAnswerSupport.isActive ? '0' : '1', 1, 3, currentAnswer.answerId])" 
                      :class="{ active: currentAnswerSupport.isActive }">
                      <img :src="require('@/assets/images/support.png')" class="action-icon" v-if="currentAnswerSupport.isActive" />
                      <img :src="require('@/assets/images/un-support.png')" class="action-icon" v-else />
                      <span>{{ currentAnswerSupport.count }}</span>
                    </el-button>
                  </div>
                  <div class="action-item">
                    <el-button type="text" class="action-btn" 
                      @click="action([currentAnswerAgainst.isActive ? '0' : '1', 2, 3, currentAnswer.answerId])" 
                      :class="{ active: currentAnswerAgainst.isActive }">
                      <img :src="require('@/assets/images/against.png')" class="action-icon" v-if="currentAnswerAgainst.isActive" />
                      <img :src="require('@/assets/images/un-against.png')" class="action-icon" v-else />
                      <span>{{ currentAnswerAgainst.count }}</span>
                    </el-button>
                  </div>
                </div>
              </div>
              <div v-if="answerShow" v-html="currentAnswer?.content"></div>
              <div v-else class="no-permission">
                <img src="@/assets/images/no-permission.png" />
						    <div class="desc">非会员无权限查看中公答案！</div>
              </div>
            </div>
            <div v-else class="content-area user-add-answers">
              <template v-if="userAddAnswers.length > 0">
                <div  v-if="userAddAnswerShow" class="user-add-answers-list">
                  <div v-for="(answer, index) in userAddAnswers" :key="index" class="user-add-answer-item">
                    <div class="answer-header">
                      <div class="answer-info">
                        <el-tag size="small" type="success" effect="plain" class="answer-source">{{ answer.source }}</el-tag>
                      </div>
                      <div class="answer-actions">
                        <div class="action-item">
                          <el-button type="text" class="action-btn" 
                            @click="action([addActionSupportList.find(item => item.targetId === answer.addId)?.status === '1' ? '0' : '1', 1, 4, answer.addId])" 
                            :class="{ active: addActionSupportList.find(item => item.targetId === answer.addId)?.status === '1' }">
                            <img :src="require('@/assets/images/support.png')" class="action-icon" v-if="addActionSupportList.find(item => item.targetId === answer.addId)?.status === '1'" />
                            <img :src="require('@/assets/images/un-support.png')" class="action-icon" v-else />
                            <span>{{ addActionSupportList.find(item => item.targetId === answer.addId)?.count || 0 }}</span>
                          </el-button>
                        </div>
                        <div class="action-item">
                          <el-button type="text" class="action-btn" 
                            @click="action([addActionAgainstList.find(item => item.targetId === answer.addId)?.status === '1' ? '0' : '1', 2, 4, answer.addId])" 
                            :class="{ active: addActionAgainstList.find(item => item.targetId === answer.addId)?.status === '1' }">
                            <img :src="require('@/assets/images/against.png')" class="action-icon" v-if="addActionAgainstList.find(item => item.targetId === answer.addId)?.status === '1'" />
                            <img :src="require('@/assets/images/un-against.png')" class="action-icon" v-else />
                            <span>{{ addActionAgainstList.find(item => item.targetId === answer.addId)?.count || 0 }}</span>
                          </el-button>
                        </div>
                      </div>
                    </div>
                    <div class="answer-content" v-html="answer.content"></div>
                  </div>
                </div>
                <div v-else class="no-permission">
                  <img src="@/assets/images/no-permission.png"/>
                  <div class="desc">非会员无权限查看网友补充答案！</div>
                </div>
              </template>
              <div v-else>暂无答案数据</div>
            </div>
          </div>
          <div class="answer-actions">
          Tips:先输入答案，点击
          <button @click="compareOverlap" class="action-button">对比重合度</button>
          根据对比情况，整理好答案后，再点击
          <button @click="submit('submit-user-answer')" class="action-button">上传至答案库</button>
        </div>
        <div class="answer-box">
          <quill-editor v-model="userAnswer" :options="editorOption" @change="onEditorChange($event)" />
          <div class="answer-info">
            <span>此题已上传过 {{ userAnswerCount }} 次答案，</span>
            <a href="javascript:" @click="reviewAnswers" class="review-link">点此回顾</a>
          </div>
        </div>

        </div>
        <div class="materials-section" v-if="!isCompareMode && !isImmersive">
          <div class="materials-tabs-container">
            <div class="materials-tabs">
              <button v-for="(material, index) in materials" :key="index" class="tab-button"
                :class="{ active: currentMaterialIndex === material.materialIndex }"
                @click="setCurrentMaterial(material)">
                材料{{ material.materialIndex }}
              </button>
            </div>
            <div class="materials-content">
              <div class="ql-editor" style="height: auto; padding: 0;">
                <div v-html="currentMaterial?.content" class="content-area"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-drawer
      :title="`评论 ${commentList.length}`"
      :visible.sync="showCommentDrawer"
      direction="rtl"
      size="30%"
      :before-close="handleDrawerClose">
      <div class="comment-container">
        <div class="comment-list">
          <div v-for="(comment, index) in commentList" :key="index" class="comment-item">
            <div class="comment-header">
              <el-avatar :size="32" :src="comment.user?.avatar || defaultAvatar"></el-avatar>
              <span class="username">{{ comment.user?.nickName || '囊友' }}</span>
              <span class="time">{{ formatTime(comment.createTime) }}</span>
              <el-button 
                v-if="comment.userId === $store.getters['user/getState'].userId"
                type="text" 
                class="delete-btn" 
                @click="deleteComment(comment.commentId)">
                <i class="el-icon-delete"></i>
              </el-button>
            </div>
            <div class="comment-content">{{ comment.content }}</div>
          </div>
          <div v-if="commentList.length === 0" class="no-comment">
            暂无评论，快来发表第一条评论吧~
          </div>
        </div>
        <div class="comment-input">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入您的评论"
            v-model="commentContent"
            maxlength="500"
            show-word-limit>
          </el-input>
          <el-button type="primary" @click="submitComment" :disabled="!commentContent.trim()" style="background-color: #46B4B1; border-color: #46B4B1;">
            发表评论
          </el-button>
        </div>
      </div>
    </el-drawer>

    <el-drawer
      :title="`随手记 ${noteList.length}`"
      :visible.sync="showNoteDrawer"
      direction="rtl"
      size="30%"
      :before-close="handleNoteDrawerClose">
      <div class="comment-container">
        <div class="comment-list">
          <div v-for="(note, index) in noteList" :key="index" class="comment-item">
            <div class="note-header">
              <span class="time">{{ formatTime(note.createTime) }}</span>
              <el-button type="text" class="delete-btn" @click="deleteNote(note.noteId)">
                <i class="el-icon-delete"></i>
              </el-button>
            </div>
            <div class="note-content">{{ note.content }}</div>
          </div>
          <div v-if="noteList.length === 0" class="no-comment">
            暂无笔记，快来记录第一条笔记吧~
          </div>
        </div>
        <div class="comment-input">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入您的笔记"
            v-model="noteContent"
            maxlength="500"
            show-word-limit>
          </el-input>
          <el-button type="primary" @click="submitNote" :disabled="!noteContent.trim()" style="background-color: #46B4B1; border-color: #46B4B1;">
            保存笔记
          </el-button>
        </div>
      </div>
    </el-drawer>

    <el-drawer
      :title="`我的答案 ${userAnswers.length}`"
      :visible.sync="showAnswerDrawer"
      direction="rtl"
      size="30%"
      :before-close="handleAnswerDrawerClose">
      <div class="comment-container">
        <div class="comment-list">
          <div v-for="(answer, index) in userAnswers" :key="index" class="comment-item">
            <div class="note-header">
              <span class="time">{{ formatTime(answer.createTime) }}</span>
              <el-button type="text" class="delete-btn" @click="deleteUserAnswer(answer.answerId)">
                <i class="el-icon-delete"></i>
              </el-button>
            </div>
            <div class="note-content" v-html="answer.content"></div>
          </div>
          <div v-if="userAnswers.length === 0" class="no-comment">
            暂无答案记录~
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { getQuestionList, getMaterialList, getAnswerList, getUserAnswerList, getUserAddAnswerList, getPaperList, getUserCommentList, getUserNoteList, getTargetsActionStatus, insertUserAnswer, insertUserComment, insertUserNote, doAction, deleteUserComment, deleteUserNote, deleteUserAnswer } from '@/api';
import discussCategory from '@/components/business/discuss/discussCategory.vue';
import { highLight } from '@/utils/filtrate';
import defaultAvatar from '@/assets/images/default-user.png';
import _ from 'lodash';

export default {
  name: 'ChineseLearningApp',
  components: {
    quillEditor,
    'discuss-category': discussCategory
  },
  data() {
    return {
      paperTitle: '',
      currentQuestionIndex: 1,
      currentMaterialIndex: 1,
      currentAnswerIndex: 0,
      questions: [],
      materials: [],
      answers: [],
      userAddAnswers: [],
      userAnswer: '',
      userAnswerContentLength: 0,
      loading: {
        submitComment: false,
        submitNote: false,
        submitAnswer: false,
        deleteComment: false,
        deleteNote: false,
        deleteAnswer: false,
        initial: false
      },
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'color': [] }],
            [{ 'align': [] }],
            ['clean']
          ]
        },
        placeholder: '请输入您的答案'
      },
      answerTabs: [
        { icon: require('@/assets/images/fenbi.png'), source: '粉笔' },
        { icon: require('@/assets/images/huatu.png'), source: '华图' },
        { icon: require('@/assets/images/zhanzhang.png'), source: '站长' },
        { icon: require('@/assets/images/zhonggong.png'), source: '中公' },
        { label: '网友补充', source: '网友补充', showCount: true }
      ],
      addActionSupportList: [],
      addActionAgainstList: [],
      answerActionSupportList: [],
      answerActionAgainstList: [],
      paperActionTarget: null,
      isCollected: false,
      pageParams: {
        pageNum: 1,
        pageSize: 1000,
        paperId: '',
        questionId: '',
      },
      isCompareMode: false,
      selectedPaper: [],
      comparePaper: null,
      regionPaperOptions: [],
      showCommentDrawer: false,
      commentContent: '',
      commentList: [],
      commentParams: {
        pageNum: 1,
        pageSize: 1000,
        targetId: '',
        targetType: 2
      },
      defaultAvatar: defaultAvatar,
      showNoteDrawer: false,
      noteContent: '',
      noteList: [],
      isImmersive: false,
      userAnswerCount: 0,
      userAnswers: [],
      showAnswerDrawer: false,
      highlightedContent: null,
      adState: true,
      answerShow: true,
      userAddAnswerShow: true
    }
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex - 1];
    },
    currentMaterial() {
      return this.materials[this.currentMaterialIndex - 1];
    },
    currentAnswer() {
      if (!this.answers || this.answers.length === 0) {
        return { content: '暂无答案数据' };
      }
      const currentTab = this.answerTabs[this.currentAnswerIndex];
      this.answerShow = true;
      this.userAddAnswerShow = true;
      if(currentTab.source === "中公" && this.adState) {
					this.answerShow = false;
			}
      if(currentTab.source === "网友补充" && this.adState) {
					this.userAddAnswerShow = false;
			}
      const matchedAnswer = this.answers.find(answer => answer.source === currentTab.source);
      if (matchedAnswer) {
        return { 
          content: this.highlightedContent || matchedAnswer.content || '暂无答案数据', 
          source: currentTab.source,
          answerId: matchedAnswer.answerId 
        };
      } else {
        return { content: '暂无答案数据', source: currentTab.source };
      }
    },
    areaList() {
      return this.$store.getters["category/getState"].areaCategoryList;
    },
    currentCompareAnswer() {
      if (!this.comparePaper || !this.comparePaper.answers) {
        return { content: '暂无答案数据' };
      }
      const currentTab = this.comparePaper.answerTabs[this.comparePaper.currentAnswerIndex];
      if (currentTab.source === '网友补充') {
        return { content: '', source: '网友补充' };
      }
      const matchedAnswer = this.comparePaper.answers.find(answer => answer.source === currentTab.source);
      return matchedAnswer || { content: '暂无答案数据', source: currentTab.source };
    },
    compareUserAddAnswers() {
      if (!this.comparePaper || !this.comparePaper.userAddAnswers) return [];
      return this.comparePaper.userAddAnswers;
    },
    currentCompareQuestion() {
      if (!this.comparePaper || !this.comparePaper.questions) {
        return null;
      }
      return this.comparePaper.questions[this.comparePaper.currentQuestionIndex - 1];
    },
    canSubmitAnswer() {
      return this.userAnswer.trim().length > 0 && !this.loading.submitAnswer;
    },
    canSubmitComment() {
      return this.commentContent.trim().length > 0 && !this.loading.submitComment;
    },
    canSubmitNote() {
      return this.noteContent.trim().length > 0 && !this.loading.submitNote;
    },
    // 机构答案的点赞状态和数量
    currentAnswerSupport() {
      const support = this.answerActionSupportList.find(item => item.targetId === this.currentAnswer?.answerId);
      return {
        isActive: support?.status === '1',
        count: support?.count || 0
      };
    },

    // 机构答案的点踩状态和数量
    currentAnswerAgainst() {
      const against = this.answerActionAgainstList.find(item => item.targetId === this.currentAnswer?.answerId);
      return {
        isActive: against?.status === '1',
        count: against?.count || 0
      };
    }
  },
  created() {
    this.paperId = this.$route.params.paperId;
    this.currentQuestionIndex = this.$route.params.questionIndex ? this.$route.params.questionIndex : 1;
    if (this.paperId) {
      this.loadInitialData();
    }
  },
  mounted() {
    // 添加全局复制事件监听
    // document.addEventListener('keydown', this.preventCopyShortcuts);
    this.adStatus();
  },
  beforeDestroy() {
    // 移除全局复制事件监听
    // document.removeEventListener('keydown', this.preventCopyShortcuts);
  },
  methods: {
    // 广告权限检查
    adStatus() {
      const versionInfo = this.$store.getters["user/getState"].versionInfo || {};
      const versionFunctions = versionInfo.versionFunctions || [];
      const versionExpireTimeStr = versionInfo.versionExpireTime || '';
      const currentDate = new Date();
      const versionExpireDate = new Date(versionExpireTimeStr);
      if (versionFunctions.indexOf("no_ad") >= 0 && (currentDate.getTime() <= versionExpireDate.getTime())) {
        this.adState = false;
      } else {
        this.adState = true;
      }
    },
    async loadInitialData() {
      this.loading = {
        ...this.loading,
        initial: true
      };
      try {
        await Promise.all([
          this.getPaperList(this.paperId),
          this.getQuestionList(this.paperId),
          this.getMaterialList(this.paperId)
        ]);
      } catch (error) {
        console.error(error);
        this.$message.error('数据加载失败');
      } finally {
        this.loading = {
          ...this.loading,
          initial: false
        };
      }
    },
    setCurrentQuestion(question) {
      this.currentQuestionIndex = question.questionIndex;
      this.currentAnswerIndex = 0;
      this.getAnswerList(question.questionId);
      this.getUserAddAnswerList(question.questionId);
    },
    setCurrentMaterial(material) {
      this.currentMaterialIndex = material.materialIndex;
    },
    async setCurrentAnswer(index) {
      this.currentAnswerIndex = index;
      this.highlightedContent = null;
      const currentTab = this.answerTabs[index];
      if (currentTab.source === '网友补充') {
        if (this.userAddAnswers && this.userAddAnswers.length > 0) {
          const answerIds = this.userAddAnswers.map(answer => answer.addId).join(',');
          if (answerIds) {
            await this.getAddAnswerActionCounts(answerIds);
          }
        }
      } else {
        const answer = this.answers.find(answer => answer.source === currentTab.source);
        if (answer?.answerId) {
          await this.getInstitutionAnswerActionCounts(answer.answerId);
        }
      }
    },

    onEditorChange: _.debounce(function({ quill, html, text }) {
      this.userAnswer = html;
      this.userAnswerContentLength = text.length;
    }, 300),
    reviewAnswers() {
      this.showAnswerDrawer = true;
    },
    
    compareOverlap() {
      try {
        if(this.currentAnswer.source === '网友补充') {
          this.$message.warning('网友补充答案无法对比，正在开发中......');
          return;
        }
        // 获取原始内容（未高亮的内容）
        const originalContent = this.answers.find(
          answer => answer.source === this.answerTabs[this.currentAnswerIndex].source
        )?.content || '';

        // 创建临时 div 来解析 HTML 内容
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = this.userAnswer;
        const plainUserAnswer = tempDiv.textContent || tempDiv.innerText || '';
        
        tempDiv.innerHTML = originalContent;
        const plainOriginalContent = tempDiv.textContent || tempDiv.innerText || '';

        // 使用纯文本内容进行对比
        let result = highLight(plainOriginalContent, plainUserAnswer, 5);
        if (result.length == 0) {
          this.$message.warning('无五字以上重复内容');
          this.highlightedContent = null;
          return;
        }

        // 在原始 HTML 内容中进行高亮
        let newContent = originalContent;
        for (let key in result) {
          try {
            const text = result[key];
            // 使用正则表达式匹配纯文本内容，同时保留HTML标签
            const regex = new RegExp(
              text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
              'g'
            );
            newContent = newContent.replace(
              regex,
              `<span style="color:#FF9C07;">${text}</span>`
            );
          } catch (err) {
            console.error('处理文本时出错:', err);
            continue;
          }
        }

        if (newContent.length > 0 && newContent.length < 1000000) {
          this.highlightedContent = newContent;
        } else {
          this.$message.error('文本过长，无法处理');
          this.highlightedContent = null;
        }
      } catch (error) {
        console.error('对比重合度时出错:', error);
        this.$message.error('对比重合度失败，请重试');
        this.highlightedContent = null;
      }
    },

    submit(submitType) {
      if (submitType === 'submit-user-answer' && !this.canSubmitAnswer) {
        console.log('不能提交');
        return;
      }

      if (submitType === 'submit-user-answer') {
        this.$confirm('是否上传答案至答案库?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading.submitAnswer = true;
          const data = {
            content: this.userAnswer,
            userId: this.$store.getters['user/getState'].userId,
            questionId: this.currentQuestion.questionId
          };
          
          return insertUserAnswer(data)
            .then(res => {
              this.userAnswer = '';
              this.userAnswerContentLength = 0;
              this.$message.success('提交成功');
              // 刷新用户答案列表和统计
              this.getUserAnswerList(this.currentQuestion.questionId);
            })
            .catch(err => {
              console.error(err);
              this.$message.error('提交失败，请重试');
            })
            .finally(() => {
              this.loading.submitAnswer = false;
            });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    },

    changeActionStatus(targetType, actionType, targetId, status) {
				  if(targetType == 3) {
					if(actionType == 1) {
						let answerActionSupportList = this.answerActionSupportList;
						let targetIndex = this.answerActionSupportList.findIndex(item => item.targetId === targetId);
						answerActionSupportList[targetIndex].status = status;
						answerActionSupportList[targetIndex].count = answerActionSupportList[targetIndex].count + (status == "1" ? 1 : -1);
						this.answerActionSupportList = answerActionSupportList;
					  }
					if(actionType == 2) {
						let answerActionAgainstList = this.answerActionAgainstList;
						let targetIndex = this.answerActionAgainstList.findIndex(item => item.targetId === targetId);
						answerActionAgainstList[targetIndex].status = status;
						answerActionAgainstList[targetIndex].count = answerActionAgainstList[targetIndex].count + (status == "1" ? 1 : -1);
						this.answerActionAgainstList = answerActionAgainstList;
					}
				}
				if(targetType == 4) {
					if (actionType == 1) {
						let addActionSupportList = this.addActionSupportList;
						let targetIndex = addActionSupportList.findIndex(item => item.targetId == targetId);
						addActionSupportList[targetIndex].status = status;
						addActionSupportList[targetIndex].count = addActionSupportList[targetIndex].count + (status ==
							"1" ? 1 : -1);
						this.addActionSupportList = addActionSupportList;
					}
					if (actionType == 2) {
						let addActionAgainstList = this.addActionAgainstList;
						let targetIndex = addActionAgainstList.findIndex(item => item.targetId == targetId);
						addActionAgainstList[targetIndex].status = status;
						addActionAgainstList[targetIndex].count = addActionAgainstList[targetIndex].count + (status ==
							"1" ? 1 : -1);
						this.addActionAgainstList = addActionAgainstList;
					}
				}
		},

    async action(e) {
      let data = {
        status: e[0],
        actionType: e[1],
        targetType: e[2],
        targetId: e[3]
      };
      try {
        await doAction(data);
        if (data.targetType == 1) {
          let paperActionTarget = this.paperActionTarget;
          paperActionTarget.status = data.status;
          this.paperActionTarget = paperActionTarget;
        } else if(data.targetType == 3 || data.targetType == 4) {
          if (data.status == '1') {
            let data1 = JSON.parse(JSON.stringify(data));
            data1.status = '0';
            let doAc = false;
            if (data1.actionType == 1) {
              // 移除踩
              data1.actionType = 2;
              // 参考答案
              if(data.targetType == 3) {
                let target = this.answerActionAgainstList.find(item => item.targetId === data.targetId);
                if(target && target.status == '1') {
                  doAc = true;
                }
              }
              // 补充答案
              if(data.targetType == 4) {
                let target = this.addActionAgainstList.find(item => item.targetId === data.targetId);
                if(target && target.status == '1') {
                  doAc = true;
                }
              }
            } else if (data1.actionType == 2) {
              // 移除赞
              data1.actionType = 1;
              // 参考答案
              if(data.targetType == 3) {
                let target = this.answerActionSupportList.find(item => item.targetId === data.targetId);
                if(target && target.status == '1') {
                  doAc = true;
                }
              }
              // 补充答案
              if(data.targetType == 4) {
                let target = this.addActionSupportList.find(item => item.targetId === data.targetId);
                if(target && target.status == '1') {
                  doAc = true;
                }
              }
            }
            if (doAc) {
              await doAction(data1);
            }
          }
          
          // 更新点赞点踩数量
          if (data.targetType === 3) {
            await this.getInstitutionAnswerActionCounts(data.targetId);
          } else if (data.targetType === 4) {
            const answerIds = this.userAddAnswers.map(answer => answer.addId).join(',');
            if (answerIds) {
              await this.getAddAnswerActionCounts(answerIds);
            }
          }
        }
      } catch (error) {
        console.error('操作失败:', error);
        this.$message.error('操作失败，请重试');
      }
    },

    async getPaperList(paperId) {
      this.loading = true;
      try {
        let params = {
          paperId: paperId,
          pageSize: 1000,
          pageNum: 1
        };
        const response = await getPaperList(params);
        if (response.rows.length > 0) {
          let paper = response.rows[0];
          this.paperTitle = paper.title;
          params = {};
          params.targetType = 1;
          params.actionType = 3;
          params.targetIds = paperId;
          const actionResponse = await getTargetsActionStatus(params);
          this.paperActionTarget = actionResponse.data.find(item => item.targetId == this.paperId);
        }
      } catch (error) {
        this.$message.error(error);
      }
    },

    async getQuestionList(paperId) {
      const params = {
        paperId: paperId,
        pageSize: 1000,
        pageNum: 1
      };
      
      const response = await getQuestionList(params);
      this.questions = response.rows || [];
      if (this.questions.length > 0) {
        await Promise.all([
          this.getAnswerList(this.questions[0].questionId),
          this.getUserAddAnswerList(this.questions[0].questionId)
        ]);
      }
    },

    async getAnswerList(questionId) {
      const params = {
        questionId: questionId,
        pageSize: 1000,
        pageNum: 1
      };
      try {
        const response = await getAnswerList(params);
        this.answers = response.rows || [];
        // 默认显示第一个有内容的答案
        const firstAnswerIndex = this.answerTabs.findIndex(tab =>
          this.answers.some(answer => answer.source === tab.source)
        );
        if (firstAnswerIndex !== -1) {
          this.currentAnswerIndex = firstAnswerIndex;
          // 获取当前答案的点赞点踩数量
          const currentAnswer = this.answers.find(answer => 
            answer.source === this.answerTabs[firstAnswerIndex].source
          );
          if (currentAnswer?.answerId) {
            await this.getInstitutionAnswerActionCounts(currentAnswer.answerId);
          }
        }
      } catch (error) {
        console.error('获取答案列表失败:', error);
        this.$message.error('获取答案列表失败');
      }
    },

    async getUserAddAnswerList(questionId) {
      const params = {
        questionId: questionId,
        pageSize: 1000,
        pageNum: 1,
        status: 1
      };
      try {
        const response = await getUserAddAnswerList(params);
        this.userAddAnswers = response.rows || [];
        if (this.userAddAnswers.length > 0) {
          const answerIds = this.userAddAnswers.map(answer => answer.addId).join(',');
          if (answerIds) {
            await this.getAddAnswerActionCounts(answerIds);
          }
        }
      } catch (error) {
        console.error('获取用户补充答案列表失败:', error);
        this.$message.error('获取用户补充答案列表失败');
      }
    },

    async getMaterialList(paperId) {
      const params = {
        paperId: paperId,
        pageSize: 1000,
        pageNum: 1
      };
      const response = await getMaterialList(params);
      this.materials = response.rows || [];
    },

    toggleCompareMode() {
      this.isCompareMode = !this.isCompareMode;
      if (!this.isCompareMode) {
        this.selectedPaper = [];
        this.comparePaper = null;
      }
    },
    handlePaperChange(value) {
      if (value && value.length === 2) {
        const region = this.regionPaperOptions.find(item => item.value === value[0]);

        Promise.all([
          getQuestionList({
            paperId: value[1],
            pageSize: 1000,
            pageNum: 1
          })
        ]).then(([questionsResponse]) => {
          this.comparePaper = {
            region: region.label,
            paper: value[1],
            value: value[1],
            questions: questionsResponse.rows || [],
            answers: [],
            userAddAnswers: [],
            currentQuestionIndex: 1,
            currentAnswerIndex: 0,
            answerTabs: [
              { icon: require('@/assets/images/fenbi.png'), source: '粉笔' },
              { icon: require('@/assets/images/huatu.png'), source: '华图' },
              { icon: require('@/assets/images/zhanzhang.png'), source: '站长' },
              { icon: require('@/assets/images/zhonggong.png'), source: '中公' },
              { label: '网友补充', source: '网友补充' }
            ]
          };

          if (questionsResponse.rows && questionsResponse.rows.length > 0) {
            const firstQuestion = questionsResponse.rows[0];
            getAnswerList({
              questionId: firstQuestion.questionId,
              pageSize: 1000,
              pageNum: 1
            }).then(response => {
              this.comparePaper.answers = response.rows || [];
            });

            getUserAddAnswerList({
              questionId: firstQuestion.questionId,
              pageSize: 1000,
              pageNum: 1,
              status: 1
            }).then(response => {
              this.comparePaper.userAddAnswers = response.rows || [];
            });
          }
        }).catch(error => {
          this.$message.error(error);
        });
      }
    },
    setCompareQuestionIndex(index) {
      if (this.comparePaper) {
        this.comparePaper.currentQuestionIndex = index;
        const currentQuestion = this.comparePaper.questions[index - 1];
        if (currentQuestion) {
          getAnswerList({
            questionId: currentQuestion.questionId,
            pageSize: 1000,
            pageNum: 1
          }).then(response => {
            this.comparePaper.answers = response.rows || [];
          }).catch(error => {
            this.$message.error(error);
            this.comparePaper.answers = [];
          });

          getUserAddAnswerList({
            questionId: currentQuestion.questionId,
            pageSize: 1000,
            pageNum: 1,
            status: 1
          }).then(response => {
            this.comparePaper.userAddAnswers = response.rows || [];
          }).catch(error => {
            this.$message.error(error);
            this.comparePaper.userAddAnswers = [];
          });
        }
      }
    },
    setCompareAnswerIndex(index) {
      if (this.comparePaper) {
        this.comparePaper.currentAnswerIndex = index;
      }
    },
    initRegionOptions() {
      this.regionPaperOptions = this.areaList.map(area => ({
        label: area.content,
        value: area.areaId,
        leaf: false
      }));
    },
    loadPaperOptions(node, resolve) {
      if (node.level === 0) {
        resolve(this.regionPaperOptions);
        return;
      }

      if (node.level === 1) {
        getPaperList({
          pageSize: 1000,
          pageNum: 1,
          areaId: node.value
        }).then(response => {
          const papers = (response.rows || []).map(paper => ({
            label: paper.title,
            value: paper.paperId,
            leaf: true
          }));
          resolve(papers);
        }).catch(() => {
          resolve([]);
        });
      }
    },
    handleDrawerClose(done) {
      this.commentContent = '';
      done();
    },
    submitComment() {
      if (!this.canSubmitComment) return;
      
      this.loading.submitComment = true;
      
      const data = {
        userId: this.$store.getters['user/getState'].userId,
        targetType: 2, // 2 代表题目
        targetId: this.currentQuestion.questionId,
        content: this.commentContent
      };
      
      insertUserComment(data)
        .then(() => {
          this.$message.success('评论发表成功！');
          this.commentContent = '';
          // 重新获取评论列表
          this.getUserCommentList(this.currentQuestion.questionId);
        })
        .catch(error => {
          console.error(error);
          this.$message.error('评论发表失败，请重试');
        })
        .finally(() => {
          this.loading.submitComment = false;
        });
    },
    getUserCommentList(questionId) {
      const params = {
        ...this.pageParams,
        targetId: questionId,
        targetType: 2
      };
      getUserCommentList(params).then(response => {
        this.commentList = response.rows || [];
      }).catch(error => {
        this.$message.error(error);
        this.commentList = [];
      });
    },
    formatTime(timestamp) {
      if (!timestamp) return '';
      const date = new Date(timestamp);
      const now = new Date();
      const diff = now - date;
      
      // 小于1分钟
      if (diff < 60000) {
        return '刚刚';
      }
      // 小于1小时
      if (diff < 3600000) {
        return Math.floor(diff / 60000) + '分钟前';
      }
      // 小于24小时
      if (diff < 86400000) {
        return Math.floor(diff / 3600000) + '小时前';
      }
      // 小于30天
      if (diff < 2592000000) {
        return Math.floor(diff / 86400000) + '天前';
      }
      
      // 超过30天显示具体日期
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const minute = date.getMinutes().toString().padStart(2, '0');
      
      if (year === now.getFullYear()) {
        return `${month}-${day} ${hour}:${minute}`;
      }
      return `${year}-${month}-${day} ${hour}:${minute}`;
    },
    handleNoteDrawerClose(done) {
      this.noteContent = '';
      done();
    },
    submitNote() {
      if (!this.canSubmitNote) return;
      
      this.loading.submitNote = true;
      
      const data = {
        userId: this.$store.getters['user/getState'].userId,
        targetType: 2, // 2 代表题目
        targetId: this.currentQuestion.questionId,
        content: this.noteContent
      };
      
      insertUserNote(data)
        .then(() => {
          this.$message.success('笔记保存成功！');
          this.noteContent = '';
          // 重新获取笔记列表
          this.getUserNoteList(this.currentQuestion.questionId);
        })
        .catch(error => {
          console.error(error);
          this.$message.error('笔记保存失败，请重试');
        })
        .finally(() => {
          this.loading.submitNote = false;
        });
    },
    deleteNote(noteId) {
      if (this.loading.deleteNote) return;

      this.$confirm('确定要删除这条笔记吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading.deleteNote = true;
        
        deleteUserNote(noteId)
          .then(() => {
            this.noteList = this.noteList.filter(note => note.noteId !== noteId);
            this.$message.success('笔记删除成功！');
          })
          .catch(error => {
            console.error(error);
            this.$message.error('删除笔记失败，请重试');
          })
          .finally(() => {
            this.loading.deleteNote = false;
          });
      }).catch(() => {});
    },
    toggleImmersive() {
      this.isImmersive = !this.isImmersive;
      this.$message.success(this.isImmersive ? '已开启沉浸模式' : '已退出沉浸模式');
    },
    getUserNoteList(questionId) {
      const params = {
        targetId: questionId,
        targetType: 2,
        pageSize: 1000,
        pageNum: 1,
        userId: this.$store.getters['user/getState'].userId
      };
      
      getUserNoteList(params)
        .then(response => {
          this.noteList = response.rows || [];
        })
        .catch(error => {
          console.error(error);
          this.$message.error('获取笔记列表失败');
          this.noteList = [];
        });
    },
    deleteComment(commentId) {
      if (this.loading.deleteComment) return;

      this.$confirm('确定要删除这条评论吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading.deleteComment = true;
        
        deleteUserComment(commentId)
          .then(() => {
            this.commentList = this.commentList.filter(comment => comment.commentId !== commentId);
            this.$message.success('评论删除成功！');
          })
          .catch(error => {
            console.error(error);
            this.$message.error('删除评论失败，请重试');
          })
          .finally(() => {
            this.loading.deleteComment = false;
          });
      }).catch(() => {});
    },
    getUserAnswerList(questionId) {
      const params = {
        questionId: questionId,
        pageSize: 1000,
        pageNum: 1,
        userId: this.$store.getters['user/getState'].userId
      };
      
      getUserAnswerList(params)
        .then(response => {
          this.userAnswers = response.rows || [];
          this.userAnswerCount = this.userAnswers.length;
        })
        .catch(error => {
          console.error(error);
          this.$message.error('获取答案列表失败');
          this.userAnswers = [];
          this.userAnswerCount = 0;
        });
    },
    handleAnswerDrawerClose(done) {
      done();
    },
    deleteUserAnswer(answerId) {
      if (this.loading.deleteAnswer) return;

      this.$confirm('确定要删除这条答案记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading.deleteAnswer = true;
        
        deleteUserAnswer(answerId)
          .then(() => {
            this.userAnswers = this.userAnswers.filter(answer => answer.answerId !== answerId);
            this.userAnswerCount = this.userAnswers.length;
            this.$message.success('答案删除成功！');
          })
          .catch(error => {
            console.error(error);
            this.$message.error('删除答案失败，请重试');
          })
          .finally(() => {
            this.loading.deleteAnswer = false;
          });
      }).catch(() => {});
    },
    // 获取机构答案的点赞点踩数量
    async getInstitutionAnswerActionCounts(answerId) {
      try {
        // 获取点赞数量
        const supportParams = {
          targetIds: answerId,
          targetType: 3,  // 3表示机构答案
          actionType: 1   // 1表示点赞
        };
        const supportRes = await getTargetsActionStatus(supportParams);
        if (supportRes.code === 200) {
          this.answerActionSupportList = supportRes.data || [];
        }

        // 获取点踩数量
        const againstParams = {
          targetIds: answerId,
          targetType: 3,  // 3表示机构答案
          actionType: 2   // 2表示点踩
        };
        const againstRes = await getTargetsActionStatus(againstParams);
        if (againstRes.code === 200) {
          this.answerActionAgainstList = againstRes.data || [];
        }
      } catch (error) {
        console.error('获取机构答案点赞点踩数量失败:', error);
      }
    },

    // 获取用户补充答案的点赞点踩数量
    async getAddAnswerActionCounts(answerIds) {
      try {
        // 获取点赞数量
        const supportParams = {
          targetIds: answerIds,
          targetType: 4,  // 4表示用户补充答案
          actionType: 1   // 1表示点赞
        };
        const supportRes = await getTargetsActionStatus(supportParams);
        if (supportRes.code === 200) {
          this.addActionSupportList = supportRes.data || [];
        }

        // 获取点踩数量
        const againstParams = {
          targetIds: answerIds,
          targetType: 4,  // 4表示用户补充答案
          actionType: 2   // 2表示点踩
        };
        const againstRes = await getTargetsActionStatus(againstParams);
        if (againstRes.code === 200) {
          this.addActionAgainstList = againstRes.data || [];
        }
      } catch (error) {
        console.error('获取答案列表失败:', error);
        this.$message.error('获取答案列表失败');
      }
    },
    preventCopyShortcuts(event) {
      // 防止 Ctrl+C, Cmd+C
      // if ((event.ctrlKey || event.metaKey) && event.keyCode === 67) {
      //   const selection = window.getSelection();
      //   const selectedText = selection.toString();
        
      //   // 检查选中的文本是否在答案区域内
      //   if (this.isTextInAnswerArea(selection.anchorNode)) {
      //     event.preventDefault();
      //   }
      // }
    },
    isTextInAnswerArea(node) {
      // while (node && node !== document.body) {
      //   if (node.classList && 
      //       (node.classList.contains('no-copy') || 
      //        node.classList.contains('content-area'))) {
      //     return true;
      //   }
      //   node = node.parentNode;
      // }
      // return false;
    },
  },
  watch: {
    showCommentDrawer(newVal) {
      if (newVal) {
        this.getUserCommentList(this.currentQuestion.questionId);
      }
    },
    showNoteDrawer(newVal) {
      if (newVal) {
        this.getUserNoteList(this.currentQuestion.questionId);
      }
    },
    showAnswerDrawer(newVal) {
      if (newVal) {
        this.getUserAnswerList(this.currentQuestion.questionId);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.body-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
  .index-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .action-buttons {
      width: 5%;
      margin-right: 12px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .icon-button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0;
        margin-bottom: 15px;
        background-color: white;
        border: 1px solid #dce6e3b0;
        display: block;
        text-align: center;
        margin-left: 0px !important;
        transition: all 0.3s;

        &:hover {
          background-color: #f2f6fc8a;
          transform: scale(1.1);

          :deep(i) {
            color: #46B4B1;
          }
        }

        :deep(i) {
          font-size: 20px;
          color: #606266;
          transition: color 0.3s;

          &.el-icon-star-on {
            color: #46B4B1;
          }
        }
      }
    }
  }
}

.answer-actions {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;

  .action-button {
    padding: 5px 10px;
    margin: 0 10px;
    background-color: #46B4B1;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #378F8C;
    }
  }
}

.question-section {
  width: 50%;
  padding-right: 20px;
  transition: all 0.3s ease;
  &.full-width {
    width: calc(1200px - 60px);
    padding-right: 0;
  }
  .no-permission {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
    img {
      width: 221px;
    }
	}
}

.materials-section {
  width: 45%;
  padding-left: 20px;
  border-left: 1px solid #e0e0e0;
  min-width: 0;
  min-height: 80vh;
  overflow-y: auto;
}

.question-tabs,
.materials-tabs,
.answer-tabs {
  display: flex;
  margin-bottom: 12px;
  margin-top: 12px;
}

.paper-text {
  font-size: 18px;
  text-align: center;
  color: #333;
}

.materials-tabs-container {
  overflow-x: auto;
  white-space: normal;
}

.materials-tabs-container::-webkit-scrollbar {
  display: none;
}

.materials-tabs .tab-button:nth-child(9n) {
  margin-right: 0;
}

.tab-button {
  margin: 0 15px 0 0;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 5px 15px;
  transition: all 0.3s;
  position: relative;
}

.tab-button:hover {
  color: #46B4B1;
}

.tab-button.active {
  color: #46B4B1;
}

.tab-button.active::after {
  content: '';
  width: 50%;
  height: 8px;
  background: #46B4B1;
  transform: scaleY(.5);
  position: absolute;
  bottom: -4px;
  right: 0;
  align-items: center;
  left: 25%;
}

.answer-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.answer-box {
  margin-top: 12px;
  position: relative;

  .answer-info {
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 10px;
    font-size: 12px;
    color: #666;

    .review-link {
      text-decoration: underline;
      color: #46B4B1;
      cursor: pointer;
    }

    &:hover {
      color: #378F8C;
    }
  }
}

.ql-editor {
  width: 100%; /* 占满父级宽度 */
  box-sizing: border-box; /* 确保 padding 和 border 不会超出父级宽度 */
}

:deep(.quill-editor) {
  font-size: 14px;
  .ql-container{
    min-height: 150px;
    border: none;
    background-color: #f9f9f9;
    border-radius: 10px;
  }
  .ql-toolbar.ql-snow {
    border: none !important;
  }
}

.content-area {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #333;
  overflow-x: auto;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1;
    pointer-events: none;
  }

  .header .left {
    color: #46B4B1;
  }
}

.paper-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  position: relative;
}

.compare-section {
  flex: 0 0 45%;
  padding-left: 20px;
  border-left: 1px solid #e0e0e0;
  min-width: 0;
  min-height: 80vh;
  overflow-y: auto;

  .paper-select {
    width: 100%;
    line-height: 25px;
    background-color: #fff;
    border-radius: 5px !important;

    :deep(.el-input__inner) {
      height: 25px !important;
      line-height: 25px !important;
      margin-top: 0 !important;
      background-color: #fff !important;
      font-size: 14px !important;
      border-radius: 5px !important;
    }
  }
}

:deep(.el-tabs__item) {
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
}

:deep(.el-tabs--card > .el-tabs__header .el-tabs__item.is-active) {
  color: #46B4B1;
}

:deep(.el-tabs--card > .el-tabs__header) {
  border-bottom: none;
}

:deep(.el-cascader) {
  width: 100%;
}

.comment-container {
  padding: 20px;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;

  .comment-list {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 20px;

    .no-comment {
      text-align: center;
      color: #999;
      padding: 20px 0;
    }

    .comment-item {
      margin-bottom: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid #eee;

      &:last-child {
        border-bottom: none;
      }

      .comment-header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .username {
          margin-left: 10px;
          font-weight: 500;
          color: #333;
          font-size: 14px;
        }

        .time {
          margin-left: auto;
          font-size: 12px;
          color: #999;
        }
      }

      .comment-content {
        margin-left: 42px;
        color: #666;
        line-height: 1.5;
        font-size: 14px;
        word-break: break-all;
        white-space: pre-wrap;
      }
    }
  }

  .comment-input {
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;

    .el-button {
      margin-top: 10px;
      width: 100%;

      &:hover {
        background-color: #378F8C !important;
        border-color: #378F8C !important;
      }

      &:focus {
        background-color: #46B4B1 !important;
        border-color: #46B4B1 !important;
      }
    }
  }
}

:deep(.el-drawer__header) {
  margin-bottom: 0;
  padding: 8px 20px;
  border-bottom: 1px solid #eee;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  
  span {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    display: flex;
    align-items: center;
  }

  .el-drawer__close-btn {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}

:deep(.el-drawer__body) {
  padding: 0;
  overflow: hidden;
}

.delete-btn {
  color: #999;
  margin-left: 10px;
  padding: 0;

  &:hover {
    color: #f56c6c;
  }

  i {
    font-size: 16px;
  }
}

.note-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-bottom: 4px;
  border-bottom: 1px dashed #eee;

  .time {
    font-size: 12px;
    color: #999;
  }

  .delete-btn {
    color: #999;
    padding: 4px;
    margin-left: 10px;

    &:hover {
      color: #f56c6c;
    }

    i {
      font-size: 16px;
    }
  }
}

.note-content {
  color: #666;
  line-height: 1.6;
  font-size: 14px;
  word-break: break-all;
  white-space: pre-wrap;
  padding: 4px 0;
}

.user-add-answers {
  .user-add-answers-list {
    max-height: 600px;
    overflow-y: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
      border-radius: 3px;
    }
  }

  .user-add-answer-item {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .answer-header {
      margin-bottom: 12px;
      padding-bottom: 8px;
      border-bottom: 1px dashed #e8e8e8;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .answer-info {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .answer-actions {
        display: flex;
        align-items: center;
        gap: 16px;

        .action-item {
          display: flex;
          align-items: center;

          .action-btn {
            padding: 0;
            height: auto;
            display: flex;
            align-items: center;
            gap: 4px;
            color: #86909c;

            &:hover {
              color: #46B4B1;
            }

            &.active {
              color: #46B4B1;
            }

            i {
              font-size: 16px;
            }

            span {
              font-size: 12px;
            }
          }
        }
      }
    }

    .answer-content {
      color: #333;
      line-height: 1.6;
      font-size: 14px;
    }
  }
}

.answer-header {
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px dashed #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .answer-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .answer-actions {
    display: flex;
    align-items: center;
    gap: 16px;

    .action-item {
      display: flex;
      align-items: center;

      .action-btn {
        padding: 0;
        height: auto;
        display: flex;
        align-items: center;
        gap: 4px;
        color: #86909c;

        &:hover {
          color: #46B4B1;
        }

        &.active {
          color: #46B4B1;
        }

        i {
          font-size: 16px;
        }

        span {
          font-size: 12px;
        }
      }
    }
  }
}

.action-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
  vertical-align: middle;
  margin-right: 4px;
}

.action-btn {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  
  &:hover {
    background-color: rgba(70, 180, 177, 0.1);
    border-radius: 4px;
  }
  
  &.active {
    color: #46B4B1;
  }
  
  span {
    margin-left: 4px;
    font-size: 12px;
  }
}

.no-copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
</style>
