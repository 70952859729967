import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts'
import Meta from 'vue-meta'
import wx from 'weixin-js-sdk'
import App from '@/App.vue'
import store from '@/store/index';
import 'lib-flexible'
import router from '@/routes'
import './permission'
import "@/utils/flexible";
import myComponentsInstall from '@/components/myComponentsInstall';
Vue.prototype.$eventBus = new Vue();
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.use(ElementUI);
Vue.use(Meta,{refreshOnceOnNavigation: true});
Vue.use(myComponentsInstall);
Vue.use(router);
Vue.use(wx);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
