<template>
    <div class="body-content">
        <argue-category></argue-category>
        <div class="index-container">
            <div class="index-main shadow">
                <div class="publish-area">
                    <div class="argue-type">
                        <el-radio-group v-model="argueType">
                            <el-radio label="行测">行测</el-radio>
                            <el-radio label="申论">申论</el-radio>
                        </el-radio-group>
                    </div>
                    <publish-comment placeholder="快让囊友给你答疑解惑吧！你可通过输入#标签内容#生成新标签，快来试试吧~"
                        :inputCount="1000"></publish-comment>
                </div>
                <div class="publish-item-wrap shadow" v-for="(item, index) in publishList" :key="item.msg_id">
                    <publish-item :item.sync="publishList[index]" :actors="actors"></publish-item>
                </div>
            </div>
        </div>
        <div class="right-section">
            <about-author class="about-author" :info="articDetail"></about-author>
            <tool-box class="tool-box"></tool-box>
        </div>
    </div>
</template>
<script>
import argueCategory from '@/components/business/argue/argueCategory.vue';
import aboutAuthor from '@/components/business/detail/aboutAuthor.vue';
import toolBox from '@/components/business/discuss/toolBox.vue';
export default {
    name: "CommunityHomePage",
    data() {
        return {
            argueType: '行测',
            articDetail: {
                userInfo: {
                    userId: 1
                },
                userInfo: {
                    avatar_large: '',
                    userName: "测试",
                    level: 10,
                    job_title: '后端',
                    company: '微亮'
                },
                articleInfo: {
                    descContent: '1. 敢于超前研发。树立首创精神,敢为人先,形成差异化竞争力,抵御风险,赢得优势。 2. 加强技术攻关。成立专门团队,进行大量试验,不懈探索分析,不断尝试改进。 3. 市场需求导',
                    createTime: '2023-10-26 17:23',
                    view_count: 100,
                    coverImage: '',
                    title: "这是测试标题",

                },
                user_interact: {
                    is_follow: false
                },
                tags: [{
                    tagId: 1,
                    tagName: 'ce'
                },
                {
                    tagId: 2,
                    tagName: 'ae'
                },
                {
                    tagId: 3,
                    tagName: 'se'
                }]
            },
            publishList: [
                {
                    title: '这是标题',
                    content: '这是内容',
                    screenshot: '@/assets/images/logo.png',
                    userInfo: {
                        userId: 1,
                        job_title: '测试工程师',
                        company: '微软'
                    },
                    msg_Info: {
                        createTime: '2小时前',
                        content: '这只是浅浅发布一下内容',
                        pic_list: ['@/assets/images/logo.png',],
                        like_count: 100,
                        commentCount: 100,
                        url_title: '百度官网',
                        url: 'www.baidu.com',
                        url_pic: '@/assets/images/logo.png',
                    },
                    topic: {
                        topic_id: 1,
                        title: '自定义话题'
                    },

                    user_interact: {
                        is_follow: true,
                        is_like: false,
                    }
                },
                {
                    title: '这是标题',
                    content: '这是内容',
                    screenshot: '@/assets/images/logo.png',
                    userInfo: {
                        userId: 1,
                        job_title: '测试工程师',
                        company: '微软'
                    },
                    msg_Info: {
                        createTime: '2小时前',
                        content: '这只是浅浅发布一下内容',
                        pic_list: ['@/assets/images/logo.png', '@/assets/images/logo.png'],
                        like_count: 100,
                        commentCount: 100,
                        url_title: '百度官网',
                        url: 'www.baidu.com',
                        url_pic: '@/assets/images/logo.png',
                    },
                    topic: {
                        topic_id: 1,
                        title: '自定义话题'
                    },

                    user_interact: {
                        is_follow: true,
                        is_like: false,
                    }
                },
                {
                    title: '这是标题',
                    content: '这是内容',
                    screenshot: '@/assets/images/logo.png',
                    userInfo: {
                        userId: 1,
                        job_title: '测试工程师',
                        company: '微软'
                    },
                    msg_Info: {
                        createTime: '2小时前',
                        content: '这只是浅浅发布一下内容',
                        pic_list: ['@/assets/images/logo.png', '@/assets/images/logo.png', '@/assets/images/logo.png'],
                        like_count: 100,
                        commentCount: 100,
                        url_title: '百度官网',
                        url: 'www.baidu.com',
                        url_pic: '@/assets/images/logo.png',
                    },
                    topic: {
                        topic_id: 1,
                        title: '自定义话题'
                    },

                    user_interact: {
                        is_follow: true,
                        is_like: false,
                    }
                }
            ],
        }
    },
    components: {
        'argue-category': argueCategory,
        'about-author': aboutAuthor,
        'tool-box': toolBox,
    },
    methods: {

        changeNavType(item) {
            if (item.id && this.navId !== item.id) {
                this.navId = item.id
            }
        }
    }
}

</script>
<style lang="scss" scoped>
.body-content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .index-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .index-container {
        display: flex;
        position: relative;
        left: 148px;

        .index-main {
            width: 720px;
            min-height: 620px;
            margin-right: 20px;

            .publish-area {
                padding: 16px;
                background-color: #FFF;
                border-radius: 10px;
                .argue-type {
                    margin-bottom: 16px;

                    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
                        color: $color-primary;
                    }

                    /deep/ .el-checkbox__inner {
                        margin: 4px 0;
                        border-color: $color-primary;
                    }

                    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
                    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                        border-color: $color-primary !important;
                        background-color: $color-primary !important;
                    }
                }
            }
        }

        margin-left: 12px;
    }

    .right-section {
        width: 300px;

        .tool-box {
            margin-top: 16px;
        }
    }
}</style>