<template>
  <div class="statistics-container">
    <!-- 概览容器 -->
    <div class="overview-container">
      <div class="overview-item">
        <div class="title">今日专注时长</div>
        <div class="value">{{ formatDuration(todayTotalDuration) }}</div>
      </div>
      <div class="overview-item">
        <div class="title">总专注时长</div>
        <div class="value">{{ formatDuration(allTimeTotalDuration) }}</div>
      </div>
    </div>

    <!-- 详情容器 -->
    <div class="detail-row">
      <!-- 专注详情容器 -->
      <div class="detail-container">
        <div class="title">今日专注详情</div>
        <div id="pieChart" style="width: 100%; height: 300px;"></div>
      </div>

      <!-- 新增容器 -->
      <div class="detail-container">
        <div class="title">专注分析</div>
        <div id="analysisChart" style="width: 100%; height: 400px;"></div>
      </div>
    </div>

    <!-- 专注趋势容器 -->
    <div class="trend-container">
      <div class="title">专注趋势</div>
      <div id="lineChart" style="width: 100%; height: 300px;"></div>
    </div>

    <!-- 新增卡片 -->
    <div class="trend-container">
      <div class="title">年度热力图</div>
      <div id="rankChart" style="width: 100%; height: 300px;"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
require('echarts/theme/dark');

export default {
  name: 'StatisticsView',
  data() {
    return {
      pieChart: null,
      lineChart: null,
      todayTotalDuration: 0,
      allTimeTotalDuration: 0,
      historyRecords: [],
      historyWatcher: null,
      rankChart: null
    }
  },
  mounted() {
    setTimeout(() => {
      this.initCharts();
      this.loadData();
      window.addEventListener('resize', this.handleResize);
      this.setupStorageListener();
    }, 100);
  },
  methods: {
    setupStorageListener() {
      window.addEventListener('storage', (e) => {
        if (e.key === 'countdownHistory') {
          this.loadData();
        }
      });
      this.historyWatcher = setInterval(() => {
        const currentData = localStorage.getItem('countdownHistory');
        if (currentData) {
          const parsedData = JSON.parse(currentData);
          if (JSON.stringify(this.historyRecords) !== JSON.stringify(parsedData.records)) {
            this.loadData();
          }
        }
      }, 1000);
    },
    loadData() {
      const savedData = localStorage.getItem('countdownHistory');
      if (savedData) {
        const { records } = JSON.parse(savedData);
        this.historyRecords = records;
        this.calculateStatistics();
        if (this.pieChart && this.lineChart) {
          this.updateCharts();
        }
      }
    },
    calculateStatistics() {
      const today = new Date().toDateString();
      const todayStats = {};
      this.todayTotalDuration = 0;
      this.allTimeTotalDuration = 0;

      this.historyRecords.forEach(record => {
        const recordDate = new Date(record.endTime).toDateString();
        this.allTimeTotalDuration += record.duration;

        if (recordDate === today) {
          this.todayTotalDuration += record.duration;
          const type = record.name;
          todayStats[type] = (todayStats[type] || 0) + record.duration;
        }
      });

      this.updatePieChart(todayStats);
      this.updateLineChart();
    },
    updateCharts() {
      const today = new Date().toDateString();
      const todayStats = {};

      this.historyRecords.forEach(record => {
        const recordDate = new Date(record.endTime).toDateString();
        if (recordDate === today) {
          if (record.segments && record.segments.length > 0) {
            record.segments.forEach(segment => {
              const segmentName = segment.name;
              todayStats[segmentName] = (todayStats[segmentName] || 0) + segment.duration;
            });
          }
        }
      });

      this.updatePieChart(todayStats);
      this.updateLineChart();
    },
    initCharts() {
      const pieChartDom = document.getElementById('pieChart');
      const analysisChartDom = document.getElementById('analysisChart');
      const lineChartDom = document.getElementById('lineChart');
      const rankChartDom = document.getElementById('rankChart');

      if (pieChartDom && analysisChartDom && lineChartDom && rankChartDom) {
        this.pieChart = echarts.init(pieChartDom);
        this.analysisChart = echarts.init(analysisChartDom);
        this.lineChart = echarts.init(lineChartDom);
        this.rankChart = echarts.init(rankChartDom);

        this.updatePieChart({});
        this.updateAnalysisChart();
        this.updateLineChart();
        this.updateRankChart();
      }
    },
    updatePieChart(data) {
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}分钟 ({d}%)'
        },
        series: [{
          name: '专注详情',
          type: 'pie',
          radius: ['30%', '50%'],
          center: ['50%', '40%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 4
          },
          label: {
            show: true,
            formatter: '{b}: {c}分钟',
            color: '#888',
            fontSize: 12
          },
          labelLine: {
            length: 10,
            length2: 10
          },
          data: Object.entries(data).map(([name, value]) => ({
            name,
            value: Math.floor(value / 60)
          }))
        }]
      };
      this.pieChart.setOption(option);
    },
    updateLineChart() {
      const days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
      const data = new Array(7).fill(0);
      const today = new Date();

      this.historyRecords.forEach(record => {
        const recordDate = new Date(record.endTime);
        const dayDiff = Math.floor((today - recordDate) / (1000 * 60 * 60 * 24));
        if (dayDiff < 7) {
          data[6 - dayDiff] += Math.floor(record.duration / 60);
        }
      });

      const option = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: '11%',
          right: '5%',
          bottom: '10%',
          left: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: days,
          axisLabel: { color: '#888' }
        },
        yAxis: {
          type: 'value',
          name: '专注时长(分钟)',
          axisLabel: { color: '#888' }
        },
        series: [{
          data: data,
          type: 'line',
          smooth: true,
          lineStyle: {
            color: '#42b883'
          },
          itemStyle: {
            color: '#42b883'
          }
        }]
      };
      this.lineChart.setOption(option);
    },
    updateAnalysisChart() {
      const cellSize = [50, 40];
      const pieRadius = 15;
      const data = this.getCalendarData();

      const pieSeries = data.map(item => {
        return {
          type: 'pie',
          id: 'pie-' + item[0],
          center: item[0],
          radius: pieRadius,
          coordinateSystem: 'calendar',
          label: {
            show: false
          },
          data: [
            {
              name: '专注',
              value: item[1],
              itemStyle: {
                color: '#42b883'
              }
            },
            {
              name: '其他',
              value: 24 * 60 - item[1],
              itemStyle: {
                color: '#eee'
              }
            }
          ]
        };
      });

      const option = {
        tooltip: {
          formatter: function (params) {
            if (params.componentSubType === 'pie') {
              return `${params.name}: ${params.value}分钟`;
            }
            return params.value[0];
          }
          // },
          // legend: {
          //     data: ['专注', '其他'],
          //     bottom: 10,
          //     textStyle: {
          //         color: '#888'
          //     }
        },
        calendar: {
          top: 'top',
          left: 'center',
          orient: 'vertical',
          cellSize: cellSize,
          yearLabel: {
            show: false
          },
          dayLabel: {
            margin: 8,
            firstDay: 1,
            nameMap: ['日', '一', '二', '三', '四', '五', '六'],
            color: '#fff',
            fontSize: 8
          },
          monthLabel: {
            show: false,
          },
          range: this.getCalendarRange(),
          itemStyle: {
            color: '#fff',
            borderColor: '#ddd',
            borderWidth: 1
          }
        },
        series: [
          {
            id: 'label',
            type: 'scatter',
            coordinateSystem: 'calendar',
            symbolSize: 0,
            label: {
              show: true,
              formatter: function (params) {
                return echarts.time.format(params.value[0], '{dd}', false);
              },
              offset: [-cellSize[0] / 2 + 6, -cellSize[1] / 2 + 6],
              fontSize: 9,
              color: '#666'
            },
            data: data
          },
          ...pieSeries
        ]
      };

      this.analysisChart.setOption(option);
    },
    getCalendarData() {
      const data = [];
      const end = new Date();
      const start = new Date();
      start.setDate(end.getDate() - 30); // 显示最近30天的数据

      // 遍历日期范围
      for (let time = start; time <= end; time = new Date(time.setDate(time.getDate() + 1))) {
        const dateStr = time.toISOString().split('T')[0];
        const minutes = this.calculateDailyDuration(time);
        data.push([dateStr, minutes]);
      }

      return data;
    },
    getCalendarRange() {
      const end = new Date();
      const start = new Date();
      start.setDate(end.getDate() - 30);
      return [start.toISOString().split('T')[0], end.toISOString().split('T')[0]];
    },
    calculateDailyDuration(date) {
      const dateStr = date.toDateString();
      let totalMinutes = 0;

      this.historyRecords.forEach(record => {
        const recordDate = new Date(record.endTime).toDateString();
        if (recordDate === dateStr) {
          totalMinutes += Math.floor(record.duration / 60);
        }
      });

      return totalMinutes;
    },
    handleResize() {
      this.pieChart?.resize();
      this.lineChart?.resize();
      this.analysisChart?.resize();
      this.rankChart?.resize();
    },
    formatDuration(seconds) {
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = seconds % 60;
      return `${h}时${m}分${s}秒`;
    },
    updateRankChart() {
      const option = {
        tooltip: {
          formatter: function (params) {
            return params.value[0] + ' : ' + params.value[1] + '分钟';
          }
        },
        visualMap: {
          show: false,
          min: 0,
          max: 300, // 根据实际专注时长调整
          inRange: {
            color: ['#ebedf0', '#c6e48b', '#7bc96f', '#239a3b', '#196127']
          }
        },
        calendar: {
          top: 30,
          left: 40,
          right: 40,
          cellSize: ['auto', 15],
          range: this.getYearRange(),
          itemStyle: {
            color: '#eee',
            borderWidth: 2,
            borderColor: '#fff'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#ddd',
              width: 2,
              type: 'solid'
            }
          },
          dayLabel: {
            firstDay: 1,
            nameMap: ['日', '一', '二', '三', '四', '五', '六']
          },
          monthLabel: {
            nameMap: [
              '一月', '二月', '三月', '四月', '五月', '六月',
              '七月', '八月', '九月', '十月', '十一月', '十二月'
            ]
          },
          yearLabel: {
            show: false
          }
        },
        series: {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: this.getHeatmapData()
        }
      };
      this.rankChart.setOption(option);
    },

    getYearRange() {
      const end = new Date();
      const start = new Date();
      start.setFullYear(end.getFullYear() - 1);
      return [start.toISOString().split('T')[0], end.toISOString().split('T')[0]];
    },

    getHeatmapData() {
      const data = [];
      const end = new Date();
      const start = new Date();
      start.setFullYear(end.getFullYear() - 1);

      // 遍历日期范围
      for (let time = start; time <= end; time = new Date(time.setDate(time.getDate() + 1))) {
        const dateStr = time.toISOString().split('T')[0];
        const minutes = this.calculateDailyDuration(time);
        data.push([dateStr, minutes]);
      }

      return data;
    },

    calculateDailyDuration(date) {
      const dateStr = date.toDateString();
      let totalMinutes = 0;

      this.historyRecords.forEach(record => {
        const recordDate = new Date(record.endTime).toDateString();
        if (recordDate === dateStr) {
          totalMinutes += Math.floor(record.duration / 60);
        }
      });

      return totalMinutes;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('storage', this.loadData);
    if (this.historyWatcher) {
      clearInterval(this.historyWatcher);
    }
    if (this.pieChart) {
      this.pieChart.dispose();
    }
    if (this.lineChart) {
      this.lineChart.dispose();
    }
    if (this.rankChart) {
      this.rankChart.dispose();
    }
  }
}
</script>

<style scoped lang="scss">
.statistics-container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.overview-container {
  display: flex;
  gap: 20px;

  .overview-item {
    flex: 1;
    background: #2c2c2c;
    padding: 20px;
    border-radius: 8px;

    .title {
      color: #888;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .value {
      color: #42b883;
      font-size: 24px;
      font-weight: 500;
    }
  }
}

.detail-row {
  display: flex;
  gap: 20px;
  width: 100%;

  .detail-container {
    flex: 1;
    background: #2c2c2c;
    padding: 15px;
    border-radius: 8px;
    height: 300px;

    .title {
      color: #888;
      font-size: 14px;
      margin-bottom: 10px;
    }

    #pieChart {
      width: 100%;
      height: 250px;
    }
  }
}

.detail-container,
.trend-container {
  background: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  height: 360px;

  .title {
    color: #888;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .chart {
    width: 100%;
    height: 400px;
    min-height: 300px;
  }
}

.trend-container {
  background: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  height: 360px;
  margin-bottom: 20px;

  .title {
    color: #888;
    font-size: 16px;
    margin-bottom: 20px;
  }

  #rankChart {
    margin-top: 10px;
    border-radius: 4px;
    background: #fff;
  }
}
</style>