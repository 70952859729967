<template>
  <div class="page-container">
    <!-- 左侧导航栏 -->
    <!-- <div class="nav-sidebar" :class="{ collapsed: isCollapsed }"> -->
      <!-- 收缩按钮 -->
      <!-- <div class="collapse-btn" @click="toggleCollapse">
        <i class="iconfont" :class="isCollapsed ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
      </div> -->

      <!-- <div class="nav-item" v-for="item in navItems" :key="item.id" :class="{ active: currentNav === item.id }"
           @click="handleNavClick(item.id)">
        <i class="iconfont" :class="item.icon"></i>
        <span :class="{ 'nav-text': true, 'hidden': isCollapsed }">{{ item.name }}</span>
      </div> -->
    <!-- </div> -->

    <!-- 主内容区 -->
    <div class="main-content">
      <div class="clock-wrapper">
        <!-- 修改日期和星期显示的布局 -->
        <div class="date-container">
          <div class="date-row" v-if="currentNav === 'flipClock'">
            <div class="date">{{ currentDate }}</div>
            <div class="weekday">{{ currentWeekday }}</div>
          </div>
          <component :is="currentComponent"></component>
          <div class="clock-footer" v-if="currentNav === 'flipClock'">
            <span>「苟日新，日日新，又日新」</span>——《礼记·大学》
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberCard from '@/components/common/NumberCard.vue'
import Countdown from './countdown.vue'
import Statistics from './statistics.vue'

export default {
  name: 'ClockView',
  components: {
    NumberCard,
    Countdown,
    Statistics,
  },
  data() {
    return {
      isCollapsed: false,
      currentNav: 'flipClock',
      navItems: [
        {
          id: 'flipClock',
          name: '北京时间',
          icon: 'el-icon-time',
          component: 'NumberCard'
        },
        // {
        //   id: 'countdown',
        //   name: '专注计时',
        //   icon: 'el-icon-timer',
        //   component: 'Countdown'
        // },
        // {
        //   id: 'focus',
        //   name: '数据统计',
        //   icon: 'el-icon-pie-chart',
        //   component: 'Statistics'
        // }
      ],
      weekdays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
    }
  },
  computed: {
    currentComponent() {
      const current = this.navItems.find(item => item.id === this.currentNav);
      return current?.component || null;
    },
    currentWeekday() {
      return this.weekdays[new Date().getDay()];
    },
    currentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  methods: {
    handleNavClick(id) {
      this.currentNav = id;
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
</script>

<style scoped lang="scss">
.page-container {
  display: flex;
  min-height: 100vh;
  background: #1a1a1a;
}

.nav-sidebar {
  width: 160px;
  background: #2c2c2c;
  padding: 40px 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;

  &.collapsed {
    width: 40px;

    .nav-item {
      padding: 15px;
      justify-content: center;
    }
  }
}

.collapse-btn {
  position: absolute;
  right: -12px;
  top: 10px;
  width: 24px;
  height: 24px;
  background: #42b883;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;

  i {
    color: #fff;
    font-size: 16px;
  }

  &:hover {
    background: #3aa876;
  }
}

.nav-item {
  padding: 15px 20px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;

  i {
    font-size: 20px;
    width: 24px;
    text-align: center;
  }

  .nav-text {
    transition: opacity 0.3s ease;
    white-space: nowrap;

    &.hidden {
      width: 0;
      opacity: 0;
    }
  }

  &:hover {
    background: #3c3c3c;
  }

  &.active {
    background: #4c4c4c;
    border-left: 4px solid #42b883;

    i,
    span {
      color: #42b883;
    }
  }
}

.main-content {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clock-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
}

.date-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .date-row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 15px;
    padding: 0 30px;
  }

  .card-container-wrapper {
    margin: 0 auto;
  }
}

.date {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 10px;
}

.weekday {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 20px;
}

.clock-footer {
  color: #fff;
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}
</style>