<template>
  <div class="body-content">
    <discuss-category @areaChange="handleAreaChange"></discuss-category>
    <div class="index-container">
      <div class="index-main">
        <div v-if="loading">
          <el-skeleton :rows="10" animated>
            <template slot="template">
              <div v-for="i in 10" :key="i" class="paper-item skeleton-item">
                <el-skeleton-item variant="p" style="width: 100%; height: 20px;" />
              </div>
            </template>
          </el-skeleton>
        </div>

        <div v-else>
          <div v-if="questionList.length > 0">
            <div v-for="(item, index) in questionList" :key="index" class="question-item" @click="goToDetail(item)">
              <div class="content-text" v-html="item.content"></div>
            </div>
            <el-pagination
              class="pagination-container"
              :current-page="questionPage.pageNum"
              :page-size="questionPage.pageSize"
              :total="questionTotal"
              layout="prev, pager, next"
              @current-change="handleQuestionPageChange"
            />
          </div>
          <div v-else>
            <div v-for="(item, index) in paperList" :key="index" class="paper-item" @click="goToDetail(item)">
              <div class="content-text">{{ item.title }}</div>
            </div>
            <el-pagination
              class="pagination-container"
              :current-page="paperPage.pageNum"
              :page-size="paperPage.pageSize"
              :total="paperTotal"
              layout="prev, pager, next"
              @current-change="handlePaperPageChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="right-section">
      <tool-box class="tool-box"></tool-box>
    </div>
  </div>
</template>

<script>
import discussCategory from '@/components/business/discuss/discussCategory.vue';
import toolBox from '@/components/business/discuss/toolBox.vue';
import { getPaperList, getQuestionList } from '@/api';
import { highLight } from '@/utils/filtrate';

export default {
  components: {
    'discuss-category': discussCategory,
    'tool-box': toolBox
  },
  data() {
    return {
      paperList: [],
      questionList: [],
      loading: false,
      
      // 试卷分页参数
      paperPage: {
        pageNum: 1,
        pageSize: 10,
        areaId: null
      },
      paperTotal: 0,

      // 问题分页参数
      questionPage: {
        pageNum: 1,
        pageSize: 5,
        areaId: null
      },
      questionTotal: 0,

      keyword: ''
    };
  },
  created() {
    const lastSelectedArea = localStorage.getItem('selectedAreaId');
    if (lastSelectedArea) {
      this.paperPage.areaId = parseInt(lastSelectedArea);
      this.questionPage.areaId = parseInt(lastSelectedArea);
    }
    this.getPaperList();
    // 移除滚动事件监听
  },
  mounted() {
    this.$eventBus.$on('search', this.handleSearch);
  },
  beforeDestroy() {
    this.$eventBus.$off('search');
    // 移除滚动事件相关代码
  },
  methods: {
    handleSearch(data) {
      this.keyword = data.keyword;
      if (this.keyword.trim()) {
        this.questionPage.pageNum = 1; // 重置问题页码
        this.getQuestionList();
      } else {
        this.$message.warning('请输入关键字进行搜索');
      }
    },

    // 移除无限滚动相关方法（handleScroll, checkAndLoadMore）

    handleAreaChange(areaId) {
      this.questionList = [];
      // 重置分页参数
      this.paperPage = {
        pageNum: 1,
        pageSize: 10,
        areaId: areaId
      };
      this.questionPage.areaId = areaId;
      
      if (areaId) {
        localStorage.setItem('selectedAreaId', areaId);
      } else {
        localStorage.removeItem('selectedAreaId');
      }
      this.getPaperList();
    },

    handlePaperPageChange(newPage) {
      this.paperPage.pageNum = newPage;
      this.getPaperList();
    },

    handleQuestionPageChange(newPage) {
      this.questionPage.pageNum = newPage;
      this.getQuestionList();
    },

    async getPaperList() {
      this.loading = true;
      try {
        const response = await getPaperList({
          ...this.paperPage,
          orderByColumn: 'create_time',
          isAsc: 'desc',
        });
        this.paperList = response.rows || [];
        this.paperTotal = response.total || 0;
      } catch (error) {
        console.error('加载试卷列表失败:', error);
        this.$message.error('加载试卷列表失败');
      } finally {
        this.loading = false;
      }
    },

    async getQuestionList() {
      this.loading = true;
      const params = {
        ...this.questionPage,
        content: this.keyword,
      };
      try {
        const response = await getQuestionList(params);
        this.questionTotal = response.total || 0;
        
        if (response.rows.length === 0) {
          this.$message.warning('未搜索到该关键字对应的题目');
        }

        this.questionList = response.rows.map(item => {
          let content = item.content;
          if (this.keyword) {
            this.keyword.split(" ").forEach(keyword => {
              const result = highLight(content, keyword, keyword.length);
              result.forEach(match => {
                content = content.replace(new RegExp(match, "gm"),
                  `<span style="color:#FF9C07;">${match}</span>`);
              });
            });
          }
          return {
            content,
            paperId: item.paperId,
            questionIndex: item.questionIndex
          };
        });
      } catch (error) {
        console.error('加载题目列表失败:', error);
        this.$message.error('加载题目列表失败');
      } finally {
        this.loading = false;
      }
    },

    goToDetail(item) {
      this.$router.push({
        name: 'PaperDetail',
        params: { 
          paperId: item.paperId, 
          questionIndex: item.questionIndex 
        },
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.body-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;

  .index-container {
    display: flex;
    position: relative;
    margin-left: 168px;
    flex: 1;
    min-width: 0;

    .index-main {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      padding: 20px;
      box-sizing: border-box;
      margin-right: 320px;

      .question-item {
        padding: 16px 20px;
        border: 1px solid #f0f0f0;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        background-color: #fff;
        margin-bottom: 20px;
        border-radius: 10px;
        box-sizing: border-box;
        height: 100px;
        &:hover {
          background-color: rgba($color-primary, 0.05);
          color: $color-primary;
        }
      }

      .paper-item {
        padding: 16px 20px;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        background-color: #fff;
        box-sizing: border-box;
        height: 52px;
        &:hover {
          background-color: rgba($color-primary, 0.05);
          color: $color-primary;
        }
      }

      .content-text {
        font-size: 14px;
        // line-height: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; 
        overflow: hidden;
        color: $font-color-primary;
      }

      :deep(.pagination-container) {
        padding-top: 10px;
        text-align: center;
          .el-pager li {
            &:not(.disabled).active {
              background-color: $color-primary; 
              color: #fff;
              padding: 0 8px;
              border-radius: 4px;
            }

            &:hover {
              color: $color-primary;
            }
          }

          .btn-prev,
          .btn-next {
            &:hover {
              color: $color-primary;
            }
          }

          button:disabled {
            background-color: #fff;
          }
        }
    }
  }

  .right-section {
    width: 300px;
    min-height: auto;
    max-height: calc(100vh - 100px);
    position: fixed;
    top: 80px;
    right: calc((100vw - 1200px) / 2 + 20px);
    background-color: $background-color;
    border-radius: 10px;
    transition: all .2s;
    overflow-y: auto;

    /* 隐藏滚动条但保持滚动功能 */
    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.skeleton-item {
  background-color: #fff !important;

}

@media screen and (max-width: 1200px) {
  .body-content {
    padding: 0 10px;

    .right-section {
      display: none;
    }

    .index-container {
      margin-left: 0 !important;

      .index-main {
        margin-right: 0;
        max-width: 100%;
      }
    }

    :deep(.nav-list) {
      display: none;
    }
  }
}
</style>
