<template>
  <div class="body-content">
    <discuss-category @areaChange="handleAreaChange"></discuss-category>
    <div class="index-container">
      <div class="index-main">
        <div class="form-group">
          <label for="examPaper">试卷</label>
          <el-select v-model="examPaper" filterable remote reserve-keyword placeholder="请先选择省份，再输入试卷名称搜索"
            :remote-method="handlePaperSearch" :loading="loading" class="paper-select" @change="handlePaperChange">
            <el-option v-for="item in paperOptions" :key="item.paperId" :label="item.title"
              :value="item.paperId"></el-option>
          </el-select>
        </div>

        <div class="form-group">
          <label for="answerSource">答案来源</label>
          <input id="answerSource" type="text" v-model="answerSource" required placeholder="请输入答案来源机构">
        </div>

        <div class="add-rules">
          <p class="add-desc">1.补充的答案审核通过后，将会显示在页面中，你将获得1天纯净版会员；</p>
          <p class="add-desc">2.已有的4家机构和网友上传答案，不要重复上传；</p>
          <p class="add-desc">3.不要补充自己写的答案，低质量答案会被定期删除；</p>
        </div>

        <div class="question-tabs">
          <button v-for="(question, index) in questions" :key="index" class="tab-button"
            :class="{ active: currentQuestionIndex === index + 1 }" @click="setCurrentQuestion(question)">
            第{{ index + 1 }}题
          </button>
        </div>

        <template v-if="currentQuestion?.content">
          <div class="ql-editor" style="height: auto;padding: 0;">
            <div v-html="currentQuestion?.content" class="content-area"></div>
          </div>

          <div class="answer-box">
            <quill-editor v-model="answer" :options="editorOption" @change="onEditorChange($event)" />
          </div>
          <div class="answer-actions">
            <button @click="confirmSubmitAnswer" class="action-button">提交答案</button>
          </div>
        </template>
        <template v-else>
          <div class="empty-question">
            <i class="el-icon-document icon"></i>
            <div class="text">请先选择试卷和题目</div>
          </div>
        </template>
      </div>
    </div>
    <div class="right-section">
      <tool-box class="tool-box"></tool-box>
    </div>
  </div>
</template>

<script>
import discussCategory from '@/components/business/discuss/discussCategory.vue';
import toolBox from '@/components/business/discuss/toolBox.vue';
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { MessageBox } from 'element-ui';
import { getPaperList, getQuestionList,insertUserAddAnswer } from '@/api';

export default {
  name: "AddAnswerPage",
  components: {
    'discuss-category': discussCategory,
    'tool-box': toolBox,
    quillEditor
  },
  data() {
    return {
      examPaper: '',
      answerSource: '',
      answer: '',
      currentQuestionIndex: 1,
      loading: false,
      paperOptions: [],
      selectedArea: null,
      questions: [],
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'color': [] }],
            [{ 'align': [] }],
            ['clean']
          ]
        },
        placeholder: '请输入您的答案'
      }
    }
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex - 1] || {};
    },
    areaList() {
      return this.$store.getters["category/getState"].areaCategoryList;
    }
  },
  created() {
    if (this.areaList.length === 0) {
      this.$store.dispatch('category/GetAreaList').then(() => {
        // 获取地区列表后，设置默认地区并获取试卷列表
        if (this.areaList.length > 0) {
          this.selectedArea = this.areaList[0].areaId;
          this.handlePaperSearch('');
        }
      });
    } else {
      // 如果已有地区列表，直接设置默认地区并获取试卷列表
      this.selectedArea = this.areaList[0].areaId;
      this.handlePaperSearch('');
    }
  },
  methods: {
    handleAreaChange(areaId) {
      this.selectedArea = areaId;
      this.examPaper = ''; // 清空已选试卷
      this.paperOptions = []; // 清空试卷选项
      this.questions = []; // 清空问题列表
      this.currentQuestionIndex = 1; // 重置当前问题索引
      this.handlePaperSearch(''); // 重新获取试卷列表
    },

    handlePaperSearch(query) {
      if (!this.selectedArea) {
        this.$message.warning('请先选择地区');
        return;
      }
      
      this.loading = true;
      getPaperList({
        pageSize: 20,
        pageNum: 1,
        areaId: this.selectedArea,
        keyword: query
      }).then(response => {
        this.paperOptions = response.rows || [];
        this.loading = false;
      }).catch(error => {
        this.$message.error(error);
        this.loading = false;
        this.paperOptions = [];
      });
    },

    // 监听试卷选择变化
    handlePaperChange(value) {
      if (value) {
        this.getQuestionList(value);
      } else {
        this.questions = [];
        this.currentQuestionIndex = 1;
      }
    },

    // 获取问题列表
    getQuestionList(paperId) {
      const params = {
        paperId: paperId,
        pageSize: 1000,
        pageNum: 1
      };
      getQuestionList(params).then(response => {
        this.questions = response.rows || [];
      }).catch(error => {
        this.$message.error(error);
        this.questions = [];
      });
    },

    setCurrentQuestion(question) {
      if (question && question.questionIndex) {
        this.currentQuestionIndex = question.questionIndex;
        this.answer = ''; // 清空答案
      }
    },

    onEditorChange({ quill, html, text }) {
      this.answer = html;
    },

    confirmSubmitAnswer() {
      if (!this.examPaper) {
        this.$message.warning('请选择试卷');
        return;
      }
      
      MessageBox.confirm('确定要提交答案吗？', '提交确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.submitAnswer();
      }).catch(() => {
        // 用户点击取消，不做任何操作
      });
    },

    async submitAnswer() {
      try {
        if (!this.answer.trim()) {
          this.$message.warning('请输入答案内容');
          return;
        }

        if (!this.answerSource.trim()) {
          this.$message.warning('请输入答案来源');
          return;
        }

        const data = {
          content: this.answer,
          userId: this.$store.getters['user/getState'].userId,
          questionId: this.currentQuestion.questionId,
          source: this.answerSource,
          status: "0" // 0代表未审核 1代表审核通过 2代表审核拒绝
        };

        await insertUserAddAnswer(data);
        this.$message.success('答案提交成功，等待审核');
        this.resetForm();
      } catch (error) {
        console.error('提交答案错误:', error);
        this.$message.error('提交过程中发生错误，请重试');
      }
    },

    resetForm() {
      this.examPaper = '';
      this.answerSource = '';
      this.answer = '';
      this.currentQuestionIndex = 1;
      this.paperOptions = [];
      this.questions = [];
    }
  },
  watch: {
    examPaper(newVal) {
      this.handlePaperChange(newVal);
    }
  }
}
</script>

<style lang="scss" scoped>
.body-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;

  .index-container {
    display: flex;
    position: relative;
    margin-left: 168px;
    flex: 1;
    min-width: 0;

    .index-main {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      padding: 20px;
      box-sizing: border-box;
      margin-right: 320px;

      .empty-question {
        text-align: center;
        padding: 40px 0;
        color: #909399;
        font-size: 14px;

        .icon {
          font-size: 48px;
          margin-bottom: 16px;
          color: #DCDFE6;
        }

        .text {
          color: #909399;
        }
      }
    }
  }
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  label {
    width: 60px;
    margin-right: 5px;
    white-space: nowrap;
  }

  /deep/ .paper-select {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 4px;

    // 添加 placeholder 样式
    .el-input__inner::placeholder {
      color: #999; // 设置占位符颜色
      font-size: 12px; // 设置占位符字体大小
    }

    .el-input__inner {
      background-color: #fff !important;

      &::placeholder {
        color: #999; // 设置占位符颜色
        font-size: 12px; // 设置占位符字体大小
      }
    }

    .el-select-dropdown__item {
      background-color: #fff;

      &:hover {
        background-color: #f5f7fa;
      }
    }
  }

  input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 14px;
    flex: 1;

    &::placeholder {
      color: #999;
      font-size: 12px;
    }
  }
}

.add-rules {
  margin: 16px 0 24px;
  padding: 12px 16px;
  background-color: #f9f9f9;
  border-radius: 8px;

  .add-desc {
    color: #666;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.right-section {
    width: 300px;
    min-height: auto;
    max-height: calc(100vh - 100px);
    position: fixed;
    top: 80px;
    right: calc((100vw - 1200px) / 2 + 20px);
    background-color: $background-color;
    border-radius: 10px;
    transition: all .2s;
    overflow-y: auto;

    /* 隐藏滚动条但保持滚动功能 */
    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

.question-tabs {
  display: flex;
  margin-bottom: 10px;
}

.tab-button {
  padding: 5px 10px;
  margin-right: 10px;
  border: none;
  background: none;
  cursor: pointer;
}

.tab-button.active {
  border-bottom: 2px solid #46B4B1;
  color: #46B4B1;
}


.answer-box {
  margin-top: 20px;
  margin-bottom: 20px;
}

.answer-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding-top: 20px;
}

.action-button {
  padding: 6px 10px;
  background-color: #46B4B1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;

  &:hover {
    background-color: #378F8C;
  }
}

.content-area {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #333;
  .header {
    .left {
      color: #46B4B1;
    }
  }
}
</style>
