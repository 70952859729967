var sliceStr = function (str, len) {
  var len = len || 8;
  if (str != null) {
    if (str.length > len) {
      return str.substring(0, len) + "...";
    } else {
      return str;
    }
  }
  return '';
}

var highLight = function(source, target, step) {
  var targetLength = target.length;
  var targetData = [];
  var filterResult = [];
  // 对目标文字内容进行分段处理
  for(let i = 0; i <= targetLength - step; i++) {
      for(let j = i+ step; j <= targetLength; j++) {
          var targetSplit = target.substring(i,j);
          targetData.push(targetSplit);
      }
  }
  // 在源文字内容查找目标文字分段
  for(var i in targetData) {
      var target = targetData[i];
      if(source.indexOf(target)>=0) {
          filterResult.push(target);
      }
  }
  filterResult.sort((a, b) => b.length - a.length)
  return filterResult;
}

var myReplace = function (content) {
  content = content.replace(" ", "&nbsp;");
  if (content.indexOf(" ") != -1) {
    return myReplace(content);
  }

  return content;
}
module.exports.myReplace = myReplace;
module.exports.highLight = highLight;
module.exports.sliceStr = sliceStr;