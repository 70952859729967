import { login, getUserInfo } from '@/api/index'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
    namespaced: true,
    state: {
        token: getToken(),
        userId: '',
        nickName: '',
        userNumber: '',
        avatar: '',
        versionInfo: {}
    },
    getters: {
        getState(state) {
            return state;
        }
    },
    mutations: {
        SET_TOKEN: (state, data) => {
            state.token = data
        },
        SET_USER_ID: (state, data) => {
            state.userId = data
        },
        SET_NICK_NAME: (state, data) => {
            state.nickName = data
        },
        SET_USER_NUMBER: (state, data) => {
            state.userNumber = data
        },
        SET_AVATAR: (state, data) => {
            state.avatar = data
        },
        SET_VERSION_INFO: (state, data) => {
            state.versionInfo = data
        },
    },
    actions: {
        // 登陆 
        Login({ commit }, userInfo) {
            const account = userInfo.account.trim();
            const password = userInfo.password;
            let data = {}
            if (userInfo.account) {
                data.account = account;
                data.password = userInfo.password;
                data.type = userInfo.type;
            }
            return new Promise((resolve, reject) => {
                login(data).then(response => {
                    setToken(response.token);
                    commit('SET_TOKEN', response.token);
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            });
        },

        // 获取用户信息
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getUserInfo().then(res => {
                    const user = res.data.user
                    const avatar = (user.avatar === "" || user.avatar == null) ? require("@/assets/images/default-user.png") : user.avatar;
                    commit('SET_USER_ID', user.userId)
                    commit('SET_NICK_NAME', user.nickName)
                    commit('SET_USER_NUMBER', user.userNumber)
                    commit('SET_AVATAR', avatar)
                    commit('SET_VERSION_INFO', {
                        'version': res.data.version,
                        'versionFunctions': res.data.versionFunctions,
                        'versionExpireTime': res.data.versionExpireTime
                    })
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        //退出系统
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                commit('SET_TOKEN', '')
                commit('SET_USER_ID', '')
                commit('SET_NICK_NAME', '')
                commit('SET_USER_NUMBER', '')
                commit('SET_AVATAR', '')
                commit('SET_VERSION_INFO', {})
                removeToken()
                resolve()
            })
        },
        
        AddToCollection({ commit }, data) {
            commit('SET_COLLECTIONS', data)
        }
    }
}

export default user