<template>
  <div class="detail-container">
    <div class="detail-left-side">
      <userAction></userAction>
    </div>
    <div class="detail-main">
      <div class="detail-block shadow">
        <div class="detail">
          <div v-if="articDetail.articleInfo.coverImage" class="detail__cover"
            :style="`background-image: url(${articDetail.articleInfo.coverImage})`"></div>
          <h1 class="detail__title">{{ articDetail.articleInfo.title }}</h1>
          <div class="detail__userInfo">
            <router-link :to="'/user/' + articDetail.userInfo.userId" class="detail__user__name" target="_blank">
              <span>{{ articDetail.userInfo.userName }}</span>
            </router-link>
            <div class="detail__user__meta">
              <span class="meta__time">{{ '发布于 ' + articDetail.articleInfo.createTime }}</span>
            </div>
          </div>
          <div class="detail__content" v-html="content"></div>
        </div>
        <div class="tags">
          <span class="tags__title">标签：</span>
          <router-link :to="'/tag?name=' + item.tagName" v-for="item in articDetail.tags" :key="item.tagId" class="tag"
            target="_blank">
            <span class="tag__title">{{ item.tagName }}</span>
          </router-link>
        </div>
      </div>
      <div class="comment-area shadow">
        <p class="comment-area__title">评论<span>{{ commentInfo.total }}</span></p>
        <publish-comment></publish-comment>
        <div class="comment__sort"><span class="comment__sort-item">最新</span><span class="comment__sort-item">最热</span>
        </div>
        <div>
          <comment-item v-for="(item, index) in comments" :key="item.id" :index="index"
            :author-id="articDetail.userInfo.userId" :data="item" @reply-more="getMoreReply"></comment-item>
          <div v-if="commentInfo.hasMore" class="comment__more-btn" @click="getMoreComment">查看更多</div>
        </div>
      </div>
    </div>
    <div class="detail-right-side">
      <about-author :info="articDetail" :showInfo="true" :showFollowbtn="true"></about-author>
      <catalog></catalog>
    </div>
  </div>
</template>

<script>
// import markdownit from 'markdown-it'
// import highlight from 'highlight.js'
// import reachBottom from '~/mixins/reachBottom'
// import commonRequest from '~/mixins/commonRequest'
import aboutAuthor from '@/components/business/detail/aboutAuthor.vue'
import userAction from '@/components/business/detail/userAction.vue'
// import articleSuspendedPanel from '~/components/business/detail/articleSuspendedPanel'
import catalog from '@/components/business/detail/catalog.vue'

export default {
  async asyncData({ app, params }) {
    // 文章信息
    // 相关文章
  },


  validate({ params }) {
    if (params.id && params.id != 'undefined') {
      return true
    }
    return false
  },
  components: {
    aboutAuthor,
    userAction,
    catalog
  },
  // mixins: [reachBottom, commonRequest],
  data() {
    return {
      content: '<p>1. 敢于超前研发。树立首创精神,敢为人先,形成差异化竞争力,抵御风险,赢得优势。 2. 加强技术攻关。成立专门团队,进行大量试验,不懈探索分析,不断尝试改进。 3. 市场需求导向。转变技术指导市场的思路,从客户需求出发,确定产品创新方向。 4. 优化科研生态。激励创新,包容失败,增加科研经费,允许自由支配,重视基础研究。 5. 国际化发展。利用世界优质资源,遵守国际规定,海外并购、建厂,创新全球研发体系。 </p>',
      articDetail: {
        userInfo: {
          userId: 1
        },
        userInfo: {
          avatar_large: '',
          userName: "测试",
          level: 10,
          job_title: '后端',
          company: '微亮'
        },
        articleInfo: {
          descContent: '1. 敢于超前研发。树立首创精神,敢为人先,形成差异化竞争力,抵御风险,赢得优势。 2. 加强技术攻关。成立专门团队,进行大量试验,不懈探索分析,不断尝试改进。 3. 市场需求导',
          createTime: '2023-10-26 17:23',
          view_count: 100,
          coverImage: '',
          title: "这是测试标题",

        },
        user_interact: {
          is_follow: false
        },
        tags: [{
          tagId: 1,
          tagName: 'ce'
        },
        {
          tagId: 2,
          tagName: 'ae'
        },
        {
          tagId: 3,
          tagName: 'se'
        }]
      },
      aboutArticles: [],
      recommendArticles: [],
      comments: [
        {
          userInfo: {
            userId: 1,
            avatar_large: '',
            userName: "测试",
            level: 10,
            job_title: '后端',
            company: '微亮'
          },
          comment_info: {
            comment_content: '这是第一条评论内容',
            createTime: '1天前',
            reply_count: 3,
          },
          reply_infos: [
            {
              userInfo: {
                userId: 2,
                avatar_large: '',
                userName: "12",
                level: 10,
                job_title: '后端1111',
              },
              replay_user:{
                userId: 2,
                avatar_large: '',
                userName: "AAAA",
              },
              replay_info:{
                replay_content: '这是回复用户评论内容',
                createTime: '3小时前',
              },
            },
            {
              userInfo: {
                userId: 2,
                avatar_large: '',
                userName: "12",
                level: 10,
                job_title: '后端1111',
              },
              replay_user:{
                userId: -2,
                avatar_large: '',
                userName: "AAAA",
              },
              replay_info:{
                replay_content: '这是回复主评论内容',
                createTime: '3小时前',
              },
            },
          ]
        },
        {
          userInfo: {
            userId: 1,
            avatar_large: '',
            userName: "测试",
            level: 10,
            job_title: '后端',
            company: '微亮'
          },
          comment_info: {
            comment_content: '这是第一条评论内容',
            createTime: '1天前',
          },
          reply_infos: [
            {
              userInfo: {
                userId: 2,
                avatar_large: '',
                userName: "12",
                level: 10,
                job_title: '后端1111',
              },
              replay_user:{
                userId: 3,
                avatar_large: '',
                userName: "测试22",
              },
              replay_info:{
                replay_content: '这是回复评论内容',
                createTime: '3小时前',
              },
            }
          ]
        }
      ],
      // 评论分页信息
      commentInfo: {
        total: 10,
        hasMore: true,
        cursor: ''
      },
      // 末尾推荐文章信息
      recommendArticlesInfo: {
        hasMore: true,
        cursor: ''
      },
      tagIds: '',
    }
  },

  mounted() {
    this.setTagIds()
    this.getCommentList()
    this.getRecommendEntryByTagIds()
    if (location.hash === '#comment') {
      this.$refs.panel.scrollIntoComment()
    }
  },
  methods: {
    reachBottom() {
      if (this.recommendArticlesInfo.hasMore) {
        this.getRecommendEntryByTagIds()
      }
    },
    getMoreComment() {
      if (this.commentInfo.hasMore) {
        this.getCommentList()
      }
    },
    setTagIds() {
      if (this.articDetail.tags) {
        this.tagIds = this.articDetail.tags.map(item => item.tagId)
      }
    },
    // 获取评论
    getCommentList() {

    },
    // 推荐文章
    getRecommendEntryByTagIds() {

    },
    // 点赞（暂时废了）
    setGood() {
      if (!this.$store.state.auth.token) {
        this.$loginModal(this)
        return
      }
      let isLike = this.articDetail.user_interact.is_like
      let count = this.articDetail.articleInfo.like_count
      this.$api.articleLike({
        isCollected: !isLike,
        entryId: this.articDetail.entryId
      }).then(res => {
        if (res.s === 1) {
          isLike = !isLike
          isLike ? count++ : count--
        }
      })
    },
    // 更多回复
    getMoreReply({ index }) {
      let comment = this.comments[index]
      this.$api.getReplyList({
        item_type: 2,
        item_id: this.$route.params.id,
        limit: 5,
        cursor: comment.cursor || '',
        comment_id: comment.comment_id,
      }).then(res => {
        if (res.err_no === 0) {
          comment.reply_infos = comment.cursor ? comment.reply_infos.concat(res.data) : res.data
          comment.cursor = res.cursor
          comment.has_reply_more = res.has_more
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.detail-container {
  display: flex;
  min-width: 620px;
  .detail-main {
    width: 820px;
    margin-right: 20px;

    .detail-block {
      padding: 16px;
      background: #fff;
      border-radius: 10px;
      
    }

    .comment-area {
      margin-top: 20px;
      padding: 16px;
      border-radius: 10px;
      background-color: #fff;

      .comment__sort {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        .comment__sort-item {
          display: flex;
          align-items: center;
          font-size: 16px;
          cursor: pointer;

          &:nth-of-type(1) {
            color: #46B4B1;
          }

          &:nth-of-type(1)::after {
            content: '';
            width: 1px;
            height: 12px;
            background: #000;
            margin: 0 12px;
          }
        }
      }

      .comment-area__title {
        padding-bottom: 20px;

        text-align: left;
        color: #000;
        font-size: 18px;
        font-weight: bold;

        span {
          color: #000;
          font-size: 18px;
          font-weight: bold;
          margin-left: 8px;
        }
      }

      .comment__more-btn {
        padding: 10px;
        text-align: center;
        font-size: 14px;
        color: #406599;
        cursor: pointer;

        &:hover {
          opacity: .8;
        }
      }
    }
  }

  .detail-right-side {
    width: 300px;

  }
}

.detail {
  border-radius: 2px;

  .detail__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .detail__cover {
    width: 100%;
    padding-top: 50%;
    background-size: cover;
    background-position: 50%;
    margin-bottom: 30px;
  }

  .detail__title {
    margin-bottom: 16px;
    line-height: 1.6;
    font-size: 30px;
    font-weight: 700;
    color: #000;
  }

  .detail__content {
    line-height: 1.6;
    color: #333;

    ::v-deep img {
      width: 100%;
    }

    ::v-deep strong {
      font-weight: bold;
    }

    ::v-deep p {
      margin: 22px 0;

      >code {
        background-color: rgb(255, 245, 245);
        color: rgb(255, 80, 44);
        font-size: 13px;
        padding: 1px 5px;
        border-radius: 2px;
        overflow-x: auto;
      }
    }

    ::v-deep h1 {
      margin-top: 40px;
      margin-bottom: 12px;
      font-size: 26px;
      font-weight: 700;
    }

    ::v-deep h2 {
      margin-top: 24px;
      margin-bottom: 12px;
      font-size: 24px;
      font-weight: 700;
      border-bottom: 1px solid rgb(236, 236, 236);
    }

    ::v-deep h3 {
      margin-top: 15px;
      margin-bottom: 12px;
      font-size: 18px;
      font-weight: 700;
    }

    ::v-deep h4 {
      margin-top: 15px;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 700;
    }

    ::v-deep h5 {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 700;
    }

    ::v-deep code {
      font-size: 13px;
      line-height: 1.8;
      letter-spacing: .6px;
    }

    ::v-deep ol,
    ::v-deep ul {
      padding-left: 28px;
      list-style: initial;
      list-style-type: decimal;
    }

    ::v-deep li {
      padding-left: 6px;
      list-style: initial;
      list-style-type: decimal;
    }

    ::v-deep blockquote {
      color: #666;
      padding: 1px 23px;
      margin: 22px 0;
      border-left: 4px solid #cbcbcb;
      background-color: #f8f8f8;
    }

    ::v-deep a {
      color: #0269c8;
      border-bottom: 1px solid #d1e9ff;
      word-break: break-all;

      &:hover {
        color: #007fff;
      }

      &:active {
        color: #275b8c;
      }
    }
  }
}

.detail__userInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;

  .detail__user__name {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      color: #333;
      margin-right: 30px;
    }
  }

  .detail__user__meta {
    letter-spacing: 1px;
    font-size: 14px;
    color: #909090;

    .meta__time {
      margin-right: 10px;
    }
  }
}

.tags {
  margin-top: 30px;

  .tags__title {
    color: #333;
    margin-right: 12px;
  }

  .tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px 12px 0;
    padding: 2px;
    border-radius: 5px;
    background: #F7F8FA;
    height: 32px;
    padding: 0 12px;
    text-decoration: none;

    .tag__title {
      font-size: 14px;
      color: #999;
    }
  }
}

.author-info {
  display: flex;
  padding: 15px;
  margin: 30px 0;
  background: #f4f4f4;
  cursor: pointer;

  .author__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .author-info__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2px 0;
    color: #999;
    font-size: 14px;
    margin-left: 10px;

    .author__name {
      font-size: 16px;
      font-weight: bold;
      color: #262626;
    }
  }
}</style>
