<template>
  <div class="card-container-wrapper">
    <div class="time-display">
      <div class="time-block">{{ numbers.h }}</div>
      <div class="time-separator">:</div>
      <div class="time-block">{{ numbers.m }}</div>
      <div class="time-separator">:</div>
      <div class="time-block">{{ numbers.s }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
      numbers: {
        h: '00',
        m: '00',
        s: '00'
      }
    }
  },
  methods: {
    setNumbers() {
      const now = new Date();
      this.numbers = {
        h: now.getHours().toString().padStart(2, "0"),
        m: now.getMinutes().toString().padStart(2, "0"),
        s: now.getSeconds().toString().padStart(2, "0")
      };
    },
    initClock() {
      this.setNumbers();
      
      const now = new Date();
      const delay = 1000 - now.getMilliseconds();

      setTimeout(() => {
        this.setNumbers();
        this.timer = setInterval(() => this.setNumbers(), 1000);
      }, delay);
    }
  },
  mounted() {
    this.initClock();
  },
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
</script>

<style scoped lang="scss">
.card-container-wrapper {
  display: flex;
  flex-direction: row;
}

.time-display {
  display: flex;
  align-items: center;
  gap: 10px;
}

.time-block {
  width: 200px;
  height: 200px;
  background: #2c292c;
  color: #fff;
  font-size: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.time-separator {
  color: #fff;
  font-size: 80px;
  margin: 0 10px;
}
</style>