<template>
    <div>
        <div class="wrapper">
            <el-form class="login-content" ref="loginForm" :model="loginForm" :rules="loginRules" @keyup.enter.native="login">
                <div class="login-title">
                    <img src="@/assets/images/logo.png" alt="">
                    欢迎使用囊中对比
                </div>
                <el-form-item class="account" prop="account">
                    <el-input prefix-icon="el-icon-message" v-model="loginForm.account" placeholder="请输入邮箱"
                        class="account-input"></el-input>
                </el-form-item>
                <el-form-item class="password" prop="password">
                    <el-input prefix-icon="el-icon-lock" v-model="loginForm.password" 
                        :type="passwordVisible ? 'text' : 'password'"
                        placeholder="请输入密码" class="password-input">
                        <i slot="suffix" 
                           :class="['el-input__icon', passwordVisible ? 'el-icon-view' : 'el-icon-view']"
                           @click="passwordVisible = !passwordVisible">
                        </i>
                    </el-input>
                </el-form-item>
                <div class="login-options">
                    <el-checkbox v-model="rememberPassword">记住密码</el-checkbox>
                    <div>
                        <a href="javascript:" @click="forgetPassword" class="forget-password-link">忘记密码？</a>
                    </div>
                </div>
                <div class="operate">
                    <el-button type="primary" :loading="loading" @click="login">登录</el-button>
                </div>
                <div class="register-option">
                    <a href="javascript:" @click="register" class="register-link">注册新账号</a>
                </div>
                <div class="login-aggrement">
                    登录/注册表示同意
                    <a href="javascript:;" @click="openPrivacyPolicy">隐私政策</a>
                    和
                    <a href="javascript:;" @click="openServiceAgreement">服务条款</a>
                </div>
            </el-form>
        </div>

        <!-- 添加隐私政策弹窗 -->
        <div v-if="showPrivacyPolicy" class="policy-modal">
          <div class="modal-content">
            <iframe :src="privacyPolicyUrl" frameborder="0"></iframe>
            <button @click="closePrivacyPolicy" class="close-btn">返回</button>
          </div>
        </div>

        <!-- 服务条款弹窗 -->
        <div v-if="showServiceAgreement" class="policy-modal">
          <div class="modal-content">
            <iframe :src="serviceAgreementUrl" frameborder="0"></iframe>
            <button @click="closeServiceAgreement" class="close-btn">返回</button>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AccountLoginPage",
    data() {
        return {
            loginForm: {
                account: '',
                password: '',
                type: '0'
            },
            passwordVisible: false,
            rememberPassword: true, // 默认勾选记住密码
            loginRules: {
                account: [{ required: true, message: '请输入邮箱', trigger: 'blur' },
                { type: 'email', message: '请输入正确的邮箱格式', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' },
                { min: 6, message: '密码长度至少为6位', trigger: 'blur' }],
            },
            redirect: undefined,
            loading: false,
            showPrivacyPolicy: false,
            showServiceAgreement: false,
            privacyPolicyUrl: 'https://help.srnz.net/service/privicy.html',
            serviceAgreementUrl: 'https://help.srnz.net/service/agreement.html'
        }
    },
    created() {
        // 组件创建时检查本地存储中是否有保存的账号密码
        const rememberedAccount = localStorage.getItem('rememberedAccount')
        const rememberedPassword = localStorage.getItem('rememberedPassword')
        if (rememberedAccount && rememberedPassword) {
            this.loginForm.account = rememberedAccount
            this.loginForm.password = rememberedPassword
        }
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true
        }
    },

    methods: {
        async login() {
            try {
                const valid = await this.$refs.loginForm.validate();
                if (!valid) {
                    this.$message.error('请正确填写登录信息');
                    return;
                }
                this.loading = true;
                await this.$store.dispatch("user/Login", this.loginForm);
                
                // 根据是否勾选记住密码来存储账号密码
                if (this.rememberPassword) {
                    localStorage.setItem('rememberedAccount', this.loginForm.account)
                    localStorage.setItem('rememberedPassword', this.loginForm.password)
                } else {
                    localStorage.removeItem('rememberedAccount')
                    localStorage.removeItem('rememberedPassword')
                }
                
                await this.$router.push(this.redirect || "/community/home");
            } catch (error) {
                console.error('登录失败:', error);
                this.$message.error(error || '登录失败，请重试');
            } finally {
                this.loading = false;
            }
        },
        forgetPassword() {
            this.$router.push("/login/forget");
        },
        register() {
            this.$router.push("/login/register");
        },
        openPrivacyPolicy() {
            this.showPrivacyPolicy = true;
        },
        closePrivacyPolicy() {
            this.showPrivacyPolicy = false;
        },
        openServiceAgreement() {
            this.showServiceAgreement = true;
        },
        closeServiceAgreement() {
            this.showServiceAgreement = false;
        }
    }
}

</script>
<style lang="scss" scoped>
.wrapper {
    background-image: url(~@/assets/images/login-bgc.svg);
    background-size: 100%;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    .login-content {
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba(129, 129, 129, 0.25);
        max-width: 500px;
        width: 100%;
        padding: 30px 0px;
        background-color: $background-color;
        display: flex;
        align-items: center;
        flex-direction: column;

        // align-items : center;
        .login-title {
            font-size: $font-size-Level4;
            line-height: 48px;

            img {
                width: 36px;
            }
        }

        .account,
        .password {
            input:focus {
                border: 1px solid $color-primary;
            }

            ;
        }

        .account {
            margin-top: 48px;
            position: relative;
            display: flex;
            align-items: center;
            width: 320px;

            input:focus {
                border: 1px solid $color-primary !important;
            }

            .icon-wrapper {
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translateY(-50%);
                color: $font-color-fourth;
            }

            .account-input :deep(.el-input__inner) {
                height: 48px !important;
                border-radius: 25px;
                width: 320px;
                padding: 0 24px 0 40px;
                background-color: $background-color-secondary;
                color: $font-color-fourth;

                &:focus {
                    border: 1px solid $color-primary !important;
                }
            }
        }

        .password {
            margin-top: 12px;
            position: relative;
            display: flex;
            align-items: center;
            width: 320px;

            .icon-wrapper {
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translateY(-50%);
                color: $font-color-fourth;
            }

            .password-input :deep(.el-input__inner) {
                background-color: $background-color-secondary;
                color: $font-color-fourth;
                width: 320px;
                height: 48px !important;
                border-radius: 25px;
                padding: 0 24px 0 40px;

                &:focus {
                    border: 1px solid $color-primary !important;
                }
            }

            :deep(.el-input__icon) {
                cursor: pointer;
                margin-right: 10px;
                opacity: 1;
            }
        }

        .login-options {
            width: 320px;
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        
            > div {
                display: flex;
                gap: 16px;
            }
        
            :deep(.el-checkbox__label) {
                color: $font-color-third;
            }
            
            :deep(.el-checkbox__input) {
                &:hover {
                    .el-checkbox__inner {
                        border-color: $font-color-third;
                    }
                }
            }

            :deep(.el-checkbox__input.is-checked) {
                .el-checkbox__inner {
                    background-color: $font-color-third;
                    border-color: $font-color-third;
                }
            }
        
            a {
                color: $font-color-third;
                text-decoration: none;
                font-size: $font-size-primary;
        
                &:hover {
                    text-decoration: underline;
                }
            }
        }


        .operate {
            margin-top: 64px;
            display: flex;
            justify-content: center;
            width: 320px;

            button {
                width: 160px;
                padding: 12px 32px;
                border-radius: 25px;
                border: none;
                color: $font-color-secondary;
            }

            button:nth-child(1) {
                background-color: $color-secondary;
            }

            button:nth-child(1):hover {
                background-color: $color-primary;
            }
        }

        .register-option {
            margin-top: 16px;
            display: flex;
            justify-content: center;
            width: 320px;

            .register-link {
                color: $font-color-third;
                text-decoration: none;
                font-size: $font-size-primary;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .login-aggrement {
            margin-top: 24px;
            font-size: $font-size-primary;
            color: $font-color-primary;

            a {
                color: $font-color-third;
            }
        }
    }
}

.policy-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 1000;

  .modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;

    iframe {
      flex-grow: 1;
      width: 100%;
      height: calc(100% - 50px);
      border: none;
    }

    .close-btn {
      height: 50px;
      width: 100%;
      background-color: $color-primary;
      color: white;
      border: none;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>
