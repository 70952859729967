<template>
    <div class="user-data">
        <div class="user-info">
            <div class="avatar-container">
                <div class="avatar">
                    <user-avatar :url="this.$store.getters['user/getState'].avatar" :round="true"></user-avatar>
                </div>
                <el-upload class="avatar-uploader" :action="uploadMinioUrl" :show-file-list="false"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :headers="uploadHeaders">
                    <div class="upload-mask">
                        <i class="el-icon-camera"></i>
                        <span>更换头像</span>
                    </div>
                </el-upload>
            </div>
            <div class="info">
                <div class="nick-name-container">
                    <span v-if="!isEditingNickName" class="nick-name" @click="startEditNickName">
                        {{ this.$store.getters['user/getState'].nickName || '囊友' }}
                        <i class="el-icon-edit edit-icon"></i>
                    </span>
                    <div v-else class="nick-name-edit">
                        <el-input v-model="newNickName" size="small" placeholder="请输入昵称" maxlength="20" show-word-limit
                            @blur="handleNickNameBlur" @keyup.enter.native="saveNickName"
                            ref="nickNameInput"></el-input>
                        <div class="edit-actions">
                            <el-button type="text" size="small" @mousedown.native="handleButtonMousedown"
                                @click="cancelEditNickName">取消</el-button>
                            <el-button type="primary" size="small" @mousedown.native="handleButtonMousedown"
                                @click="saveNickName" :loading="saving">保存</el-button>
                        </div>
                    </div>
                </div>
                <div class="user-number-container">
                    <span class="user-number">{{ this.$store.getters['user/getState'].userNumber }}</span>
                    <el-button type="text" class="copy-btn" @click="copyUserNumber">
                        <i class="el-icon-document-copy"></i>
                    </el-button>
                </div>
                <div class="version-info-container">
                    <span :style="versionStyle" class="version-tag">
                        <img v-if="versionInfo.version" style="width: 18px;" src="@/assets/images/vip.svg" alt="">
                        {{ versionTag }}
                        <span v-if="versionInfo.version"></span>
                    </span>
                    <span class="version-time" v-if="versionInfo.version">{{ expirationText }}</span>
                    <span class="version-action">
                        <el-button v-if="!versionInfo.version" type="info" size="mini" plain
                            icon="el-icon-position" @click="versionVisable = true">开通会员</el-button>
                        <el-button v-else type="info" size="mini" plain icon="el-icon-position" @click="versionVisable = true">立即续期</el-button>
                    </span>
                </div>
                <!-- <div class="tags">
                    <span class="tag">标签1</span>
                    <span class="tag">标签2</span>
                    <span class="tag">标签3</span>
                </div> -->
                <!-- <span class="user-name-desc">个性签名</span> -->
            </div>
            <div class="action-area">
                <!-- <follow-btn></follow-btn> -->
            </div>
        </div>
        <version :visable="versionVisable" @close="versionVisable = false" @refresh="getVersionInfo"></version>
    </div>
</template>

<script>
import { updateUser, uploadMinioUrl } from '@/api';
import { getToken } from "@/utils/auth";
import version from "@/components/business/version/index.vue"

export default {
    props: {
    },

    components: {
        version
    },

    data() {
        return {
            uploadMinioUrl,
            isEditingNickName: false,
            newNickName: '',
            saving: false,
            versionInfo: {},
            versionStyle: "",
            uploadHeaders: {
                Authorization: 'Bearer ' + getToken()
            },
            versionVisable: false,
        }
    },
    computed: {
        expirationText() {
            const daysLeft = this.calculateDaysLeft(this.versionInfo.versionExpireTime);
            if (daysLeft < 0) {
                return `会员权益已过期 ${Math.abs(daysLeft)} 天`;
            } else {
                return `有效期至：${this.versionInfo.versionExpireTime.split("T")[0]}`;
            }
        },
        versionTag() {
            if (this.versionInfo.version === 'clean') {

                const daysLeft = this.calculateDaysLeft(this.versionInfo.versionExpireTime);
                if (daysLeft < 0) {
                    this.versionStyle = 'background:#ccc;'
                } else {
                    this.versionStyle = 'background:#B89A70;'
                }
                return '纯净版';
            } else if (this.versionInfo.version === 'pro') {
                const daysLeft = this.calculateDaysLeft(this.versionInfo.versionExpireTime);
                if (daysLeft < 0) {
                    this.versionStyle = 'background:#ccc;'
                } else {
                    this.versionStyle = 'background:#666666;'
                }
                return '尊享版';
            } else {
                this.versionStyle = 'background:#ccc;'
                return '普通版'
            }
        }
    },
    created() {
        this.getVersionInfo();
    },
    methods: {
        calculateDaysLeft(expireDate) {
            const today = new Date();
            const expire = new Date(expireDate);
            const timeDiff = expire - today;
            const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
            return daysLeft;
        },
        copyUserNumber() {
            const userNumber = this.$store.getters['user/getState'].userNumber;
            navigator.clipboard.writeText(userNumber).then(() => {
                this.$message.success('囊友号已复制！');
            }).catch(() => {
                this.$message.error('复制失败，请重试');
            });
        },
        startEditNickName() {
            this.isEditingNickName = true;
            this.newNickName = this.$store.getters['user/getState'].nickName || '';
            this.$nextTick(() => {
                this.$refs.nickNameInput.focus();
            });
        },
        cancelEditNickName() {
            this.isEditingNickName = false;
            this.newNickName = '';
        },
        getVersionInfo() {
            this.versionInfo = this.$store.getters['user/getState'].versionInfo || {};
        },
        async saveNickName() {
            if (!this.newNickName.trim()) {
                this.$message.warning('昵称不能为空');
                return;
            }

            this.saving = true;
            try {
                await updateUser({
                    userId: this.$store.getters['user/getState'].userId,
                    nickName: this.newNickName.trim()
                });

                // 更新 Vuex store 中的用户信息
                await this.$store.dispatch('user/GetInfo');

                this.$message.success('昵称修改成功');
                this.isEditingNickName = false;
            } catch (error) {
                console.error('修改昵称失败:', error);
                this.$message.error('修改昵称失败，请重试');
            } finally {
                this.saving = false;
            }
        },
        handleButtonMousedown(e) {
            // 阻止按钮点击触发输入框的 blur 事件
            e.preventDefault();
        },
        handleNickNameBlur(e) {
            // 检查点击的是否是编辑区域内的元素
            const editArea = e.target.closest('.nick-name-edit');
            const relatedTarget = e.relatedTarget;

            if (!this.saving && (!relatedTarget || !editArea?.contains(relatedTarget))) {
                this.cancelEditNickName();
            }
        },
        beforeAvatarUpload(file) {
            const isImage = file.type.startsWith('image/');
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isImage) {
                this.$message.error('只能上传图片文件！');
                return false;
            }
            if (!isLt2M) {
                this.$message.error('图片大小不能超过 2MB！');
                return false;
            }
            return true;
        },
        async handleAvatarSuccess(response) {
            if (response.code === 200) {
                try {
                    // 更新用户头像
                    await updateUser({
                        userId: this.$store.getters['user/getState'].userId,
                        avatar: response.url
                    });

                    // 更新 Vuex store 中的用户信息
                    await this.$store.dispatch('user/GetInfo');

                    this.$message.success('头像更新成功');
                } catch (error) {
                    console.error('更新头像失败:', error);
                    this.$message.error('更新头像失败，请重试');
                }
            } else {
                this.$message.error('上传头像失败，请重试');
            }
        }
    }
}
</script>

<style lang='scss' scoped>
.user-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $background-color;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 12px;

    .user-info {
        display: flex;

        .avatar-container {
            position: relative;
            width: 90px;
            height: 90px;
            margin-right: 36px;

            .avatar {
                width: 100%;
                height: 100%;
            }

            .avatar-uploader {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;

                &:hover .upload-mask {
                    opacity: 1;
                }
            }

            .upload-mask {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: opacity 0.3s;
                color: white;

                i {
                    font-size: 20px;
                    margin-bottom: 4px;
                }

                span {
                    font-size: 12px;
                }
            }
        }

        .info {
            width: 100%;
            display: flex;
            flex-direction: column;

            .nick-name-container {
                display: flex;
                align-items: center;

                .nick-name {
                    font-size: 20px;
                    font-weight: 600;
                    color: #000;
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    .edit-icon {
                        font-size: 16px;
                        margin-left: 8px;
                        color: #86909c;
                        opacity: 0;
                        transition: opacity 0.3s;
                    }

                    &:hover .edit-icon {
                        opacity: 1;
                    }
                }

                .nick-name-edit {
                    width: 200px;

                    .el-input {
                        margin-bottom: 8px;
                    }

                    .edit-actions {
                        display: flex;
                        justify-content: flex-end;
                        gap: 8px;

                        .el-button--primary {
                            background-color: #46B4B1;
                            border-color: #46B4B1;

                            &:hover {
                                background-color: #378F8C;
                                border-color: #378F8C;
                            }
                        }
                    }
                }
            }

            .user-number-container {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                .user-number {
                    font-size: 16px;
                    color: #515767;
                }

                .copy-btn {
                    padding: 4px;
                    margin-left: 4px;
                    color: #333;

                    i {
                        font-size: 16px;
                    }
                }
            }

            .version-info-container {
                display: flex;

                .version-tag {
                    border-radius: 14px;
                    display: flex;
                    align-items: center;
                    color: #fff;
                    // background-color: #ddd;
                    font-size: 12px;
                    padding: 0 10px;
                    justify-content: space-between;

                    img {
                        margin-right: 10px;
                    }

                    margin-right: 20px;
                }

                .version-time {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                }

                .version-action {
                    .el-button {
                        background-color: #ecfff9;
                        border-color: #46B4B1;
                        color: #46B4B1;
                    }

                    .el-button:hover {
                        background-color: #46B4B1;
                        border-color: #46B4B1;
                        color: #fff;
                    }
                }
            }

            .tags {
                margin-top: 16px;
                display: flex;
                justify-content: flex-start;

                .tag {
                    padding: 0 12px;
                    font-size: 12px;
                    color: #515767;
                    margin-right: 16px;
                    background-color: #F7F8FA;
                }
            }

            .user-name-desc {
                color: #8A919F;
                font-size: 16px;
                margin-top: 16px;
            }
        }
    }

    .action-area {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }
}
</style>
