/**
 * 控制加解密，需要与后台一致
 * @type {boolean}
 */
import CryptoJS from 'crypto-js';

const sckey = "2024102810025201";

const aesflag = true;

/**
 * 请求参数加密
 * @param params 参数
 * @param type 请求类型
 * @returns {{}}
 */
function encryptParams(params, type) {
    var paramdata = params;
    var encryptData = {};
    if (!aesflag) {
        return paramdata;
    }
    if (type == '' || type == null) {
        console.log("请求类型为空");
    }
    for (var key in params) {
        encryptData[key] = AESEncrypt(params[key]);
    }
    paramdata = encryptData;
    return paramdata;
}

function encryptParamsBody(params) {
    params = {"pageNum":1,"pageSize":10}
    var paramdata = params;
    var encryptData = {};
    if (!aesflag) {
        return paramdata;
    }
    paramdata = AESEncrypt(params);
    return paramdata;
}

/**
 *  加密url中的参数，有些人喜欢在url后面通过?的方式添加参数，也需要加密下
 * @param url
 */
function encryptUrlParams(url){
    if (!aesflag || url.indexOf("?") == -1) {
        return url;
    }
    var arr;
    var urlparams = url.substring(url.indexOf("?")+1, url.length);
    var url = url.substring(0, url.indexOf("?"));
    var params = urlparams.split("&");
    var encryptparams = "";
    for(var i = 0; i < params.length; i++){
        arr = params[i].split("=");
        var name = arr[0];
        var value = arr[1];
        encryptparams += (name + "=" + AESEncrypt(value));
        if(i < params.length - 1){
            encryptparams += "&";
        }
    }
    url += "?"+encryptparams;
    return url;
}

/**
 * AES加密
 * @param word 参数
 * @returns {string}
 * @constructor
 */
function AESEncrypt(word) {
    if (!aesflag) {
        return word;
    }
    var key = CryptoJS.enc.Utf8.parse(sckey);
    var srcs = CryptoJS.enc.Utf8.parse(word);
    var resultByte = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    // + 在ajax中属于字符串连接符号，会被替换成空格，需要转下
    return str_encode(resultByte);
}

/**
 * AES解密
 * @param word 待解密密文
 * @returns {any}
 * @constructor
 */
function AESDecrypt(word) {
	
    if (!aesflag) {
        return word;
    }
    var key = CryptoJS.enc.Utf8.parse(sckey);
    var resultByte = CryptoJS.AES.decrypt(word, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return JSON.parse(CryptoJS.enc.Utf8.stringify(resultByte).toString());
}

// private property
var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

// public method for encoding
var str_encode = function (input) {
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;
    input = str_utf8_encode(input);
    while (i < input.length) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);
        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;
        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output = output +
            _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
            _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
    }
    return output;
}

// public method for decoding
var str_decode = function (input) {
    var output = "";
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    while (i < input.length) {
        enc1 = _keyStr.indexOf(input.charAt(i++));
        enc2 = _keyStr.indexOf(input.charAt(i++));
        enc3 = _keyStr.indexOf(input.charAt(i++));
        enc4 = _keyStr.indexOf(input.charAt(i++));
        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;
        output = output + String.fromCharCode(chr1);
        if (enc3 != 64) {
            output = output + String.fromCharCode(chr2);
        }
        if (enc4 != 64) {
            output = output + String.fromCharCode(chr3);
        }
    }
    output = str_utf8_decode(output);
    return output;
}

// private method for UTF-8 encoding
var str_utf8_encode = function (string) {
    string = string.toString().replace(/\r\n/g,"\n");
    var utftext = "";
    for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);
        if (c < 128) {
            utftext += String.fromCharCode(c);
        } else if((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
        } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
        }

    }
    return utftext;
}

// private method for UTF-8 decoding
var str_utf8_decode = function (utftext) {
    var string = "";
    var i = 0;
    var c = c1 = c2 = 0;
    while ( i < utftext.length ) {
        c = utftext.charCodeAt(i);
        if (c < 128) {
            string += String.fromCharCode(c);
            i++;
        } else if((c > 191) && (c < 224)) {
            c2 = utftext.charCodeAt(i+1);
            string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
            i += 2;
        } else {
            c2 = utftext.charCodeAt(i+1);
            c3 = utftext.charCodeAt(i+2);
            string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
            i += 3;
        }
    }
    return string;
}

export {
    encryptParams,
    encryptUrlParams,
    encryptParamsBody,
    str_encode,
    str_decode,
    str_utf8_encode,
    str_utf8_decode,
    AESEncrypt,
    AESDecrypt
}