import { getAreaList } from '@/api/index'
export default {
    namespaced: true,
    state: {
        areaCategoryList: [

        ]
    },

    getters: {
        getState(state) {
            return state;
        }
    },

    mutations: {
        SET_AREA_CATEGORY(state, data) {
            state.areaCategoryList = data;
        }
    },

    actions: {
        // 获取区域列表
        GetAreaList({ commit, state }) {
            return new Promise((resolve, reject) => {
                getAreaList({
                    page: 1,
                    pageSize: 1000
                }).then(res => {
                    commit('SET_AREA_CATEGORY', res.rows)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}