<template>
  <div class="editor-wrapper">
    <el-upload :action="uploadUrl" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess"
      :on-error="handleUploadError" name="file" :show-file-list="false" :headers="headers" style="display: none"
      ref="upload" v-if="this.type === 'url'">
    </el-upload>
    <div class="editor" ref="editor" :style="styles"> </div>
    <div class="action-bar">
      <div class="action-alert">{{ alertText }}</div>
      <div class="action-draft">草稿箱</div>
      <div class="action-publish" @click="toPublish()">发布</div>
    </div>
    <div class="tags-bar">
      <el-select v-model="areaValue" placeholder="请选择分享适用区域">
        <el-option v-for="item in areaList" :key="item.areaId" :label="item.content" :value="item.areaId">
        </el-option>
      </el-select>
      <el-select class="tag-select" filterable remote :remote-method="getTagList" :focus="getTagList" @change="handleCorrect" v-model="tagValue" placeholder="请搜索添加分享标签" :loading="loading">
        <el-option v-for="item in tagList" :key="item.tagId" :label="item.name" :value="item">
        </el-option>
      </el-select>
      <div class="tag-list">
        <span class="meta-item__tag" v-for="tag in selectedTagList" :key="tag.tagId">{{ tag.name }}
          <i class="el-icon-circle-close" @click="handleDeleteTag(tag)"></i>
        </span>
      </div>
    </div>
    <div class="title">
      <input type="text" placeholder="请输入标题" @input="handleSave" v-model="title" maxlength="100">
    </div>
  </div>
</template>
  
<script>
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { getToken } from "@/utils/auth";
import { getTagList, insertArticleDraft, updateArticleDraft, insertArticle } from '@/api';
export default {
  name: "Editor",
  props: {
    /* 编辑器的内容 */
    value: {
      type: String,
      default: "",
    },
    /* 高度 */
    height: {
      type: Number,
      default: null,
    },
    /* 最小高度 */
    minHeight: {
      type: Number,
      default: null,
    },
    /* 只读 */
    readOnly: {
      type: Boolean,
      default: false,
    },
    /* 上传文件大小限制(MB) */
    fileSize: {
      type: Number,
      default: 5,
    },
    /* 类型（base64格式、url格式） */
    type: {
      type: String,
      default: "url",
    }
  },
  data() {
    return {
      title: "",
      tagValue: null,
      tagList: [],
      loading: false,
      selectedTagList:[],
      areaValue: null,
      alertText: "文章将自动保存至草稿箱",
      uploadUrl: process.env.VUE_APP_BASE_API + "/common/upload", // 上传的图片服务器地址
      headers: {
        Authorization: "Bearer " + getToken()
      },
      draftId: null,
      articleId: null,
      Quill: null,
      currentValue: "",
      options: {
        theme: "snow",
        bounds: document.body,
        debug: "warn",
        modules: {
          // 工具栏配置
          toolbar: [
            ["bold", "italic", "underline", "strike"],       // 加粗 斜体 下划线 删除线
            [{ list: "ordered" }, { list: "bullet" }],       // 有序、无序列表
            [{ header: [1, 2, 3, 4, 5, 6, false] }],         // 标题
            [{ color: [] }, { background: [] }],             // 字体颜色、字体背景颜色                            // 对齐方式
            ["clean"],                                       // 清除文本格式
            ["image"]                       // 链接、图片、视频
          ],
        },
        placeholder: "请输入内容",
        readOnly: this.readOnly,
      },
    };
  },
  computed: {
    areaList() {
      return this.$store.getters["category/getState"].areaCategoryList;
    },
    styles() {
      let style = {};
      if (this.minHeight) {
        style.minHeight = `${this.minHeight}px`;
      }
      if (this.height) {
        style.height = `${this.height}px`;
      }
      return style;
    },
  },
  watch: {
    value: {
      handler(val) {
        if(val) {
          this.handleSave();
        }
      },
      immediate: true,
    }
  },
  created() {
    if(this.areaList.length === 0) {
      this.$store.dispatch('category/GetAreaList')
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.Quill = null;
  },
  methods: {

    init() {
      const editor = this.$refs.editor;
      this.Quill = new Quill(editor, this.options);
      // 如果设置了上传地址则自定义图片上传事件
      if (this.type === 'url') {
        let toolbar = this.Quill.getModule("toolbar");
        toolbar.addHandler("image", (value) => {
          if (value) {
            this.$refs.upload.$children[0].$refs.input.click();
          } else {
            this.quill.format("image", false);
          }
        });
      }
      this.Quill.pasteHTML(this.currentValue);
      this.Quill.on("text-change", (delta, oldDelta, source) => {
        const html = this.$refs.editor.children[0].innerHTML;
        const text = this.Quill.getText();
        const quill = this.Quill;
        this.currentValue = html;
        this.$emit("input", html);
        this.$emit("on-change", { html, text, quill });
      });
      this.Quill.on("text-change", (delta, oldDelta, source) => {
        this.$emit("on-text-change", delta, oldDelta, source);
      });
      this.Quill.on("selection-change", (range, oldRange, source) => {
        this.$emit("on-selection-change", range, oldRange, source);
      });
      this.Quill.on("editor-change", (eventName, ...args) => {
        this.$emit("on-editor-change", eventName, ...args);
      });
      this.getTagList();
    },

    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      const type = ["image/jpeg", "image/jpg", "image/png", "image/svg"];
      const isJPG = type.includes(file.type);
      // 检验文件格式
      if (!isJPG) {
        this.$message.error(`图片格式错误!`);
        return false;
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      return true;
    },

    handleUploadSuccess(res, file) {
      // 如果上传成功
      if (res.code === 200) {
        // 获取富文本组件实例
        let quill = this.Quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  res.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", process.env.VUE_APP_BASE_API + res.fileName);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
    },

    handleUploadError() {
      this.$message.error("图片插入失败");
    },

    async toPublish(hash = '') {
      let data = {
        areaId: this.areaValue,
        title: this.title,
        descContent: this.currentValue,
        tagList: this.selectedTagList
      }
      let res = await insertArticle(data);
      this.articleId = res.data.articleId;
      if(this.articleId) {
        await this.updateDraft();
        this.$message.success('发布成功');
        await this.$router.push('/community/published');
      }
    },

    handleCorrect(val) {
      if(this.selectedTagList.length > 2){
        this.$message.warning('最多只能选择三个标签');
        this.tagValue = '';
        return;
      }
      if (this.selectedTagList.includes(this.tagValue)) {
          this.$message.warning('标签已存在');
          this.tagValue = '';
          return;
        }
        this.selectedTagList.push(this.tagValue);
        this.tagValue = '';
    },

    getTagList(query) {
      this.loading = true;
      let params = {};
      if(query) {
        params.name = query;
      }
      getTagList(params).then(response => {
         this.tagList = response.rows;
         this.loading = false;
      });
    },

    handleDeleteTag(tag) {
      let index = this.selectedTagList.indexOf(tag);
      if (index > -1) {
        this.selectedTagList.splice(index, 1);
      }
    },

    handleSave() {
      this.alertText = '正在保存中...';
      setTimeout(() => {
        this.alertText = '保存成功';
        if(this.draftId) {
          this.updateDraft();
          this.updateRoute();
        } else {
          this.insertDraft();
        }
      }, 1000);
    },

    updateRoute() {
      this.$router.replace({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          id: this.draftId
        },
        query: {
          ...this.$route.query,
        }
      })
    },

    async insertDraft() {
      let data = {
        areaId: this.areaValue,
        title: this.title,
        descContent: this.currentValue,
      }
      let response = await insertArticleDraft(data);
      this.draftId = response.data.draftId;
    },

    async updateDraft() {
      let data = {
        draftId: this.draftId,
        articleId: this.articleId,
        areaId: this.areaValue,
        title: this.title,
        descContent: this.currentValue,
      }
      await updateArticleDraft(data);
    }
  },
};
</script>
  
<style>
.editor-wrapper {
  position: relative !important;
}

.editor,
.ql-toolbar {
  white-space: pre-wrap !important;
  line-height: normal !important;
  border: none !important;

}

.editor {
  margin-top: 90px;
}

.ql-toolbar {
  border-bottom: 1px solid #E6E6E6 !important;
}

.quill-img {
  display: none;
}

.ql-snow {
  padding: 0px 0px 16px 0px !important;
}

.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}

.editor-wrapper>.action-bar {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
}

.editor-wrapper>.action-bar>.action-draft,
.action-publish {
  padding: 0 20px;
  border-radius: 5px;
  line-height: 28px;
  cursor: pointer;
}

.editor-wrapper>.action-bar>.action-draft {
  border: 1px solid #46B4B1;
  color: #46B4B1;
  margin-right: 20px;
}

.editor-wrapper>.action-bar>.action-publish {
  background-color: #46B4B1;
  color: #FFF;
}

.editor-wrapper>.action-bar>.action-alert {
  color: #E1F4F4;
  line-height: 28px;
  padding: 0 20px;
  color: #D9D9D9;
}

.editor-wrapper>.tags-bar {
  position: absolute;
  top: 50px;
  display: flex;
}

.editor-wrapper>.tags-bar>.tag-select {
  margin: 0 20px 0 32px;
}

.editor-wrapper>.tags-bar>.tag-list {
  display: flex;
}

.editor-wrapper>.tags-bar>.tag-list>.meta-item__tag {
  cursor: pointer;
  background-color: #E1F4F4;
  color: #46B4B1;
  margin-right: 8px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(----semi-border-radius-extra-small, 3px);
}

.editor-wrapper>.tags-bar>.tag-list>.meta-item__tag>.el-icon-circle-close {
  margin-left: 8px;
}

.el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  font-size: 12px;
  background-color: #F2F3F5 !important;
  border: none !important;
}

.el-input__inner:focus {
  border-color: #46B4B1 !important;
}

.el-select-dropdown__item.selected {
  color: #46B4B1 !important;
}

.el-input__icon {
  line-height: 28px !important;
}

.editor-wrapper>.title {
  position: absolute;
  top: 90px;
  width: 100%;
}

.editor-wrapper>.title>input {
  font-size: 24px;
  font-weight: 500;
  width: 100%;
}</style>
  