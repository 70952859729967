import router from '@/routes'
import store from './store'
import NProgress from 'nprogress'
import { Message } from 'element-ui'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import { isRelogin } from '@/utils/request'

NProgress.configure({ showSpinner: false })

const whiteList = ['/home','/agreement', '/wx-serve/login','/wx-serve/version', '/privicy','/login/register','/login/forget','/login/code','/login/account']

router.beforeEach((to, from, next) => {
  NProgress.start()
  // 设置页面标题
  if (to.meta.title) {
    document.title = to.meta.title + ' | 囊中对比';
  } else {
    document.title = '囊中对比';
  }

  if (getToken()) {
    /* has token*/
    if (to.path === '/login/code' || to.path === '/login/account') {
      next({ path: '/community/home' })
      NProgress.done()
    } else {
        if (!store.getters['user/getState'].userId) {
            isRelogin.show = true;
            store.dispatch('user/GetInfo').then(() => {
                isRelogin.show = false
                next();
              }).catch(err => {
                  store.dispatch('user/LogOut').then(() => {
                    Message.error(err)
                    next({ path: '/login/code' })
                  })
                })
        } else {
            next();
        }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login/code?redirect=${encodeURIComponent(to.fullPath)}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
