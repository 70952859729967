import { render, staticRenderFns } from "./statistics.vue?vue&type=template&id=89d37424&scoped=true"
import script from "./statistics.vue?vue&type=script&lang=js"
export * from "./statistics.vue?vue&type=script&lang=js"
import style0 from "./statistics.vue?vue&type=style&index=0&id=89d37424&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89d37424",
  null
  
)

export default component.exports