<template>
  <div class="countdown-container">
    <!-- 添加爆炸动效容器 -->
    <div class="explosion-container" v-show="showExplosion">
      <div class="explosion"></div>
      <div class="explosion-text">时间到！</div>
    </div>
    <div class="date-row">
      <div class="date">{{ currentDate }}</div>
      <div class="weekday">{{ currentWeekday }}</div>
    </div>
    <!-- 时间显示区域 -->
    <div class="time-display">
      <div class="time-block">{{ numbers.h }}</div>
      <div class="time-separator">:</div>
      <div class="time-block">{{ numbers.m }}</div>
      <div class="time-separator">:</div>
      <div class="time-block">{{ numbers.s }}</div>
    </div>

    <!-- 控制按钮区域 -->
    <div class="control-buttons">
      <!-- 只在未开始计时时显示选择框 -->
      <el-select v-if="!isRunning" v-model="timerMode" class="mode-select" size="large">
        <el-option label="倒计时" value="countdown"></el-option>
        <el-option label="正计时" value="stopwatch"></el-option>
      </el-select>

      <el-button v-if="!isRunning" circle @click="handleStart" class="start-btn">
        <i class="el-icon-video-play"></i>
      </el-button>
      <template v-else>
        <el-button @click="addSegment" class="segment-btn" :disabled="isPaused">
          分段
        </el-button>
        <el-button circle @click="togglePause" class="pause-btn">
          <i :class="isPaused ? 'el-icon-video-play' : 'el-icon-video-pause'"></i>
        </el-button>
        <el-button circle @click="stopTimer" class="stop-btn">
          <i class="el-icon-circle-close"></i>
        </el-button>
      </template>
    </div>

    <!-- 历史记录列表 -->
    <div class="history-list" v-if="historyRecords.length > 0">
      <el-table :data="historyRecords" row-key="id" :tree-props="{
                children: 'segments',
                hasChildren: 'hasChildren'
            }">
        <el-table-column type="expand">
          <template slot-scope="props">
            <div v-if="props.row.segments && props.row.segments.length" class="segment-list">
              <div v-for="segment in props.row.segments" :key="segment.id" class="segment-item">
                                <span class="editable-name">
                                    <el-input v-if="segment.isEditing" v-model="segment.name" size="small"
                                              @blur="finishEdit(segment)" @keyup.enter.native="finishEdit(segment)"
                                              ref="editInput">
                                    </el-input>
                                    <span v-else @click="startEdit(segment)" class="name-text">
                                        {{ segment.name }}
                                    </span>
                                </span>
                <span>{{ formatDuration(segment.duration) }}</span>
                <span>{{ formatTimeRange(segment) }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="事项" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="时长" align="center">
          <template slot-scope="scope">
            <span>{{ formatDuration(scope.row.duration) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="时间段" align="center">
          <template slot-scope="scope">
            <span>{{ formatTimeRange(scope.row) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 输入弹框 -->
    <el-dialog title="设置倒计时" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="时长(分钟)" prop="minutes">
          <el-input-number v-model="form.minutes" :min="1" :max="999"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="startTimer"
                           style="background-color: #46B4B1; color: #fff;">立即开始</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CountdownView',
  data() {
    return {
      numbers: {
        h: '00',
        m: '00',
        s: '00'
      },
      remainingSeconds: 0,
      timer: null,
      isRunning: false,
      isPaused: false,
      dialogVisible: false,
      form: {
        minutes: 1,
      },
      rules: {
        minutes: [
          { required: true, message: '请输入倒计时时长', trigger: 'blur' }
        ]
      },
      historyRecords: [],
      currentTask: null,
      totalSeconds: 0,
      showExplosion: false,
      currentId: Date.now(),
      expandedRows: [],
      timerMode: 'countdown',
      elapsedSeconds: 0,
      pauseStartTime: null,
      totalPausedTime: 0,
      segmentPausedTime: 0,
      weekdays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      lastCheckDate: new Date().toDateString(),
    }
  },
  computed: {
    currentWeekday() {
      return this.weekdays[new Date().getDay()];
    },
    currentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  methods: {
    showInputDialog() {
      this.dialogVisible = true;
      this.form.minutes = 1;
    },
    startTimer() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const taskId = Date.now();
          this.remainingSeconds = this.form.minutes * 60;
          this.totalSeconds = this.remainingSeconds;
          this.currentTask = {
            id: `task_${taskId}`,
            name: '倒计时',
            startTime: new Date(),
            segments: [],
            hasChildren: false,
            duration: this.totalSeconds,
            isEditing: false
          };
          this.segmentStartTime = new Date();
          this.updateDisplay();
          this.isRunning = true;
          this.isPaused = false;
          this.dialogVisible = false;
          this.startCountdown();
        }
      });
    },
    togglePause() {
      this.isPaused = !this.isPaused;
      if (this.isPaused) {
        clearInterval(this.timer);
        this.pauseStartTime = new Date();
      } else {
        const pauseDuration = new Date() - this.pauseStartTime;
        this.totalPausedTime += pauseDuration;
        this.segmentPausedTime += pauseDuration;
        this.pauseStartTime = null;

        if (this.timerMode === 'countdown') {
          this.startCountdown();
        } else {
          this.startCounting();
        }
      }
    },
    stopTimer() {
      clearInterval(this.timer);
      this.isRunning = false;

      if (this.currentTask && this.segmentStartTime) {
        const now = new Date();
        const lastSegmentDuration = Math.floor(
            (now - this.segmentStartTime - this.segmentPausedTime) / 1000
        );

        const lastSegment = {
          id: `segment_${this.currentTask.id}_final`,
          name: `分段 ${(this.currentTask.segments || []).length + 1}`,
          startTime: this.segmentStartTime,
          endTime: now,
          duration: lastSegmentDuration,
          isEditing: false
        };

        if (!Array.isArray(this.currentTask.segments)) {
          this.currentTask.segments = [];
        }
        this.currentTask.segments.push(lastSegment);
      }

      if (this.currentTask) {
        const duration = this.timerMode === 'countdown' ?
            (this.totalSeconds - this.remainingSeconds) :
            this.elapsedSeconds;

        this.historyRecords.unshift({
          ...this.currentTask,
          endTime: new Date(),
          duration: duration,
          hasChildren: true
        });
        this.saveHistoryRecords();
      }

      this.resetDisplay();
    },
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.remainingSeconds > 0) {
          this.remainingSeconds--;
          this.updateDisplay();
        } else {
          clearInterval(this.timer);
          this.isRunning = false;

          if (this.currentTask && this.segmentStartTime) {
            const now = new Date();
            const lastSegmentDuration = Math.floor((now - this.segmentStartTime) / 1000);

            const lastSegment = {
              id: `segment_${this.currentTask.id}_final`,
              name: `分段 ${(this.currentTask.segments || []).length + 1}`,
              startTime: this.segmentStartTime,
              endTime: now,
              duration: lastSegmentDuration,
              isEditing: false
            };

            if (!Array.isArray(this.currentTask.segments)) {
              this.currentTask.segments = [];
            }
            this.currentTask.segments.push(lastSegment);
          }

          if (this.currentTask) {
            this.historyRecords.unshift({
              ...this.currentTask,
              endTime: new Date(),
              duration: this.totalSeconds,
              hasChildren: true
            });
            this.saveHistoryRecords();
          }

          this.showExplosion = true;
          setTimeout(() => {
            this.showExplosion = false;
            this.resetDisplay();
          }, 3000);
        }
      }, 1000);
    },
    updateDisplay() {
      const seconds = this.timerMode === 'countdown' ?
          this.remainingSeconds :
          this.elapsedSeconds;

      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = seconds % 60;

      this.numbers = {
        h: h.toString().padStart(2, '0'),
        m: m.toString().padStart(2, '0'),
        s: s.toString().padStart(2, '0')
      };
    },
    resetDisplay() {
      this.numbers = { h: '00', m: '00', s: '00' };
      this.remainingSeconds = 0;
      this.elapsedSeconds = 0;
      this.totalSeconds = 0;
      this.totalPausedTime = 0;
      this.segmentPausedTime = 0;
      this.pauseStartTime = null;
    },
    formatDuration(seconds) {
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = seconds % 60;
      return `${h}时${m}分${s}秒`;
    },
    formatDateTime(date) {
      const d = new Date(date);
      const hours = d.getHours().toString().padStart(2, '0');
      const minutes = d.getMinutes().toString().padStart(2, '0');
      const seconds = d.getSeconds().toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    },
    formatTimeRange(record) {
      const startTime = new Date(record.startTime);
      const endTime = new Date(record.endTime);

      const startStr = this.formatDateTime(startTime);
      const endStr = this.formatDateTime(endTime);

      return `${startStr} - ${endStr}`;
    },
    addSegment() {
      if (!this.currentTask || !this.segmentStartTime || this.isPaused) return;

      const now = new Date();
      const segmentDuration = Math.floor((now - this.segmentStartTime - this.segmentPausedTime) / 1000);

      if (!Array.isArray(this.currentTask.segments)) {
        this.currentTask.segments = [];
      }

      const segment = {
        id: `segment_${this.currentTask.id}_${this.currentTask.segments.length + 1}`,
        name: `分段 ${this.currentTask.segments.length + 1}`,
        startTime: this.segmentStartTime,
        endTime: now,
        duration: segmentDuration,
        isEditing: false
      };

      this.currentTask.segments.push(segment);
      this.currentTask.hasChildren = true;
      this.segmentStartTime = now;
      this.segmentPausedTime = 0;

      this.$forceUpdate();
    },
    saveHistoryRecords() {
      try {
        const data = {
          date: new Date().toDateString(),
          records: this.historyRecords
        };
        localStorage.setItem('countdownHistory', JSON.stringify(data));
      } catch (e) {
        console.error('保存历史记录失败:', e);
      }
    },
    loadHistoryRecords() {
      try {
        const savedData = localStorage.getItem('countdownHistory');
        if (savedData) {
          const { date, records } = JSON.parse(savedData);
          if (date === new Date().toDateString()) {
            this.historyRecords = records;
          } else {
            this.historyRecords = [];
            this.saveHistoryRecords();
          }
        } else {
          this.historyRecords = [];
        }
      } catch (e) {
        console.error('加载历史记录失败:', e);
        this.historyRecords = [];
      }
    },
    handleExpand(row, expanded) {
      console.log('展开/收起行:', row, expanded);
      if (expanded) {
        this.expandedRows.push(row.id);
      } else {
        const index = this.expandedRows.indexOf(row.id);
        if (index !== -1) {
          this.expandedRows.splice(index, 1);
        }
      }
    },
    startEdit(segment) {
      this.historyRecords.forEach(record => {
        if (record.segments) {
          record.segments.forEach(seg => {
            if (seg.id !== segment.id) {
              seg.isEditing = false;
            }
          });
        }
      });

      segment.isEditing = true;
      this.$nextTick(() => {
        const input = this.$refs.editInput;
        if (Array.isArray(input)) {
          input[0].$el.querySelector('input').focus();
        } else if (input) {
          input.$el.querySelector('input').focus();
        }
      });
    },
    finishEdit(segment) {
      segment.isEditing = false;
      this.saveHistoryRecords();
    },
    handleStart() {
      if (this.timerMode === 'countdown') {
        this.showInputDialog();
      } else {
        this.startStopwatch();
      }
    },
    startStopwatch() {
      const taskId = Date.now();
      this.elapsedSeconds = 0;
      this.currentTask = {
        id: `task_${taskId}`,
        name: '正计时',
        startTime: new Date(),
        segments: [],
        hasChildren: false,
        duration: 0,
        isEditing: false
      };
      this.segmentStartTime = new Date();
      this.updateDisplay();
      this.isRunning = true;
      this.isPaused = false;
      this.startCounting();
    },
    startCounting() {
      this.timer = setInterval(() => {
        if (this.timerMode === 'countdown') {
          if (this.remainingSeconds > 0) {
            this.remainingSeconds--;
            this.updateDisplay();
          } else {
            this.handleCountdownEnd();
          }
        } else {
          this.elapsedSeconds++;
          this.updateDisplay();
        }
      }, 1000);
    },
    handleCountdownEnd() {
      clearInterval(this.timer);
      this.isRunning = false;

      if (this.currentTask && this.segmentStartTime) {
        const now = new Date();
        const lastSegmentDuration = Math.floor((now - this.segmentStartTime) / 1000);

        const lastSegment = {
          id: `segment_${this.currentTask.id}_final`,
          name: `分段 ${(this.currentTask.segments || []).length + 1}`,
          startTime: this.segmentStartTime,
          endTime: now,
          duration: lastSegmentDuration,
          isEditing: false
        };

        if (!Array.isArray(this.currentTask.segments)) {
          this.currentTask.segments = [];
        }
        this.currentTask.segments.push(lastSegment);
      }

      if (this.currentTask) {
        this.historyRecords.unshift({
          ...this.currentTask,
          endTime: new Date(),
          duration: this.totalSeconds,
          hasChildren: true
        });
        this.saveHistoryRecords();
      }

      this.showExplosion = true;
      setTimeout(() => {
        this.showExplosion = false;
        this.resetDisplay();
      }, 3000);
    },
    checkDate() {
      const currentDate = new Date().toDateString();
      if (this.lastCheckDate !== currentDate) {
        this.historyRecords = [];
        this.saveHistoryRecords();
        this.lastCheckDate = currentDate;
      }
    },
  },
  created() {
    this.loadHistoryRecords();
    setInterval(() => {
      this.checkDate();
    }, 60000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.saveHistoryRecords();
  },
  watch: {
    historyRecords: {
      handler(newVal) {
        console.log('History records updated:', newVal);
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.countdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 30px;
  width: 100%;
  // padding: 0 calc((100% - 660px) / 2);
}


.date-row {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 15px;

  // padding: 0 calc((100% - 660px) / 2);
  .date {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 0px;
  }

  .weekday {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding: 0px 20px;
  }
}

.time-display {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  // max-width: 660px;
  margin-bottom: 30px;
}

.time-block {
  width: 200px;
  height: 200px;
  background: #2c292c;
  color: #fff;
  font-size: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.time-separator {
  color: #fff;
  font-size: 80px;
  margin: 0 10px;
}

.control-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  position: relative;
  margin-bottom: 30px;

  .mode-select {
    position: absolute;
    left: 0;
    width: 100px;
  }

  .start-btn {
    margin: 0 auto;
  }

  .el-button {
    width: 50px;
    height: 50px;
    padding: 0;
    background: #2c2c2c !important;
    border: none;

    &:hover {
      background: #3c3c3c !important;
    }

    i {
      font-size: 50px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.start-btn i {
      color: #888;
    }

    &.pause-btn i {
      color: #888;
    }

    &.stop-btn i {
      color: #888;
    }
  }



  .segment-btn {
    height: 36px;
    font-size: 14px;
    width: 80px;
    background: #2c2c2c !important;
    border: none;
    color: #888;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;

    // 添加点击波纹效果
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: 5px;
      background: rgba(255, 255, 255, 0.3);
      opacity: 0;
      border-radius: 100%;
      transform: scale(1, 1) translate(-50%, -50%);
      transform-origin: 50% 50%;
    }

    &:active {
      transform: scale(0.95);
      background: #363636 !important;

      &::after {
        animation: ripple 0.4s ease-out;
      }
    }

    &:hover {
      background: #3c3c3c !important;
    }

    &:disabled {
      color: #666;
      cursor: not-allowed;
      transform: none;

      &::after {
        display: none;
      }
    }
  }
}

.history-list {
  width: 100%;
  // max-width: 800px;

  ::v-deep .el-table {
    background-color: transparent;
    color: #888;

    &::before,
    &::after {
      background-color: #3c3c3c !important;
    }

    &__row {
      background-color: #2c292c;
      color: #888;

      &:hover>td {
        background-color: #2c292c !important;
      }

      &.el-table__row--level-1 {
        background-color: #363636;
        font-size: 0.9em;
      }
    }

    th {
      background-color: #2c292c;
      color: #888;
      font-weight: normal;
      border-bottom: 1px solid #3c3c3c !important;
    }

    &__expand-icon {
      color: #888;

      &--expanded {
        transform: rotate(90deg);
      }
    }

    td {
      border-bottom: 1px solid #3c3c3c;
      color: #888;
    }

    .segment-list {
      padding: 0px 20px;
    }

    .segment-item {
      padding: 10px;
      margin: 5px 0;
      background: #363636;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;

      span {
        flex: 1;
        text-align: center;
        color: #888;
      }
    }

    .el-table__expanded-cell {
      background: #2c292c !important;
      padding: 10px !important;
      color: #888;
    }
  }
}

.explosion-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
}

.explosion {
  width: 200px;
  height: 200px;
  position: relative;
  animation: explode 0.5s ease-out forwards;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, #ff5e5e 0%, transparent 70%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    animation: pulse 0.5s ease-out infinite;
  }

  &::after {
    animation-delay: 0.25s;
  }
}

.explosion-text {
  color: #fff;
  font-size: 48px;
  margin-top: 20px;
  animation: fadeInUp 0.5s ease-out forwards;
}

@keyframes explode {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1.5);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

@keyframes fadeInUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.segment-item {
  padding: 10px;
  margin: 5px 0;
  background: #363636;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;

  span {
    flex: 1;
    text-align: center;
  }

  .editable-name {
    flex: 1;
    text-align: center;
    position: relative;

    .name-text {
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 4px;
      transition: background-color 0.3s;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .el-input {
      width: 200px;

      ::v-deep .el-input__inner {
        background: transparent;
        border: 1px solid #4c4c4c;
        color: #888;
        text-align: center;
        height: 32px;
        line-height: 32px;
        padding: 0 10px;

        &:focus {
          border-color: #42b883;
        }
      }
    }
  }
}

::v-deep .el-table {
  .el-table__expand-icon {
    color: #888;
    font-size: 16px;

    &--expanded {
      transform: rotate(90deg);
    }
  }

  .el-table__expanded-cell {
    padding: 20px !important;
    background: #2c292c !important;
    color: #fff;
  }
}

// 添加波纹动画
@keyframes ripple {
  0% {
    transform: scale(0, 0) translate(-50%, -50%);
    opacity: 0.5;
  }

  100% {
    transform: scale(40, 40) translate(-50%, -50%);
    opacity: 0;
  }
}

.mode-select {
  width: 100px;
  margin-right: 300px;

  ::v-deep .el-select,
  ::v-deep .el-input,
  ::v-deep .el-input__inner {
    background: #2c2c2c !important;
  }

  ::v-deep .el-select-dropdown {
    background: #2c2c2c !important;
  }

  ::v-deep .el-select-dropdown__item {
    background: #2c2c2c !important;
    border: none;
    color: #888;
    height: 36px;
    line-height: 36px;

    &.selected {
      background: #3c3c3c !important;
      color: #42b883;
    }

    &:hover {
      background: #363636 !important;
    }
  }
}
</style>
